// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".text--widen{letter-spacing:.15px}.notification{padding:.625rem 1rem;justify-content:center;color:#fff;box-shadow:0 10px 20px rgba(0,0,0,.2);background-color:rgba(1,125,133,.2)}.notification>div{display:flex;align-items:center}.notification--primary .notification{background-color:#017d85}.notification--warning .notification{background-color:#e40339}.notification--success .notification{background-color:#0e7f30}.notification--error .notification{background-color:#e40339}.notification--default .notification{background-color:rgba(1,125,133,.2);color:#fff}@media(min-width:64em){.notification{top:3.5em}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"price1": "#d3048e",
	"price2": "#936d22",
	"price3": "#617e26",
	"price4": "#196e19",
	"price5": "#056868",
	"price6": "#5252b6",
	"price7": "#8d0dcd",
	"price8": "#bc2e2d"
};
module.exports = ___CSS_LOADER_EXPORT___;
