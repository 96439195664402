import Vue from 'vue'
import { ActionTree, MutationTree, ActionContext, GetterTree } from 'vuex'
import { RootState } from './types'
import * as package_queries from '~/api/queries/packages.gql'

import { DateRangeInput, Package, PackageInstance } from '~/@types/skyway'

/**
 * Interface for app state, transform properties for convenience
 */
export type PackageTransformed<T> = Partial<T> & {
  min_age: number
  max_age: number
  first_date: string
  last_date: string
  instances: PackageInstance[]
  available: number
}

export const name = 'package'

export const namespaced = true

export const types = {
  SET_PACKAGE: 'SET_PACKAGE',
  SET_FIXED_PACKAGES: 'SET_FIXED_PACKAGES',
  SET_FLEX_PACKAGES: 'SET_FLEX_PACKAGES',
  SET_PACKAGE_AVAILABILITY: 'SET_PACKAGE_AVAILABILITY',
  SET_FIXED_PACKAGE_PRICES: 'SET_FIXED_PACKAGE_PRICES',
}

export type ViewOptions = 'list' | 'grid'

export interface State {
  package?: Package
  fixed_packages: Array<PackageTransformed<Package>>
  flex_packages: Array<Package>
}

/**
 * Initial state, empty array of events
 */
export const state = (): State => ({
  package: undefined,
  fixed_packages: [],
  flex_packages: [],
})

export const getters: GetterTree<State, RootState> = {
  package: (state: State): Package | undefined => {
    return state.package
  },

  fixed_packages: (state: State): Array<Package> | undefined => {
    return state.fixed_packages
  },

  flex_packages: (state: State): Array<Package> | undefined => {
    return state.flex_packages
  },
}

export const actions: ActionTree<State, RootState> = {
  async getPackage(
    context: ActionContext<State, RootState>,
    id: number
  ): Promise<any> {
    const response = await this.app.$apollo.query({
      query: package_queries['getPackage'],
      variables: {
        id,
      },
      context: {
        public: false,
      },
      fetchPolicy: 'network-only',
    })

    const { data } = response

    context.commit(types.SET_PACKAGE, data.package)

    return data.package
  },

  async getFixedPackages(
    context: ActionContext<State, RootState>,
    range: DateRangeInput
  ): Promise<any> {
    const response = await this.app.$apollo.query({
      query: package_queries['getPackagesByType'],
      variables: {
        type: 'fixed',
        range,
      },
      context: {
        public: false,
      },
      fetchPolicy: 'network-only',
    })

    const { data } = response

    context.commit(types.SET_FIXED_PACKAGES, data.getPackages)

    return data.getPackages
  },

  async getFixedPackagePrices(
    context: ActionContext<State, RootState>,
    range: DateRangeInput
  ): Promise<any> {
    const response = await this.app.$apolloNonPersisted.query({
      query: package_queries['getPackagePricingByType'],
      variables: {
        type: 'fixed',
        range,
      },
      context: {
        public: false,
      },
      fetchPolicy: 'network-only',
    })

    const { data } = response

    context.commit(types.SET_FIXED_PACKAGE_PRICES, data.getPackages)

    return data.getPackages
  },

  async getFlexPackages(
    context: ActionContext<State, RootState>,
    id: number
  ): Promise<any> {
    const response = await this.app.$apollo.query({
      query: package_queries['getPackagesByType'],
      variables: {
        type: 'flex',
      },
      context: {
        public: false,
      },
      fetchPolicy: 'network-only',
    })

    const { data } = response

    context.commit(types.SET_FLEX_PACKAGES, data.getPackages)

    return data.getPackages
  },

  async getPackageByRef(
    context: ActionContext<State, RootState>,
    package_ref: number
  ): Promise<any> {
    const response = await this.app.$apolloNonPersisted.query({
      query: package_queries['getPackageByRef'],
      variables: {
        package_ref,
      },
      fetchPolicy: 'network-only',
      extensions: {
        persistedQuery: {
          version: 12345,
        },
      },
    })

    const { data } = response

    context.commit(types.SET_PACKAGE, data.getPackageByRef)
    return data.getPackageByRef
  },
}

export const mutations: MutationTree<State> = {
  [types.SET_PACKAGE](state: State, payload: Package): void {
    state.package = payload
  },

  [types.SET_FIXED_PACKAGE_PRICES](state: State, payload: Package[]): void {
    const packageAvailable = (instances: PackageInstance[]): boolean => {
      let result = true

      instances.forEach((inst) => {
        if (!inst.available) {
          result = false
        }
      })

      return result
    }

    for (const i in payload) {
      const index = state.fixed_packages.findIndex(
        (fp) => fp && fp.package_ref === payload[i].package_ref
      )

      if (index !== -1) {
        Vue.set(state.fixed_packages, index, {
          ...state.fixed_packages[index],
          ...payload[i],
          available: Boolean(
            payload[i].instances &&
              packageAvailable(payload[i].instances as PackageInstance[])
          ),
        })
      }
    }
  },

  [types.SET_FIXED_PACKAGES](state: State, payload: Array<Package>): void {
    const packages = payload.filter(
      (pkg: Package) => pkg.instances && pkg.instances.length > 0
    )

    state.fixed_packages = packages.map(
      (fp: Package): PackageTransformed<Package> => {
        const output: any = { ...fp }
        const first_date =
          fp && fp.instances && fp.instances[0] && fp.instances[0].date_time
        const last_date =
          fp &&
          fp.instances &&
          fp.instances[fp.instances.length - 1] &&
          fp.instances[fp.instances.length - 1]?.date_time
        output.first_date = first_date
        output.min_age =
          fp.extra && fp.extra.minimum_age ? fp.extra.minimum_age : 0
        output.max_age =
          fp.extra && fp.extra.maximum_age ? fp.extra.maximum_age : 18
        output.last_date = last_date

        return output
      }
    )
  },

  [types.SET_FLEX_PACKAGES](state: State, payload: Array<Package>): void {
    state.flex_packages = payload
  },

  [types.SET_PACKAGE_AVAILABILITY](state: State, payload: Package): void {
    if (state.package && state.package.package_ref == payload.package_ref) {
      Vue.set(state.package, 'total_availability', payload.total_availability)
      Vue.set(state.package, 'availability', payload.availability)
    }
  },
}
