import Vue from 'vue'

export default ({ app }, inject) => {
  const customMessages = (slug, page) => {
    const getter = app.store.getters['custom_messages/getMessagesBySlug']

    return getter(slug, page)
  }

  Vue.prototype.$cm = customMessages

  inject('cm', customMessages)
}
