import { ActionTree, MutationTree, ActionContext, GetterTree } from 'vuex'

import { RootState } from './types'
import * as venues from '~/api/queries/venues.gql'
import { Venue } from '~/@types/skyway'

export const name = 'venues'

export const namespaced = true

export const types = {
  SET_VENUES: 'SET_VENUES'
}

export interface State {
  venues?: Array<Venue>
}

/**
 * Initial state, empty array of events
 */
export const state = (): State => ({
  venues: []
})

export const getters: GetterTree<State, RootState> = {}

export const actions: ActionTree<State, RootState> = {
  async getVenues(context: ActionContext<State, RootState>): Promise<any> {
    const client = this.app.$apollo;
    const response = await client.query({
      query: venues['getVenues'],
      context: {
        public: true
      }
    })

    const { data } = response

    context.commit(types.SET_VENUES, data.getVenues)
  },

  async getFacilities(context: ActionContext<State, RootState>): Promise<any> {
    const response = await this.app.$apollo.query({
      query: venues['getFacilities'],
      fetchPolicy: 'network-only'
    })
    const { data } = response
    context.commit(types.SET_VENUES, data.getFacilities)
  }
}

export const mutations: MutationTree<State> = {
  [types.SET_VENUES](state: State, payload: Venue[]): void {
    state.venues = payload
  }
}
