// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".text--widen{letter-spacing:.15px}.modal{background:rgba(35,31,32,.6);position:fixed;top:0;left:0;width:100%;min-height:100vh;overflow:auto;z-index:99999;display:flex;align-items:center;justify-content:center;padding:1em}.modal__content-heading{margin-bottom:1.1875rem}.modal__content-heading h4{margin:0;font-size:1.375rem}.modal__content-heading svg{fill:#231f20}.modal .modal-header{position:relative}.modal .modal-header__close-button{position:absolute;top:0;right:0;width:1.5rem;height:1.5rem;display:flex;align-items:center;justify-content:center;border-radius:50%;cursor:pointer;transition:all .2s ease-in-out;background:#fff}.modal .modal-header__close-button:hover{background:#017d85}.modal .modal-header__close-button:hover svg{fill:#fff}.modal__content{background:#fff;width:21.875rem;max-height:calc(100vh - 2em);overflow:auto;padding:1rem;position:relative}.modal--no-head .modal-header{display:none}.modal--small .modal__content{width:17.5rem}@media(min-width:48em){.modal--medium .modal__content{width:35.3125rem}}@media(min-width:48em){.modal--large .modal__content,.modal--xlarge .modal__content{width:47rem}}@media(min-width:64em){.modal--xlarge .modal__content{width:65.5rem}}.modal__content-footer{padding:16px 24px;display:flex;justify-content:flex-end;align-items:center;flex-wrap:wrap}.modal__content-footer-button{width:100%;margin-top:1em;display:inline-block}@media(min-width:40em){.modal__content-footer-button{width:auto;margin-top:0;margin-left:1em}}.modal__ornaments,.modal__tees{width:100%}.modal__ornaments .modal-header,.modal__tees .modal-header{margin:0}.modal__ornaments .modal__content,.modal__tees .modal__content{background-color:#f5f4f0;width:100vw;height:100vh;padding:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"price1": "#d3048e",
	"price2": "#936d22",
	"price3": "#617e26",
	"price4": "#196e19",
	"price5": "#056868",
	"price6": "#5252b6",
	"price7": "#8d0dcd",
	"price8": "#bc2e2d"
};
module.exports = ___CSS_LOADER_EXPORT___;
