export const getEvents = [
  {
    id: "40018",
    event_ref: 116129,
    production_ref: 116085,
    season_ref: 191,
    series_id: 15,
    venue_id: 336,
    production_title: "LPO 20/11/06",
    season_title: "LPO 20/21",
    title: "Emotion and tranquility",
    subtitle: null,
    first_date: "2020-11-06T00:00:00.000Z",
    last_date: "2020-11-06T00:00:00.000Z",
    publish_up: null,
    publish_down: null,
    alias: "emotion-and-tranquility",
    type: "event",
    price: null,
    previews: null,
    running_time: null,
    by_line: null,
    booking_info: null,
    booking_link: null,
    preview_info: null,
    hash_tag: null,
    short_description:
      "<p>Ray Chen plays Brahms&rsquo;s sunlit Violin Concerto, the percect foil for Tchaikovsky&rsquo;s Fourth Symphony.</p>",
    description: null,
    additional: null,
    credits: null,
    access: null,
    miscellaneous: null,
    images: null,
    galleries: null,
    videos: null,
    sponsors: [{ level: "Primary" }],
    attachments: null,
    extra: [],
    booking_fee: null,
    custom: false,
    on_sale: true,
    published: true,
    private: false,
    featured: false,
    general_admission: false,
    instances: [
      {
        id: "16324",
        instance_ref: 116172,
        title: "LPO 06/11/20",
        date_time: "2020-11-06T19:30:00.000Z",
        __typename: "Instance",
      },
    ],
    series: { id: "15", title: "International", __typename: "Series" },
    venue: {
      id: "336",
      title: "Royal Festival Hall",
      venue_ref: 8,
      __typename: "Venue",
    },
    facility: {
      id: "500",
      title: "RFH 2020",
      facility_ref: 1388,
      __typename: "Facility",
    },
    modes_of_sale: [
      {
        mode_of_sale_ref: 2,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2020-11-06T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 13,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2020-11-06T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 14,
        title: null,
        start_date: "2020-11-06T17:30:00.000Z",
        end_date: "2020-11-06T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 15,
        title: null,
        start_date: "2020-07-08T08:00:00.000Z",
        end_date: "2020-07-08T08:01:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 16,
        title: null,
        start_date: "2020-07-08T08:00:00.000Z",
        end_date: "2020-07-08T08:01:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 38,
        title: null,
        start_date: "2020-01-28T09:55:00.000Z",
        end_date: "2020-11-06T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 50,
        title: null,
        start_date: "2019-11-26T12:58:57.000Z",
        end_date: "2020-11-06T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 52,
        title: null,
        start_date: "2019-11-26T12:58:57.000Z",
        end_date: "2020-11-06T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 56,
        title: null,
        start_date: "2020-07-08T08:00:00.000Z",
        end_date: "2020-07-08T08:01:00.000Z",
        __typename: "ModeOfSale",
      },
    ],
    __typename: "Event",
  },
  {
    id: "40019",
    event_ref: 116130,
    production_ref: 116086,
    season_ref: 191,
    series_id: 12,
    venue_id: 333,
    production_title: "LPO 20/11/11",
    season_title: "LPO 20/21",
    title: "2015: The midnight sun",
    subtitle: null,
    first_date: "2020-11-11T00:00:00.000Z",
    last_date: "2020-11-11T00:00:00.000Z",
    publish_up: null,
    publish_down: null,
    alias: "2015-the-midnight-sun",
    type: "event",
    price: null,
    previews: null,
    running_time: null,
    by_line: null,
    booking_info: null,
    booking_link: null,
    preview_info: null,
    hash_tag: null,
    short_description: null,
    description: "",
    additional: null,
    credits: null,
    access: null,
    miscellaneous: null,
    images: null,
    galleries: null,
    videos: null,
    sponsors: null,
    attachments: null,
    extra: [],
    booking_fee: null,
    custom: false,
    on_sale: true,
    published: true,
    private: false,
    featured: false,
    general_admission: false,
    instances: [
      {
        id: "16325",
        instance_ref: 116173,
        title: "LPO 11/11/20",
        date_time: "2020-11-11T19:30:00.000Z",
        __typename: "Instance",
      },
    ],
    series: null,
    venue: {
      id: "336",
      title: "Royal Festival Hall",
      venue_ref: 8,
      __typename: "Venue",
    },
    facility: {
      id: "500",
      title: "RFH 2020",
      facility_ref: 1388,
      __typename: "Facility",
    },
    modes_of_sale: [
      {
        mode_of_sale_ref: 2,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2020-11-11T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 13,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2020-11-11T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 14,
        title: null,
        start_date: "2020-11-11T17:30:00.000Z",
        end_date: "2020-11-11T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 15,
        title: null,
        start_date: "2020-07-08T08:00:00.000Z",
        end_date: "2020-07-08T08:01:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 16,
        title: null,
        start_date: "2020-07-08T08:00:00.000Z",
        end_date: "2020-07-08T08:01:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 38,
        title: null,
        start_date: "2020-01-28T09:55:00.000Z",
        end_date: "2020-11-11T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 50,
        title: null,
        start_date: "2019-11-26T13:10:48.000Z",
        end_date: "2020-11-11T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 52,
        title: null,
        start_date: "2019-11-26T13:10:48.000Z",
        end_date: "2020-11-11T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 56,
        title: null,
        start_date: "2020-07-08T08:00:00.000Z",
        end_date: "2020-07-08T08:01:00.000Z",
        __typename: "ModeOfSale",
      },
    ],
    __typename: "Event",
  },
  {
    id: "40020",
    event_ref: 116131,
    production_ref: 116087,
    season_ref: 191,
    series_id: 13,
    venue_id: 330,
    production_title: "LPO 20/11/18",
    season_title: "LPO 20/21",
    title: "Mehta conducts Shankar",
    subtitle: null,
    first_date: "2020-11-18T00:00:00.000Z",
    last_date: "2020-11-18T00:00:00.000Z",
    publish_up: null,
    publish_down: null,
    alias: "mehta-conducts-shankar",
    type: "event",
    price: null,
    previews: null,
    running_time: null,
    by_line: null,
    booking_info: null,
    booking_link: null,
    preview_info: null,
    hash_tag: null,
    short_description: null,
    description: "",
    additional: null,
    credits: null,
    access: null,
    miscellaneous: null,
    images: null,
    galleries: null,
    videos: null,
    sponsors: null,
    attachments: null,
    extra: [],
    booking_fee: null,
    custom: false,
    on_sale: true,
    published: true,
    private: false,
    featured: false,
    general_admission: false,
    instances: [
      {
        id: "16326",
        instance_ref: 116174,
        title: "LPO 18/11/20",
        date_time: "2020-11-18T19:30:00.000Z",
        __typename: "Instance",
      },
    ],
    series: null,
    venue: {
      id: "336",
      title: "Royal Festival Hall",
      venue_ref: 8,
      __typename: "Venue",
    },
    facility: {
      id: "500",
      title: "RFH 2020",
      facility_ref: 1388,
      __typename: "Facility",
    },
    modes_of_sale: [
      {
        mode_of_sale_ref: 2,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2020-11-18T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 13,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2020-11-18T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 14,
        title: null,
        start_date: "2020-11-18T17:30:00.000Z",
        end_date: "2020-11-18T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 15,
        title: null,
        start_date: "2020-07-08T08:00:00.000Z",
        end_date: "2020-07-08T08:01:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 16,
        title: null,
        start_date: "2020-07-08T08:00:00.000Z",
        end_date: "2020-07-08T08:01:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 38,
        title: null,
        start_date: "2020-01-28T09:55:00.000Z",
        end_date: "2020-11-18T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 50,
        title: null,
        start_date: "2019-11-26T13:12:54.000Z",
        end_date: "2020-11-18T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 52,
        title: null,
        start_date: "2019-11-26T13:12:54.000Z",
        end_date: "2020-11-18T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 56,
        title: null,
        start_date: "2020-07-08T08:00:00.000Z",
        end_date: "2020-07-08T08:01:00.000Z",
        __typename: "ModeOfSale",
      },
    ],
    __typename: "Event",
  },
  {
    id: "40021",
    event_ref: 116132,
    production_ref: 116088,
    season_ref: 191,
    series_id: 14,
    venue_id: 334,
    production_title: "LPO 20/11/25",
    season_title: "LPO 20/21",
    title: "2016: Song of the night",
    subtitle: null,
    first_date: "2020-11-25T00:00:00.000Z",
    last_date: "2020-11-25T00:00:00.000Z",
    publish_up: null,
    publish_down: null,
    alias: "2016-song-of-the-night",
    type: "event",
    price: null,
    previews: null,
    running_time: null,
    by_line: null,
    booking_info: null,
    booking_link: null,
    preview_info: null,
    hash_tag: null,
    short_description: null,
    description: "",
    additional: null,
    credits: null,
    access: null,
    miscellaneous: null,
    images: null,
    galleries: null,
    videos: null,
    sponsors: null,
    attachments: null,
    extra: [],
    booking_fee: null,
    custom: false,
    on_sale: true,
    published: true,
    private: false,
    featured: false,
    general_admission: false,
    instances: [
      {
        id: "16327",
        instance_ref: 116175,
        title: "LPO 25/11/20",
        date_time: "2020-11-25T19:30:00.000Z",
        __typename: "Instance",
      },
    ],
    series: null,
    venue: {
      id: "336",
      title: "Royal Festival Hall",
      venue_ref: 8,
      __typename: "Venue",
    },
    facility: {
      id: "500",
      title: "RFH 2020",
      facility_ref: 1388,
      __typename: "Facility",
    },
    modes_of_sale: [
      {
        mode_of_sale_ref: 2,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2020-11-25T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 13,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2020-11-25T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 14,
        title: null,
        start_date: "2020-11-25T17:30:00.000Z",
        end_date: "2020-11-25T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 15,
        title: null,
        start_date: "2020-07-08T08:00:00.000Z",
        end_date: "2020-07-08T08:01:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 16,
        title: null,
        start_date: "2020-07-08T08:00:00.000Z",
        end_date: "2020-07-08T08:01:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 38,
        title: null,
        start_date: "2020-01-28T09:55:00.000Z",
        end_date: "2020-11-25T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 50,
        title: null,
        start_date: "2019-11-26T13:14:14.000Z",
        end_date: "2020-11-25T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 52,
        title: null,
        start_date: "2019-11-26T13:14:14.000Z",
        end_date: "2020-11-25T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 56,
        title: null,
        start_date: "2020-07-08T08:00:00.000Z",
        end_date: "2020-07-08T08:01:00.000Z",
        __typename: "ModeOfSale",
      },
    ],
    __typename: "Event",
  },
  {
    id: "40022",
    event_ref: 116133,
    production_ref: 116089,
    season_ref: 191,
    series_id: 15,
    venue_id: 336,
    production_title: "LPO 20/11/28",
    season_title: "LPO 20/21",
    title: "2017: Gavrylyuk plays Prokofiev",
    subtitle: null,
    first_date: "2020-11-28T00:00:00.000Z",
    last_date: "2020-11-28T00:00:00.000Z",
    publish_up: null,
    publish_down: null,
    alias: "2017-gavrylyuk-plays-prokofiev",
    type: "event",
    price: null,
    previews: null,
    running_time: null,
    by_line: null,
    booking_info: null,
    booking_link: null,
    preview_info: null,
    hash_tag: null,
    short_description: null,
    description: "",
    additional: null,
    credits: null,
    access: null,
    miscellaneous: null,
    images: null,
    galleries: null,
    videos: null,
    sponsors: null,
    attachments: null,
    extra: [],
    booking_fee: null,
    custom: false,
    on_sale: true,
    published: true,
    private: false,
    featured: false,
    general_admission: false,
    instances: [
      {
        id: "16328",
        instance_ref: 116176,
        title: "LPO 28/11/20",
        date_time: "2020-11-28T19:30:00.000Z",
        __typename: "Instance",
      },
    ],
    series: null,
    venue: {
      id: "336",
      title: "Royal Festival Hall",
      venue_ref: 8,
      __typename: "Venue",
    },
    facility: {
      id: "500",
      title: "RFH 2020",
      facility_ref: 1388,
      __typename: "Facility",
    },
    modes_of_sale: [
      {
        mode_of_sale_ref: 2,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2020-11-28T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 13,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2020-11-28T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 14,
        title: null,
        start_date: "2020-11-28T17:30:00.000Z",
        end_date: "2020-11-28T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 15,
        title: null,
        start_date: "2020-07-08T08:00:00.000Z",
        end_date: "2020-07-08T08:01:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 16,
        title: null,
        start_date: "2020-07-08T08:00:00.000Z",
        end_date: "2020-07-08T08:01:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 38,
        title: null,
        start_date: "2020-01-28T09:55:00.000Z",
        end_date: "2020-11-28T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 50,
        title: null,
        start_date: "2019-11-26T13:15:36.000Z",
        end_date: "2020-11-28T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 52,
        title: null,
        start_date: "2019-11-26T13:15:36.000Z",
        end_date: "2020-11-28T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 56,
        title: null,
        start_date: "2020-07-08T08:00:00.000Z",
        end_date: "2020-07-08T08:01:00.000Z",
        __typename: "ModeOfSale",
      },
    ],
    __typename: "Event",
  },
  {
    id: "40023",
    event_ref: 116134,
    production_ref: 116090,
    season_ref: 191,
    series_id: 13,
    venue_id: 336,
    production_title: "LPO 20/12/02",
    season_title: "LPO 20/21",
    title: "2018: Jurowski conducts Enescu",
    subtitle: null,
    first_date: "2020-12-02T00:00:00.000Z",
    last_date: "2020-12-02T00:00:00.000Z",
    publish_up: null,
    publish_down: null,
    alias: "2018-jurowski-conducts-enescu",
    type: "event",
    price: null,
    previews: null,
    running_time: null,
    by_line: null,
    booking_info: null,
    booking_link: null,
    preview_info: null,
    hash_tag: null,
    short_description: null,
    description: "",
    additional: null,
    credits: null,
    access: null,
    miscellaneous: null,
    images: null,
    galleries: null,
    videos: null,
    sponsors: null,
    attachments: null,
    extra: [],
    booking_fee: null,
    custom: false,
    on_sale: true,
    published: true,
    private: false,
    featured: false,
    general_admission: false,
    instances: [
      {
        id: "16329",
        instance_ref: 116177,
        title: "LPO 02/12/20",
        date_time: "2020-12-02T19:30:00.000Z",
        __typename: "Instance",
      },
    ],
    series: null,
    venue: {
      id: "336",
      title: "Royal Festival Hall",
      venue_ref: 8,
      __typename: "Venue",
    },
    facility: {
      id: "500",
      title: "RFH 2020",
      facility_ref: 1388,
      __typename: "Facility",
    },
    modes_of_sale: [
      {
        mode_of_sale_ref: 2,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2020-12-02T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 13,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2020-12-02T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 14,
        title: null,
        start_date: "2020-12-02T17:30:00.000Z",
        end_date: "2020-12-02T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 15,
        title: null,
        start_date: "2020-07-08T08:00:00.000Z",
        end_date: "2020-07-08T08:01:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 16,
        title: null,
        start_date: "2020-07-08T08:00:00.000Z",
        end_date: "2020-07-08T08:01:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 38,
        title: null,
        start_date: "2020-01-28T09:55:00.000Z",
        end_date: "2020-12-02T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 50,
        title: null,
        start_date: "2019-11-26T13:17:09.000Z",
        end_date: "2020-12-02T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 52,
        title: null,
        start_date: "2019-11-26T13:17:09.000Z",
        end_date: "2020-12-02T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 56,
        title: null,
        start_date: "2020-07-08T08:00:00.000Z",
        end_date: "2020-07-08T08:01:00.000Z",
        __typename: "ModeOfSale",
      },
    ],
    __typename: "Event",
  },
  {
    id: "40024",
    event_ref: 116135,
    production_ref: 116091,
    season_ref: 191,
    series_id: 12,
    venue_id: 334,
    production_title: "LPO 20/12/05",
    season_title: "LPO 20/21",
    title: "2019: All the world's a stage",
    subtitle: null,
    first_date: "2020-12-05T00:00:00.000Z",
    last_date: "2020-12-05T00:00:00.000Z",
    publish_up: null,
    publish_down: null,
    alias: "2019-all-the-worlds-a-stage",
    type: "event",
    price: null,
    previews: null,
    running_time: null,
    by_line: null,
    booking_info: null,
    booking_link: null,
    preview_info: null,
    hash_tag: null,
    short_description: null,
    description: "",
    additional: null,
    credits: null,
    access: null,
    miscellaneous: null,
    images: null,
    galleries: null,
    videos: null,
    sponsors: null,
    attachments: null,
    extra: [],
    booking_fee: null,
    custom: false,
    on_sale: true,
    published: true,
    private: false,
    featured: false,
    general_admission: false,
    instances: [
      {
        id: "16330",
        instance_ref: 116178,
        title: "LPO 05/12/20",
        date_time: "2020-12-05T19:30:00.000Z",
        __typename: "Instance",
      },
    ],
    series: null,
    venue: {
      id: "336",
      title: "Royal Festival Hall",
      venue_ref: 8,
      __typename: "Venue",
    },
    facility: {
      id: "500",
      title: "RFH 2020",
      facility_ref: 1388,
      __typename: "Facility",
    },
    modes_of_sale: [
      {
        mode_of_sale_ref: 2,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2020-12-05T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 13,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2020-12-05T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 14,
        title: null,
        start_date: "2020-12-05T17:30:00.000Z",
        end_date: "2020-12-05T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 15,
        title: null,
        start_date: "2020-07-08T08:00:00.000Z",
        end_date: "2020-07-08T08:01:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 16,
        title: null,
        start_date: "2020-07-08T08:00:00.000Z",
        end_date: "2020-07-08T08:01:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 38,
        title: null,
        start_date: "2020-01-28T09:55:00.000Z",
        end_date: "2020-12-05T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 50,
        title: null,
        start_date: "2019-11-26T13:18:21.000Z",
        end_date: "2020-12-05T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 52,
        title: null,
        start_date: "2019-11-26T13:18:21.000Z",
        end_date: "2020-12-05T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 56,
        title: null,
        start_date: "2020-07-08T08:00:00.000Z",
        end_date: "2020-07-08T08:01:00.000Z",
        __typename: "ModeOfSale",
      },
    ],
    __typename: "Event",
  },
  {
    id: "40025",
    event_ref: 116136,
    production_ref: 116092,
    season_ref: 191,
    series_id: 14,
    venue_id: 336,
    production_title: "LPO 20/12/12",
    season_title: "LPO 20/21",
    title: "2020: Christmas Oratorio",
    subtitle: null,
    first_date: "2020-12-12T00:00:00.000Z",
    last_date: "2020-12-12T00:00:00.000Z",
    publish_up: null,
    publish_down: null,
    alias: "2020-christmas-oratorio",
    type: "event",
    price: null,
    previews: null,
    running_time: null,
    by_line: null,
    booking_info: null,
    booking_link: null,
    preview_info: null,
    hash_tag: null,
    short_description: null,
    description: "",
    additional: null,
    credits: null,
    access: null,
    miscellaneous: null,
    images: null,
    galleries: null,
    videos: null,
    sponsors: null,
    attachments: null,
    extra: [],
    booking_fee: null,
    custom: false,
    on_sale: true,
    published: true,
    private: false,
    featured: false,
    general_admission: false,
    instances: [
      {
        id: "16331",
        instance_ref: 116179,
        title: "LPO 12/12/20",
        date_time: "2020-12-12T19:30:00.000Z",
        __typename: "Instance",
      },
    ],
    series: null,
    venue: {
      id: "336",
      title: "Royal Festival Hall",
      venue_ref: 8,
      __typename: "Venue",
    },
    facility: {
      id: "500",
      title: "RFH 2020",
      facility_ref: 1388,
      __typename: "Facility",
    },
    modes_of_sale: [
      {
        mode_of_sale_ref: 2,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2020-12-12T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 13,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2020-12-12T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 14,
        title: null,
        start_date: "2020-12-12T17:30:00.000Z",
        end_date: "2020-12-12T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 15,
        title: null,
        start_date: "2020-07-08T08:00:00.000Z",
        end_date: "2020-07-08T08:01:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 16,
        title: null,
        start_date: "2020-07-08T08:00:00.000Z",
        end_date: "2020-07-08T08:01:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 38,
        title: null,
        start_date: "2020-01-28T09:55:00.000Z",
        end_date: "2020-12-12T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 50,
        title: null,
        start_date: "2019-11-26T13:19:44.000Z",
        end_date: "2020-12-12T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 52,
        title: null,
        start_date: "2019-11-26T13:19:44.000Z",
        end_date: "2020-12-12T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 56,
        title: null,
        start_date: "2020-07-08T08:00:00.000Z",
        end_date: "2020-07-08T08:01:00.000Z",
        __typename: "ModeOfSale",
      },
    ],
    __typename: "Event",
  },
  {
    id: "39999",
    event_ref: 115355,
    production_ref: 115347,
    season_ref: 192,
    series_id: 15,
    venue_id: 330,
    production_title: "LPO 21/01/25",
    season_title: "LPO 20/21 NS",
    title: "Das Rheingold 2021",
    subtitle: null,
    first_date: "2021-01-25T00:00:00.000Z",
    last_date: "2021-01-25T00:00:00.000Z",
    publish_up: null,
    publish_down: null,
    alias: "das-rheingold-2021",
    type: "event",
    price: null,
    previews: null,
    running_time: null,
    by_line: null,
    booking_info: null,
    booking_link: null,
    preview_info: null,
    hash_tag: null,
    short_description: null,
    description: "",
    additional: null,
    credits: null,
    access: null,
    miscellaneous: null,
    images: null,
    galleries: null,
    videos: null,
    sponsors: null,
    attachments: null,
    extra: [],
    booking_fee: null,
    custom: false,
    on_sale: true,
    published: true,
    private: false,
    featured: false,
    general_admission: false,
    instances: [
      {
        id: "16305",
        instance_ref: 115364,
        title: "LPO 25/01/21",
        date_time: "2021-01-25T19:00:00.000Z",
        __typename: "Instance",
      },
    ],
    series: null,
    venue: {
      id: "336",
      title: "Royal Festival Hall",
      venue_ref: 8,
      __typename: "Venue",
    },
    facility: null,
    modes_of_sale: [
      {
        mode_of_sale_ref: 50,
        title: null,
        start_date: "2019-10-08T09:23:10.000Z",
        end_date: "2021-01-25T19:00:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 52,
        title: null,
        start_date: "2019-10-08T09:23:10.000Z",
        end_date: "2021-01-25T19:00:00.000Z",
        __typename: "ModeOfSale",
      },
    ],
    __typename: "Event",
  },
  {
    id: "40000",
    event_ref: 115356,
    production_ref: 115348,
    season_ref: 192,
    series_id: 14,
    venue_id: 330,
    production_title: "LPO 21/01/27",
    season_title: "LPO 20/21 NS",
    title: "Die Walküre 2021",
    subtitle: null,
    first_date: "2021-01-27T00:00:00.000Z",
    last_date: "2021-01-27T00:00:00.000Z",
    publish_up: null,
    publish_down: null,
    alias: "die-walkure-2021",
    type: "event",
    price: null,
    previews: null,
    running_time: null,
    by_line: null,
    booking_info: null,
    booking_link: null,
    preview_info: null,
    hash_tag: null,
    short_description: null,
    description: "",
    additional: null,
    credits: null,
    access: null,
    miscellaneous: null,
    images: null,
    galleries: null,
    videos: null,
    sponsors: null,
    attachments: null,
    extra: [],
    booking_fee: null,
    custom: false,
    on_sale: true,
    published: true,
    private: false,
    featured: false,
    general_admission: false,
    instances: [
      {
        id: "16306",
        instance_ref: 115367,
        title: "LPO 27/01/21",
        date_time: "2021-01-27T17:00:00.000Z",
        __typename: "Instance",
      },
    ],
    series: null,
    venue: {
      id: "336",
      title: "Royal Festival Hall",
      venue_ref: 8,
      __typename: "Venue",
    },
    facility: null,
    modes_of_sale: [
      {
        mode_of_sale_ref: 50,
        title: null,
        start_date: "2019-10-08T09:29:46.000Z",
        end_date: "2021-01-27T17:00:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 52,
        title: null,
        start_date: "2019-10-08T09:29:46.000Z",
        end_date: "2021-01-27T17:00:00.000Z",
        __typename: "ModeOfSale",
      },
    ],
    __typename: "Event",
  },
  {
    id: "40001",
    event_ref: 115357,
    production_ref: 115349,
    season_ref: 192,
    series_id: 14,
    venue_id: 336,
    production_title: "LPO 21/01/29",
    season_title: "LPO 20/21 NS",
    title: "Siegfried 2021",
    subtitle: null,
    first_date: "2021-01-29T00:00:00.000Z",
    last_date: "2021-01-29T00:00:00.000Z",
    publish_up: null,
    publish_down: null,
    alias: "siegfried-2021",
    type: "event",
    price: null,
    previews: null,
    running_time: null,
    by_line: null,
    booking_info: null,
    booking_link: null,
    preview_info: null,
    hash_tag: null,
    short_description: null,
    description: "",
    additional: null,
    credits: null,
    access: null,
    miscellaneous: null,
    images: null,
    galleries: null,
    videos: null,
    sponsors: null,
    attachments: null,
    extra: [],
    booking_fee: null,
    custom: false,
    on_sale: true,
    published: true,
    private: false,
    featured: false,
    general_admission: false,
    instances: [
      {
        id: "16307",
        instance_ref: 115368,
        title: "LPO 29/01/21",
        date_time: "2021-01-29T16:00:00.000Z",
        __typename: "Instance",
      },
    ],
    series: null,
    venue: {
      id: "336",
      title: "Royal Festival Hall",
      venue_ref: 8,
      __typename: "Venue",
    },
    facility: null,
    modes_of_sale: [
      {
        mode_of_sale_ref: 50,
        title: null,
        start_date: "2019-10-08T09:31:00.000Z",
        end_date: "2021-01-29T16:00:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 52,
        title: null,
        start_date: "2019-10-08T09:31:00.000Z",
        end_date: "2021-01-29T16:00:00.000Z",
        __typename: "ModeOfSale",
      },
    ],
    __typename: "Event",
  },
  {
    id: "40002",
    event_ref: 115358,
    production_ref: 115350,
    season_ref: 192,
    series_id: 13,
    venue_id: 336,
    production_title: "LPO 21/01/31",
    season_title: "LPO 20/21 NS",
    title: "Götterdämmerung 2021",
    subtitle: null,
    first_date: "2021-01-31T00:00:00.000Z",
    last_date: "2021-01-31T00:00:00.000Z",
    publish_up: null,
    publish_down: null,
    alias: "gotterdammerung-2021",
    type: "event",
    price: null,
    previews: null,
    running_time: null,
    by_line: null,
    booking_info: null,
    booking_link: null,
    preview_info: null,
    hash_tag: null,
    short_description: null,
    description: "",
    additional: null,
    credits: null,
    access: null,
    miscellaneous: null,
    images: null,
    galleries: null,
    videos: null,
    sponsors: null,
    attachments: null,
    extra: [],
    booking_fee: null,
    custom: false,
    on_sale: true,
    published: true,
    private: false,
    featured: false,
    general_admission: false,
    instances: [
      {
        id: "16308",
        instance_ref: 115369,
        title: "LPO 31/01/21",
        date_time: "2021-01-31T15:00:00.000Z",
        __typename: "Instance",
      },
    ],
    series: null,
    venue: {
      id: "336",
      title: "Royal Festival Hall",
      venue_ref: 8,
      __typename: "Venue",
    },
    facility: null,
    modes_of_sale: [
      {
        mode_of_sale_ref: 50,
        title: null,
        start_date: "2019-10-08T09:32:20.000Z",
        end_date: "2021-01-31T15:00:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 52,
        title: null,
        start_date: "2019-10-08T09:32:20.000Z",
        end_date: "2021-01-31T15:00:00.000Z",
        __typename: "ModeOfSale",
      },
    ],
    __typename: "Event",
  },
  {
    id: "40003",
    event_ref: 115359,
    production_ref: 115351,
    season_ref: 192,
    series_id: 12,
    venue_id: 330,
    production_title: "LPO 21/02/05",
    season_title: "LPO 20/21 NS",
    title: "Das Rheingold 2021",
    subtitle: null,
    first_date: "2021-02-05T00:00:00.000Z",
    last_date: "2021-02-05T00:00:00.000Z",
    publish_up: null,
    publish_down: null,
    alias: "das-rheingold-2021",
    type: "event",
    price: null,
    previews: null,
    running_time: null,
    by_line: null,
    booking_info: null,
    booking_link: null,
    preview_info: null,
    hash_tag: null,
    short_description: null,
    description: "",
    additional: null,
    credits: null,
    access: null,
    miscellaneous: null,
    images: null,
    galleries: null,
    videos: null,
    sponsors: null,
    attachments: null,
    extra: [],
    booking_fee: null,
    custom: false,
    on_sale: true,
    published: true,
    private: false,
    featured: false,
    general_admission: false,
    instances: [
      {
        id: "16309",
        instance_ref: 115372,
        title: "LPO 05/02/21",
        date_time: "2021-02-05T19:00:00.000Z",
        __typename: "Instance",
      },
    ],
    series: null,
    venue: {
      id: "336",
      title: "Royal Festival Hall",
      venue_ref: 8,
      __typename: "Venue",
    },
    facility: null,
    modes_of_sale: [
      {
        mode_of_sale_ref: 50,
        title: null,
        start_date: "2019-10-08T09:33:24.000Z",
        end_date: "2021-02-05T19:00:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 52,
        title: null,
        start_date: "2019-10-08T09:33:24.000Z",
        end_date: "2021-02-05T19:00:00.000Z",
        __typename: "ModeOfSale",
      },
    ],
    __typename: "Event",
  },
  {
    id: "40004",
    event_ref: 115360,
    production_ref: 115352,
    season_ref: 192,
    series_id: 13,
    venue_id: 333,
    production_title: "LPO 21/02/06",
    season_title: "LPO 20/21 NS",
    title: "Die Walküre 2021",
    subtitle: null,
    first_date: "2021-02-06T00:00:00.000Z",
    last_date: "2021-02-06T00:00:00.000Z",
    publish_up: null,
    publish_down: null,
    alias: "die-walkure-2021",
    type: "event",
    price: null,
    previews: null,
    running_time: null,
    by_line: null,
    booking_info: null,
    booking_link: null,
    preview_info: null,
    hash_tag: null,
    short_description: null,
    description: "",
    additional: null,
    credits: null,
    access: null,
    miscellaneous: null,
    images: null,
    galleries: null,
    videos: null,
    sponsors: null,
    attachments: null,
    extra: [],
    booking_fee: null,
    custom: false,
    on_sale: true,
    published: true,
    private: false,
    featured: false,
    general_admission: false,
    instances: [
      {
        id: "16310",
        instance_ref: 115373,
        title: "LPO 06/02/21",
        date_time: "2021-02-06T16:00:00.000Z",
        __typename: "Instance",
      },
    ],
    series: null,
    venue: {
      id: "336",
      title: "Royal Festival Hall",
      venue_ref: 8,
      __typename: "Venue",
    },
    facility: null,
    modes_of_sale: [
      {
        mode_of_sale_ref: 50,
        title: null,
        start_date: "2019-10-08T09:34:16.000Z",
        end_date: "2021-02-06T16:00:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 52,
        title: null,
        start_date: "2019-10-08T09:34:16.000Z",
        end_date: "2021-02-06T16:00:00.000Z",
        __typename: "ModeOfSale",
      },
    ],
    __typename: "Event",
  },
  {
    id: "40005",
    event_ref: 115361,
    production_ref: 115353,
    season_ref: 192,
    series_id: 13,
    venue_id: 334,
    production_title: "LPO 21/02/08",
    season_title: "LPO 20/21 NS",
    title: "Siegfried 2021",
    subtitle: null,
    first_date: "2021-02-08T00:00:00.000Z",
    last_date: "2021-02-08T00:00:00.000Z",
    publish_up: null,
    publish_down: null,
    alias: "siegfried-2021",
    type: "event",
    price: null,
    previews: null,
    running_time: null,
    by_line: null,
    booking_info: null,
    booking_link: null,
    preview_info: null,
    hash_tag: null,
    short_description: null,
    description: "",
    additional: null,
    credits: null,
    access: null,
    miscellaneous: null,
    images: null,
    galleries: null,
    videos: null,
    sponsors: null,
    attachments: null,
    extra: [],
    booking_fee: null,
    custom: false,
    on_sale: true,
    published: true,
    private: false,
    featured: false,
    general_admission: false,
    instances: [
      {
        id: "16311",
        instance_ref: 115377,
        title: "LPO 08/02/21",
        date_time: "2021-02-08T16:00:00.000Z",
        __typename: "Instance",
      },
    ],
    series: null,
    venue: {
      id: "336",
      title: "Royal Festival Hall",
      venue_ref: 8,
      __typename: "Venue",
    },
    facility: null,
    modes_of_sale: [
      {
        mode_of_sale_ref: 50,
        title: null,
        start_date: "2019-10-08T09:45:26.000Z",
        end_date: "2021-02-08T16:00:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 52,
        title: null,
        start_date: "2019-10-08T09:45:26.000Z",
        end_date: "2021-02-08T16:00:00.000Z",
        __typename: "ModeOfSale",
      },
    ],
    __typename: "Event",
  },
  {
    id: "40006",
    event_ref: 115362,
    production_ref: 115354,
    season_ref: 192,
    series_id: 15,
    venue_id: 330,
    production_title: "LPO 21/02/10",
    season_title: "LPO 20/21 NS",
    title: "Götterdämmerung 2021",
    subtitle: null,
    first_date: "2021-02-10T00:00:00.000Z",
    last_date: "2021-02-10T00:00:00.000Z",
    publish_up: null,
    publish_down: null,
    alias: "gotterdammerung-2021",
    type: "event",
    price: null,
    previews: null,
    running_time: null,
    by_line: null,
    booking_info: null,
    booking_link: null,
    preview_info: null,
    hash_tag: null,
    short_description: null,
    description: "",
    additional: null,
    credits: null,
    access: null,
    miscellaneous: null,
    images: null,
    galleries: null,
    videos: null,
    sponsors: null,
    attachments: null,
    extra: [],
    booking_fee: null,
    custom: false,
    on_sale: true,
    published: true,
    private: false,
    featured: false,
    general_admission: false,
    instances: [
      {
        id: "16312",
        instance_ref: 115379,
        title: "LPO 10/02/21",
        date_time: "2021-02-10T15:00:00.000Z",
        __typename: "Instance",
      },
    ],
    series: null,
    venue: {
      id: "336",
      title: "Royal Festival Hall",
      venue_ref: 8,
      __typename: "Venue",
    },
    facility: null,
    modes_of_sale: [
      {
        mode_of_sale_ref: 50,
        title: null,
        start_date: "2019-10-08T09:46:32.000Z",
        end_date: "2021-02-10T20:00:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 52,
        title: null,
        start_date: "2019-10-08T09:46:32.000Z",
        end_date: "2021-02-10T20:00:00.000Z",
        __typename: "ModeOfSale",
      },
    ],
    __typename: "Event",
  },
  {
    id: "40026",
    event_ref: 116137,
    production_ref: 116093,
    season_ref: 191,
    series_id: 15,
    venue_id: 333,
    production_title: "LPO 21/02/14",
    season_title: "LPO 20/21",
    title: "Pure Romance",
    subtitle: null,
    first_date: "2021-02-14T00:00:00.000Z",
    last_date: "2021-02-14T00:00:00.000Z",
    publish_up: null,
    publish_down: null,
    alias: "pure-romance",
    type: "event",
    price: null,
    previews: null,
    running_time: null,
    by_line: null,
    booking_info: null,
    booking_link: null,
    preview_info: null,
    hash_tag: null,
    short_description: null,
    description: "",
    additional: null,
    credits: null,
    access: null,
    miscellaneous: null,
    images: null,
    galleries: null,
    videos: null,
    sponsors: null,
    attachments: null,
    extra: [],
    booking_fee: null,
    custom: false,
    on_sale: true,
    published: true,
    private: false,
    featured: false,
    general_admission: false,
    instances: [
      {
        id: "16332",
        instance_ref: 116180,
        title: "LPO 14/02/21",
        date_time: "2021-02-14T17:00:00.000Z",
        __typename: "Instance",
      },
    ],
    series: null,
    venue: {
      id: "336",
      title: "Royal Festival Hall",
      venue_ref: 8,
      __typename: "Venue",
    },
    facility: {
      id: "500",
      title: "RFH 2020",
      facility_ref: 1388,
      __typename: "Facility",
    },
    modes_of_sale: [
      {
        mode_of_sale_ref: 2,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-02-14T21:29:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 13,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-02-14T21:29:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 14,
        title: null,
        start_date: "2021-02-14T15:00:00.000Z",
        end_date: "2021-02-14T21:29:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 15,
        title: null,
        start_date: "2020-03-03T09:55:00.000Z",
        end_date: "2021-02-14T17:00:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 16,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-02-14T17:00:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 38,
        title: null,
        start_date: "2020-01-28T09:55:00.000Z",
        end_date: "2021-02-14T21:29:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 50,
        title: null,
        start_date: "2019-11-26T13:21:21.000Z",
        end_date: "2021-02-14T17:00:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 52,
        title: null,
        start_date: "2019-11-26T13:21:21.000Z",
        end_date: "2021-02-14T17:00:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 56,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-02-14T17:00:00.000Z",
        __typename: "ModeOfSale",
      },
    ],
    __typename: "Event",
  },
  {
    id: "40027",
    event_ref: 116138,
    production_ref: 116094,
    season_ref: 191,
    series_id: 15,
    venue_id: 336,
    production_title: "LPO 21/02/24",
    season_title: "LPO 20/21",
    title: "Romantics and Englishmen",
    subtitle: null,
    first_date: "2021-02-24T00:00:00.000Z",
    last_date: "2021-02-24T00:00:00.000Z",
    publish_up: null,
    publish_down: null,
    alias: "romantics-and-englishmen",
    type: "event",
    price: null,
    previews: null,
    running_time: null,
    by_line: null,
    booking_info: null,
    booking_link: null,
    preview_info: null,
    hash_tag: null,
    short_description: null,
    description: "",
    additional: null,
    credits: null,
    access: null,
    miscellaneous: null,
    images: null,
    galleries: null,
    videos: null,
    sponsors: null,
    attachments: null,
    extra: [],
    booking_fee: null,
    custom: false,
    on_sale: true,
    published: true,
    private: false,
    featured: false,
    general_admission: false,
    instances: [
      {
        id: "16333",
        instance_ref: 116181,
        title: "LPO 24/02/21",
        date_time: "2021-02-24T19:30:00.000Z",
        __typename: "Instance",
      },
    ],
    series: null,
    venue: {
      id: "336",
      title: "Royal Festival Hall",
      venue_ref: 8,
      __typename: "Venue",
    },
    facility: {
      id: "500",
      title: "RFH 2020",
      facility_ref: 1388,
      __typename: "Facility",
    },
    modes_of_sale: [
      {
        mode_of_sale_ref: 2,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-02-24T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 13,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-02-24T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 14,
        title: null,
        start_date: "2021-02-24T17:30:00.000Z",
        end_date: "2021-02-24T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 15,
        title: null,
        start_date: "2020-03-03T09:55:00.000Z",
        end_date: "2021-02-24T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 16,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-02-24T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 38,
        title: null,
        start_date: "2020-01-28T09:55:00.000Z",
        end_date: "2021-02-24T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 50,
        title: null,
        start_date: "2019-11-26T13:22:56.000Z",
        end_date: "2021-02-24T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 52,
        title: null,
        start_date: "2019-11-26T13:22:56.000Z",
        end_date: "2021-02-24T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 56,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-02-24T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
    ],
    __typename: "Event",
  },
  {
    id: "40028",
    event_ref: 116139,
    production_ref: 116095,
    season_ref: 191,
    series_id: 14,
    venue_id: 333,
    production_title: "LPO 21/02/27",
    season_title: "LPO 20/21",
    title: "Love, hate and undiscovered worlds",
    subtitle: null,
    first_date: "2021-02-27T00:00:00.000Z",
    last_date: "2021-02-27T00:00:00.000Z",
    publish_up: null,
    publish_down: null,
    alias: "love-hate-and-undiscovered-worlds",
    type: "event",
    price: null,
    previews: null,
    running_time: null,
    by_line: null,
    booking_info: null,
    booking_link: null,
    preview_info: null,
    hash_tag: null,
    short_description: null,
    description: "",
    additional: null,
    credits: null,
    access: null,
    miscellaneous: null,
    images: null,
    galleries: null,
    videos: null,
    sponsors: null,
    attachments: null,
    extra: [],
    booking_fee: null,
    custom: false,
    on_sale: true,
    published: true,
    private: false,
    featured: false,
    general_admission: false,
    instances: [
      {
        id: "16334",
        instance_ref: 116186,
        title: "LPO 27/02/21",
        date_time: "2021-02-27T19:30:00.000Z",
        __typename: "Instance",
      },
    ],
    series: null,
    venue: {
      id: "336",
      title: "Royal Festival Hall",
      venue_ref: 8,
      __typename: "Venue",
    },
    facility: {
      id: "500",
      title: "RFH 2020",
      facility_ref: 1388,
      __typename: "Facility",
    },
    modes_of_sale: [
      {
        mode_of_sale_ref: 2,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-02-27T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 13,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-02-27T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 14,
        title: null,
        start_date: "2021-02-27T17:30:00.000Z",
        end_date: "2021-02-27T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 15,
        title: null,
        start_date: "2020-03-03T09:55:00.000Z",
        end_date: "2021-02-27T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 16,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-02-27T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 38,
        title: null,
        start_date: "2020-01-28T09:55:00.000Z",
        end_date: "2021-02-27T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 50,
        title: null,
        start_date: "2019-11-26T14:35:23.000Z",
        end_date: "2021-02-27T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 52,
        title: null,
        start_date: "2019-11-26T14:35:23.000Z",
        end_date: "2021-02-27T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 56,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-02-27T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
    ],
    __typename: "Event",
  },
  {
    id: "40029",
    event_ref: 116140,
    production_ref: 116096,
    season_ref: 191,
    series_id: 12,
    venue_id: 334,
    production_title: "LPO 21/03/10",
    season_title: "LPO 20/21",
    title: "Warner Bros. presents Gene Kelly: A Life in Music",
    subtitle: null,
    first_date: "2021-03-10T00:00:00.000Z",
    last_date: "2021-03-10T00:00:00.000Z",
    publish_up: null,
    publish_down: null,
    alias: "warner-bros-presents-gene-kelly-a-life-in-music",
    type: "event",
    price: null,
    previews: null,
    running_time: null,
    by_line: null,
    booking_info: null,
    booking_link: null,
    preview_info: null,
    hash_tag: null,
    short_description: null,
    description: "",
    additional: null,
    credits: null,
    access: null,
    miscellaneous: null,
    images: null,
    galleries: null,
    videos: null,
    sponsors: null,
    attachments: null,
    extra: [],
    booking_fee: null,
    custom: false,
    on_sale: true,
    published: true,
    private: false,
    featured: false,
    general_admission: false,
    instances: [
      {
        id: "16335",
        instance_ref: 116187,
        title: "LPO 10/03/21",
        date_time: "2021-03-10T19:30:00.000Z",
        __typename: "Instance",
      },
    ],
    series: null,
    venue: {
      id: "336",
      title: "Royal Festival Hall",
      venue_ref: 8,
      __typename: "Venue",
    },
    facility: {
      id: "500",
      title: "RFH 2020",
      facility_ref: 1388,
      __typename: "Facility",
    },
    modes_of_sale: [
      {
        mode_of_sale_ref: 2,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-03-10T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 13,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-03-10T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 14,
        title: null,
        start_date: "2021-03-10T17:30:00.000Z",
        end_date: "2021-03-10T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 15,
        title: null,
        start_date: "2020-03-03T09:55:00.000Z",
        end_date: "2021-03-10T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 16,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-03-10T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 38,
        title: null,
        start_date: "2020-01-28T09:55:00.000Z",
        end_date: "2021-03-10T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 50,
        title: null,
        start_date: "2019-11-26T14:36:41.000Z",
        end_date: "2021-03-10T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 52,
        title: null,
        start_date: "2019-11-26T14:36:41.000Z",
        end_date: "2021-03-10T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 56,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-03-10T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
    ],
    __typename: "Event",
  },
  {
    id: "40030",
    event_ref: 116141,
    production_ref: 116097,
    season_ref: 191,
    series_id: 12,
    venue_id: 336,
    production_title: "LPO 21/03/13",
    season_title: "LPO 20/21",
    title: "Resurrection Symphony",
    subtitle: null,
    first_date: "2021-03-13T00:00:00.000Z",
    last_date: "2021-03-13T00:00:00.000Z",
    publish_up: null,
    publish_down: null,
    alias: "resurrection-symphony",
    type: "event",
    price: null,
    previews: null,
    running_time: null,
    by_line: null,
    booking_info: null,
    booking_link: null,
    preview_info: null,
    hash_tag: null,
    short_description: null,
    description: "",
    additional: null,
    credits: null,
    access: null,
    miscellaneous: null,
    images: null,
    galleries: null,
    videos: null,
    sponsors: null,
    attachments: null,
    extra: [],
    booking_fee: null,
    custom: false,
    on_sale: true,
    published: true,
    private: false,
    featured: false,
    general_admission: false,
    instances: [
      {
        id: "16336",
        instance_ref: 116188,
        title: "LPO 13/03/21",
        date_time: "2021-03-13T19:30:00.000Z",
        __typename: "Instance",
      },
    ],
    series: null,
    venue: {
      id: "336",
      title: "Royal Festival Hall",
      venue_ref: 8,
      __typename: "Venue",
    },
    facility: {
      id: "500",
      title: "RFH 2020",
      facility_ref: 1388,
      __typename: "Facility",
    },
    modes_of_sale: [
      {
        mode_of_sale_ref: 2,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-03-13T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 13,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-03-13T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 14,
        title: null,
        start_date: "2021-03-13T17:30:00.000Z",
        end_date: "2021-03-13T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 15,
        title: null,
        start_date: "2020-03-03T09:55:00.000Z",
        end_date: "2021-03-13T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 16,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-03-13T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 38,
        title: null,
        start_date: "2020-01-28T09:55:00.000Z",
        end_date: "2021-03-13T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 50,
        title: null,
        start_date: "2019-11-26T14:37:51.000Z",
        end_date: "2021-03-13T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 52,
        title: null,
        start_date: "2019-11-26T14:37:51.000Z",
        end_date: "2021-03-13T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 56,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-03-13T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
    ],
    __typename: "Event",
  },
  {
    id: "40031",
    event_ref: 116143,
    production_ref: 116109,
    season_ref: 191,
    series_id: 12,
    venue_id: 330,
    production_title: "LPO 21/03/14",
    season_title: "LPO 20/21",
    title: "The Walkabout Orchestra",
    subtitle: null,
    first_date: "2021-03-14T00:00:00.000Z",
    last_date: "2021-03-14T00:00:00.000Z",
    publish_up: null,
    publish_down: null,
    alias: "the-walkabout-orchestra",
    type: "event",
    price: null,
    previews: null,
    running_time: null,
    by_line: null,
    booking_info: null,
    booking_link: null,
    preview_info: null,
    hash_tag: null,
    short_description: null,
    description: "",
    additional: null,
    credits: null,
    access: null,
    miscellaneous: null,
    images: null,
    galleries: null,
    videos: null,
    sponsors: null,
    attachments: null,
    extra: [],
    booking_fee: null,
    custom: false,
    on_sale: true,
    published: true,
    private: false,
    featured: false,
    general_admission: false,
    instances: [
      {
        id: "16337",
        instance_ref: 116199,
        title: "LPO 14/03/21",
        date_time: "2021-03-14T12:00:00.000Z",
        __typename: "Instance",
      },
    ],
    series: null,
    venue: {
      id: "336",
      title: "Royal Festival Hall",
      venue_ref: 8,
      __typename: "Venue",
    },
    facility: {
      id: "500",
      title: "RFH 2020",
      facility_ref: 1388,
      __typename: "Facility",
    },
    modes_of_sale: [
      {
        mode_of_sale_ref: 2,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-03-14T16:29:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 13,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-03-14T16:29:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 14,
        title: null,
        start_date: "2021-03-14T10:00:00.000Z",
        end_date: "2021-03-14T16:29:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 15,
        title: null,
        start_date: "2020-03-03T09:55:00.000Z",
        end_date: "2021-03-14T12:00:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 16,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-03-14T12:00:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 38,
        title: null,
        start_date: "2020-01-28T09:55:00.000Z",
        end_date: "2021-03-14T16:29:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 50,
        title: null,
        start_date: "2019-11-26T15:00:18.000Z",
        end_date: "2021-03-14T12:00:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 52,
        title: null,
        start_date: "2019-11-26T15:00:18.000Z",
        end_date: "2021-03-14T12:00:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 56,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-03-14T12:00:00.000Z",
        __typename: "ModeOfSale",
      },
    ],
    __typename: "Event",
  },
  {
    id: "40032",
    event_ref: 116144,
    production_ref: 116098,
    season_ref: 191,
    series_id: 13,
    venue_id: 334,
    production_title: "LPO 21/03/19",
    season_title: "LPO 20/21",
    title: "Bohemian rhapsodies",
    subtitle: null,
    first_date: "2021-03-19T00:00:00.000Z",
    last_date: "2021-03-19T00:00:00.000Z",
    publish_up: null,
    publish_down: null,
    alias: "bohemian-rhapsodies",
    type: "event",
    price: null,
    previews: null,
    running_time: null,
    by_line: null,
    booking_info: null,
    booking_link: null,
    preview_info: null,
    hash_tag: null,
    short_description: null,
    description: "",
    additional: null,
    credits: null,
    access: null,
    miscellaneous: null,
    images: null,
    galleries: null,
    videos: null,
    sponsors: null,
    attachments: null,
    extra: [],
    booking_fee: null,
    custom: false,
    on_sale: true,
    published: true,
    private: false,
    featured: false,
    general_admission: false,
    instances: [
      {
        id: "16338",
        instance_ref: 116189,
        title: "LPO 19/03/21",
        date_time: "2021-03-19T19:30:00.000Z",
        __typename: "Instance",
      },
    ],
    series: null,
    venue: {
      id: "336",
      title: "Royal Festival Hall",
      venue_ref: 8,
      __typename: "Venue",
    },
    facility: {
      id: "500",
      title: "RFH 2020",
      facility_ref: 1388,
      __typename: "Facility",
    },
    modes_of_sale: [
      {
        mode_of_sale_ref: 2,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-03-19T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 13,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-03-19T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 14,
        title: null,
        start_date: "2021-03-19T17:30:00.000Z",
        end_date: "2021-03-19T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 15,
        title: null,
        start_date: "2020-03-03T09:55:00.000Z",
        end_date: "2021-03-19T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 16,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-03-19T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 38,
        title: null,
        start_date: "2020-01-28T09:55:00.000Z",
        end_date: "2021-03-19T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 50,
        title: null,
        start_date: "2019-11-26T14:45:17.000Z",
        end_date: "2021-03-19T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 52,
        title: null,
        start_date: "2019-11-26T14:45:17.000Z",
        end_date: "2021-03-19T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 56,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-03-19T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
    ],
    __typename: "Event",
  },
  {
    id: "40033",
    event_ref: 116146,
    production_ref: 116099,
    season_ref: 191,
    series_id: 14,
    venue_id: 333,
    production_title: "LPO 21/03/24",
    season_title: "LPO 20/21",
    title: "Emelyanychev conducts Brahms",
    subtitle: null,
    first_date: "2021-03-24T00:00:00.000Z",
    last_date: "2021-03-24T00:00:00.000Z",
    publish_up: null,
    publish_down: null,
    alias: "emelyanychev-conducts-brahms",
    type: "event",
    price: null,
    previews: null,
    running_time: null,
    by_line: null,
    booking_info: null,
    booking_link: null,
    preview_info: null,
    hash_tag: null,
    short_description: null,
    description: "",
    additional: null,
    credits: null,
    access: null,
    miscellaneous: null,
    images: null,
    galleries: null,
    videos: null,
    sponsors: null,
    attachments: null,
    extra: [],
    booking_fee: null,
    custom: false,
    on_sale: true,
    published: true,
    private: false,
    featured: false,
    general_admission: false,
    instances: [
      {
        id: "16339",
        instance_ref: 116190,
        title: "LPO 24/03/21",
        date_time: "2021-03-24T19:30:00.000Z",
        __typename: "Instance",
      },
    ],
    series: null,
    venue: {
      id: "336",
      title: "Royal Festival Hall",
      venue_ref: 8,
      __typename: "Venue",
    },
    facility: {
      id: "500",
      title: "RFH 2020",
      facility_ref: 1388,
      __typename: "Facility",
    },
    modes_of_sale: [
      {
        mode_of_sale_ref: 2,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-03-24T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 13,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-03-24T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 14,
        title: null,
        start_date: "2021-03-24T17:30:00.000Z",
        end_date: "2021-03-24T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 15,
        title: null,
        start_date: "2020-03-03T09:55:00.000Z",
        end_date: "2021-03-24T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 16,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-03-24T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 38,
        title: null,
        start_date: "2020-01-28T09:55:00.000Z",
        end_date: "2021-03-24T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 50,
        title: null,
        start_date: "2019-11-26T14:46:48.000Z",
        end_date: "2021-03-24T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 52,
        title: null,
        start_date: "2019-11-26T14:46:48.000Z",
        end_date: "2021-03-24T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 56,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-03-24T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
    ],
    __typename: "Event",
  },
  {
    id: "40034",
    event_ref: 116147,
    production_ref: 116101,
    season_ref: 191,
    series_id: 14,
    venue_id: 333,
    production_title: "LPO 21/03/26",
    season_title: "LPO 20/21",
    title: "Danny Elfman's Percussion Concerto",
    subtitle: null,
    first_date: "2021-03-26T00:00:00.000Z",
    last_date: "2021-03-26T00:00:00.000Z",
    publish_up: null,
    publish_down: null,
    alias: "danny-elfmans-percussion-concerto",
    type: "event",
    price: null,
    previews: null,
    running_time: null,
    by_line: null,
    booking_info: null,
    booking_link: null,
    preview_info: null,
    hash_tag: null,
    short_description: null,
    description: "",
    additional: null,
    credits: null,
    access: null,
    miscellaneous: null,
    images: null,
    galleries: null,
    videos: null,
    sponsors: null,
    attachments: null,
    extra: [],
    booking_fee: null,
    custom: false,
    on_sale: true,
    published: true,
    private: false,
    featured: false,
    general_admission: false,
    instances: [
      {
        id: "16340",
        instance_ref: 116191,
        title: "LPO 26/03/21",
        date_time: "2021-03-26T19:30:00.000Z",
        __typename: "Instance",
      },
    ],
    series: null,
    venue: {
      id: "336",
      title: "Royal Festival Hall",
      venue_ref: 8,
      __typename: "Venue",
    },
    facility: {
      id: "500",
      title: "RFH 2020",
      facility_ref: 1388,
      __typename: "Facility",
    },
    modes_of_sale: [
      {
        mode_of_sale_ref: 2,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-03-26T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 13,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-03-26T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 14,
        title: null,
        start_date: "2021-03-26T17:30:00.000Z",
        end_date: "2021-03-26T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 15,
        title: null,
        start_date: "2020-03-03T09:55:00.000Z",
        end_date: "2021-03-26T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 16,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-03-26T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 38,
        title: null,
        start_date: "2020-01-28T09:55:00.000Z",
        end_date: "2021-03-26T23:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 50,
        title: null,
        start_date: "2019-11-26T14:47:59.000Z",
        end_date: "2021-03-26T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 52,
        title: null,
        start_date: "2019-11-26T14:47:59.000Z",
        end_date: "2021-03-26T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 56,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-03-26T19:30:00.000Z",
        __typename: "ModeOfSale",
      },
    ],
    __typename: "Event",
  },
  {
    id: "40035",
    event_ref: 116148,
    production_ref: 116102,
    season_ref: 191,
    series_id: 14,
    venue_id: 336,
    production_title: "LPO 21/03/31",
    season_title: "LPO 20/21",
    title: "Postcards from Paris",
    subtitle: null,
    first_date: "2021-03-31T00:00:00.000Z",
    last_date: "2021-03-31T00:00:00.000Z",
    publish_up: null,
    publish_down: null,
    alias: "postcards-from-paris",
    type: "event",
    price: null,
    previews: null,
    running_time: null,
    by_line: null,
    booking_info: null,
    booking_link: null,
    preview_info: null,
    hash_tag: null,
    short_description: null,
    description: "",
    additional: null,
    credits: null,
    access: null,
    miscellaneous: null,
    images: null,
    galleries: null,
    videos: null,
    sponsors: null,
    attachments: null,
    extra: [],
    booking_fee: null,
    custom: false,
    on_sale: true,
    published: true,
    private: false,
    featured: false,
    general_admission: false,
    instances: [
      {
        id: "16341",
        instance_ref: 116192,
        title: "LPO 31/03/21",
        date_time: "2021-03-31T18:30:00.000Z",
        __typename: "Instance",
      },
    ],
    series: null,
    venue: {
      id: "336",
      title: "Royal Festival Hall",
      venue_ref: 8,
      __typename: "Venue",
    },
    facility: {
      id: "500",
      title: "RFH 2020",
      facility_ref: 1388,
      __typename: "Facility",
    },
    modes_of_sale: [
      {
        mode_of_sale_ref: 2,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-03-31T22:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 13,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-03-31T22:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 14,
        title: null,
        start_date: "2021-03-31T16:30:00.000Z",
        end_date: "2021-03-31T22:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 15,
        title: null,
        start_date: "2020-03-03T09:55:00.000Z",
        end_date: "2021-03-31T18:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 16,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-03-31T18:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 38,
        title: null,
        start_date: "2020-01-28T09:55:00.000Z",
        end_date: "2021-03-31T22:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 50,
        title: null,
        start_date: "2019-11-26T14:49:21.000Z",
        end_date: "2021-03-31T18:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 52,
        title: null,
        start_date: "2019-11-26T14:49:21.000Z",
        end_date: "2021-03-31T18:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 56,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-03-31T18:30:00.000Z",
        __typename: "ModeOfSale",
      },
    ],
    __typename: "Event",
  },
  {
    id: "40036",
    event_ref: 116149,
    production_ref: 116103,
    season_ref: 191,
    series_id: 14,
    venue_id: 333,
    production_title: "LPO 21/04/14",
    season_title: "LPO 20/21",
    title: "Canellakis conducts Shostakovich",
    subtitle: null,
    first_date: "2021-04-14T00:00:00.000Z",
    last_date: "2021-04-14T00:00:00.000Z",
    publish_up: null,
    publish_down: null,
    alias: "canellakis-conducts-shostakovich",
    type: "event",
    price: null,
    previews: null,
    running_time: null,
    by_line: null,
    booking_info: null,
    booking_link: null,
    preview_info: null,
    hash_tag: null,
    short_description: null,
    description: "",
    additional: null,
    credits: null,
    access: null,
    miscellaneous: null,
    images: null,
    galleries: null,
    videos: null,
    sponsors: null,
    attachments: null,
    extra: [],
    booking_fee: null,
    custom: false,
    on_sale: true,
    published: true,
    private: false,
    featured: false,
    general_admission: false,
    instances: [
      {
        id: "16342",
        instance_ref: 116193,
        title: "LPO 14/04/21",
        date_time: "2021-04-14T18:30:00.000Z",
        __typename: "Instance",
      },
    ],
    series: null,
    venue: {
      id: "336",
      title: "Royal Festival Hall",
      venue_ref: 8,
      __typename: "Venue",
    },
    facility: {
      id: "500",
      title: "RFH 2020",
      facility_ref: 1388,
      __typename: "Facility",
    },
    modes_of_sale: [
      {
        mode_of_sale_ref: 2,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-04-14T22:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 13,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-04-14T22:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 14,
        title: null,
        start_date: "2021-04-14T16:30:00.000Z",
        end_date: "2021-04-14T22:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 15,
        title: null,
        start_date: "2020-03-03T09:55:00.000Z",
        end_date: "2021-04-14T18:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 16,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-04-14T18:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 38,
        title: null,
        start_date: "2020-01-28T09:55:00.000Z",
        end_date: "2021-04-14T22:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 50,
        title: null,
        start_date: "2019-11-26T14:50:39.000Z",
        end_date: "2021-04-14T18:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 52,
        title: null,
        start_date: "2019-11-26T14:50:39.000Z",
        end_date: "2021-04-14T18:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 56,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-04-14T18:30:00.000Z",
        __typename: "ModeOfSale",
      },
    ],
    __typename: "Event",
  },
  {
    id: "40037",
    event_ref: 116150,
    production_ref: 116104,
    season_ref: 191,
    series_id: 12,
    venue_id: 334,
    production_title: "LPO 21/04/16",
    season_title: "LPO 20/21",
    title: "Hough plays Brahms",
    subtitle: null,
    first_date: "2021-04-16T00:00:00.000Z",
    last_date: "2021-04-16T00:00:00.000Z",
    publish_up: null,
    publish_down: null,
    alias: "hough-plays-brahms",
    type: "event",
    price: null,
    previews: null,
    running_time: null,
    by_line: null,
    booking_info: null,
    booking_link: null,
    preview_info: null,
    hash_tag: null,
    short_description: null,
    description: "",
    additional: null,
    credits: null,
    access: null,
    miscellaneous: null,
    images: null,
    galleries: null,
    videos: null,
    sponsors: null,
    attachments: null,
    extra: [],
    booking_fee: null,
    custom: false,
    on_sale: true,
    published: true,
    private: false,
    featured: false,
    general_admission: false,
    instances: [
      {
        id: "16343",
        instance_ref: 116194,
        title: "LPO 16/04/21",
        date_time: "2021-04-16T18:30:00.000Z",
        __typename: "Instance",
      },
    ],
    series: null,
    venue: {
      id: "336",
      title: "Royal Festival Hall",
      venue_ref: 8,
      __typename: "Venue",
    },
    facility: {
      id: "500",
      title: "RFH 2020",
      facility_ref: 1388,
      __typename: "Facility",
    },
    modes_of_sale: [
      {
        mode_of_sale_ref: 2,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-04-16T22:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 13,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-04-16T22:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 14,
        title: null,
        start_date: "2021-04-16T16:30:00.000Z",
        end_date: "2021-04-16T22:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 15,
        title: null,
        start_date: "2020-03-03T09:55:00.000Z",
        end_date: "2021-04-16T18:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 16,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-04-16T18:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 38,
        title: null,
        start_date: "2020-01-28T09:55:00.000Z",
        end_date: "2021-04-16T22:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 50,
        title: null,
        start_date: "2019-11-26T14:51:55.000Z",
        end_date: "2021-04-16T18:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 52,
        title: null,
        start_date: "2019-11-26T14:51:55.000Z",
        end_date: "2021-04-16T18:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 56,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-04-16T18:30:00.000Z",
        __typename: "ModeOfSale",
      },
    ],
    __typename: "Event",
  },
  {
    id: "40038",
    event_ref: 116151,
    production_ref: 116105,
    season_ref: 191,
    series_id: 13,
    venue_id: 336,
    production_title: "LPO 21/04/21",
    season_title: "LPO 20/21",
    title: "Russian epics",
    subtitle: null,
    first_date: "2021-04-21T00:00:00.000Z",
    last_date: "2021-04-21T00:00:00.000Z",
    publish_up: null,
    publish_down: null,
    alias: "russian-epics",
    type: "event",
    price: null,
    previews: null,
    running_time: null,
    by_line: null,
    booking_info: null,
    booking_link: null,
    preview_info: null,
    hash_tag: null,
    short_description: null,
    description: "",
    additional: null,
    credits: null,
    access: null,
    miscellaneous: null,
    images: null,
    galleries: null,
    videos: null,
    sponsors: null,
    attachments: null,
    extra: [],
    booking_fee: null,
    custom: false,
    on_sale: true,
    published: true,
    private: false,
    featured: false,
    general_admission: false,
    instances: [
      {
        id: "16344",
        instance_ref: 116195,
        title: "LPO 21/04/21",
        date_time: "2021-04-21T18:30:00.000Z",
        __typename: "Instance",
      },
    ],
    series: null,
    venue: {
      id: "336",
      title: "Royal Festival Hall",
      venue_ref: 8,
      __typename: "Venue",
    },
    facility: {
      id: "500",
      title: "RFH 2020",
      facility_ref: 1388,
      __typename: "Facility",
    },
    modes_of_sale: [
      {
        mode_of_sale_ref: 2,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-04-21T22:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 13,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-04-21T22:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 14,
        title: null,
        start_date: "2021-04-21T16:30:00.000Z",
        end_date: "2021-04-21T22:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 15,
        title: null,
        start_date: "2020-03-03T09:55:00.000Z",
        end_date: "2021-04-21T18:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 16,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-04-21T18:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 38,
        title: null,
        start_date: "2020-01-28T09:55:00.000Z",
        end_date: "2021-04-21T22:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 50,
        title: null,
        start_date: "2019-11-26T14:54:11.000Z",
        end_date: "2021-04-21T18:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 52,
        title: null,
        start_date: "2019-11-26T14:54:11.000Z",
        end_date: "2021-04-21T18:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 56,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-04-21T18:30:00.000Z",
        __typename: "ModeOfSale",
      },
    ],
    __typename: "Event",
  },
  {
    id: "40039",
    event_ref: 116152,
    production_ref: 116106,
    season_ref: 191,
    series_id: 14,
    venue_id: 330,
    production_title: "LPO 21/04/24",
    season_title: "LPO 20/21",
    title: "Exiles and elegies",
    subtitle: null,
    first_date: "2021-04-24T00:00:00.000Z",
    last_date: "2021-04-24T00:00:00.000Z",
    publish_up: null,
    publish_down: null,
    alias: "exiles-and-elegies",
    type: "event",
    price: null,
    previews: null,
    running_time: null,
    by_line: null,
    booking_info: null,
    booking_link: null,
    preview_info: null,
    hash_tag: null,
    short_description: null,
    description: "",
    additional: null,
    credits: null,
    access: null,
    miscellaneous: null,
    images: null,
    galleries: null,
    videos: null,
    sponsors: null,
    attachments: null,
    extra: [],
    booking_fee: null,
    custom: false,
    on_sale: true,
    published: true,
    private: false,
    featured: false,
    general_admission: false,
    instances: [
      {
        id: "16345",
        instance_ref: 116196,
        title: "LPO 24/04/21",
        date_time: "2021-04-24T18:30:00.000Z",
        __typename: "Instance",
      },
    ],
    series: null,
    venue: {
      id: "336",
      title: "Royal Festival Hall",
      venue_ref: 8,
      __typename: "Venue",
    },
    facility: {
      id: "500",
      title: "RFH 2020",
      facility_ref: 1388,
      __typename: "Facility",
    },
    modes_of_sale: [
      {
        mode_of_sale_ref: 2,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-04-24T22:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 13,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-04-24T22:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 14,
        title: null,
        start_date: "2021-04-24T16:30:00.000Z",
        end_date: "2021-04-24T22:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 15,
        title: null,
        start_date: "2020-03-03T09:55:00.000Z",
        end_date: "2021-04-24T18:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 16,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-04-24T18:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 38,
        title: null,
        start_date: "2020-01-28T09:55:00.000Z",
        end_date: "2021-04-24T22:59:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 50,
        title: null,
        start_date: "2019-11-26T14:55:19.000Z",
        end_date: "2021-04-24T18:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 52,
        title: null,
        start_date: "2019-11-26T14:55:19.000Z",
        end_date: "2021-04-24T18:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 56,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-04-24T18:30:00.000Z",
        __typename: "ModeOfSale",
      },
    ],
    __typename: "Event",
  },
  {
    id: "40040",
    event_ref: 116153,
    production_ref: 116107,
    season_ref: 191,
    series_id: 15,
    venue_id: 330,
    production_title: "LPO 21/04/28",
    season_title: "LPO 20/21",
    title: "Jurowski conducts Swan Lake",
    subtitle: null,
    first_date: "2021-04-28T00:00:00.000Z",
    last_date: "2021-04-28T00:00:00.000Z",
    publish_up: null,
    publish_down: null,
    alias: "jurowski-conducts-swan-lake",
    type: "event",
    price: null,
    previews: null,
    running_time: null,
    by_line: null,
    booking_info: null,
    booking_link: null,
    preview_info: null,
    hash_tag: null,
    short_description: null,
    description: "",
    additional: null,
    credits: null,
    access: null,
    miscellaneous: null,
    images: null,
    galleries: null,
    videos: null,
    sponsors: null,
    attachments: null,
    extra: [],
    booking_fee: null,
    custom: false,
    on_sale: true,
    published: true,
    private: false,
    featured: false,
    general_admission: false,
    instances: [
      {
        id: "16346",
        instance_ref: 116197,
        title: "LPO 28/04/21",
        date_time: "2021-04-28T18:00:00.000Z",
        __typename: "Instance",
      },
    ],
    series: null,
    venue: {
      id: "336",
      title: "Royal Festival Hall",
      venue_ref: 8,
      __typename: "Venue",
    },
    facility: {
      id: "500",
      title: "RFH 2020",
      facility_ref: 1388,
      __typename: "Facility",
    },
    modes_of_sale: [
      {
        mode_of_sale_ref: 2,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-04-28T22:29:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 13,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-04-28T22:29:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 14,
        title: null,
        start_date: "2021-04-28T16:00:00.000Z",
        end_date: "2021-04-28T22:29:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 15,
        title: null,
        start_date: "2020-03-03T09:55:00.000Z",
        end_date: "2021-04-28T18:00:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 16,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-04-28T18:00:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 38,
        title: null,
        start_date: "2020-01-28T09:55:00.000Z",
        end_date: "2021-04-28T22:29:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 50,
        title: null,
        start_date: "2019-11-26T14:56:35.000Z",
        end_date: "2021-04-28T18:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 52,
        title: null,
        start_date: "2019-11-26T14:56:35.000Z",
        end_date: "2021-04-28T18:30:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 56,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-04-28T18:00:00.000Z",
        __typename: "ModeOfSale",
      },
    ],
    __typename: "Event",
  },
  {
    id: "40041",
    event_ref: 116154,
    production_ref: 116110,
    season_ref: 191,
    series_id: 14,
    venue_id: 330,
    production_title: "LPO 21/05/08",
    season_title: "LPO 20/21",
    title: "The Pied Piper of Hamelin",
    subtitle: null,
    first_date: "2021-05-08T00:00:00.000Z",
    last_date: "2021-05-08T00:00:00.000Z",
    publish_up: null,
    publish_down: null,
    alias: "the-pied-piper-of-hamelin",
    type: "event",
    price: null,
    previews: null,
    running_time: null,
    by_line: null,
    booking_info: null,
    booking_link: null,
    preview_info: null,
    hash_tag: null,
    short_description: null,
    description: "",
    additional: null,
    credits: null,
    access: null,
    miscellaneous: null,
    images: null,
    galleries: null,
    videos: null,
    sponsors: null,
    attachments: null,
    extra: [],
    booking_fee: null,
    custom: false,
    on_sale: true,
    published: true,
    private: false,
    featured: false,
    general_admission: false,
    instances: [
      {
        id: "16347",
        instance_ref: 116200,
        title: "LPO 08/05/21",
        date_time: "2021-05-08T11:00:00.000Z",
        __typename: "Instance",
      },
    ],
    series: null,
    venue: {
      id: "336",
      title: "Royal Festival Hall",
      venue_ref: 8,
      __typename: "Venue",
    },
    facility: {
      id: "500",
      title: "RFH 2020",
      facility_ref: 1388,
      __typename: "Facility",
    },
    modes_of_sale: [
      {
        mode_of_sale_ref: 2,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-05-08T15:29:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 13,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-05-08T15:29:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 14,
        title: null,
        start_date: "2021-05-08T09:00:00.000Z",
        end_date: "2021-05-08T15:29:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 15,
        title: null,
        start_date: "2020-03-03T09:55:00.000Z",
        end_date: "2021-05-08T11:00:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 16,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-05-08T11:00:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 38,
        title: null,
        start_date: "2020-01-28T09:55:00.000Z",
        end_date: "2021-05-08T15:29:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 50,
        title: null,
        start_date: "2019-11-26T15:01:39.000Z",
        end_date: "2021-05-08T11:00:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 52,
        title: null,
        start_date: "2019-11-26T15:01:39.000Z",
        end_date: "2021-05-08T11:00:00.000Z",
        __typename: "ModeOfSale",
      },
      {
        mode_of_sale_ref: 56,
        title: null,
        start_date: "2020-02-27T09:55:00.000Z",
        end_date: "2021-05-08T11:00:00.000Z",
        __typename: "ModeOfSale",
      },
    ],
    __typename: "Event",
  },
];

