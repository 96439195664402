// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".text--widen{letter-spacing:.15px}[aria-expanded=true] .accordion__icon:not(.accordion__icon--no-rotate){transform:rotate(180deg)}.accordion__content{border:none;overflow:hidden;border-bottom:0;background:#fff;transition:height .2s ease-in-out}.accordion__content--default{padding:1rem}.accordion__control-trigger{width:100%;display:flex;justify-content:space-between;position:relative;text-align:left;align-items:flex-start;padding:1rem;transition:all .2s ease-in-out}@media(min-width:40em){.accordion__control-trigger{min-height:4rem;align-items:center}}.accordion__control-trigger:hover{cursor:pointer}@media(min-width:40em){.accordion__control-trigger:hover{background:#f2f9f9}}.accordion__control-trigger:focus{outline:none;box-shadow:none}.accordion__control-trigger:focus svg{border:1px dotted #231f20}.accordion__control-trigger h1{font-size:1rem;margin:0;line-height:1.5}.accordion__control-trigger p{margin:0}.accordion__control-trigger svg{width:16px;margin-top:.3125rem}@media(min-width:40em){.accordion__control-trigger svg{margin-top:unset}}.accordion__icon{transition:all .2s ease-in-out}.accordion__control-heading{margin:0}.accordion:not(:last-of-type) .accordion__control-trigger{border-bottom:0}.accordion--no-border .accordion__content,.accordion--no-border .accordion__control-trigger{border:none}.accordion--no-pad .accordion__content,.accordion--no-pad .accordion__control-trigger{padding:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"price1": "#d3048e",
	"price2": "#936d22",
	"price3": "#617e26",
	"price4": "#196e19",
	"price5": "#056868",
	"price6": "#5252b6",
	"price7": "#8d0dcd",
	"price8": "#bc2e2d"
};
module.exports = ___CSS_LOADER_EXPORT___;
