// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".text--widen{letter-spacing:.15px}.error-component{margin:0 auto;display:flex;flex-direction:column;justify-content:flex-start;align-items:center;max-width:768px;padding:8em 1.25rem 0;min-height:100vh;text-align:center}.error-component h1{font-size:9.375rem;line-height:.7;opacity:1%;font-weight:300}@media(min-width:64em){.error-component h1{font-size:18.75rem}}.error-component h2{font-size:24px;line-height:32px;margin-bottom:.5rem}@media(min-width:64em){.error-component h2{font-size:32px;line-height:48px}}.error-component h2 span{display:block}@media(min-width:64em){.error-component h2 span{display:inline-block}}.error-component h3{font-size:22px}.error-component p{font-weight:700;line-height:1.5rem;font-size:1.125rem;margin-bottom:1rem}@media(min-width:64em){.error-component p{margin-bottom:.5rem}}.error-component p.secondary-info{font-weight:400;font-size:.8125rem;line-height:1rem}.error-component .button{display:inline-block;color:#fff;width:auto;margin:.5em auto}@media(min-width:64em){.error-component h1.basket-empty__header{font-size:5rem;line-height:5.625rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"price1": "#d3048e",
	"price2": "#936d22",
	"price3": "#617e26",
	"price4": "#196e19",
	"price5": "#056868",
	"price6": "#5252b6",
	"price7": "#8d0dcd",
	"price8": "#bc2e2d"
};
module.exports = ___CSS_LOADER_EXPORT___;
