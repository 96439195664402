// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".text--widen{letter-spacing:.15px}.modal--confirmation{align-items:flex-start;padding-top:4rem}.modal--confirmation .modal__content{text-align:center}@media(min-width:40em){.modal--confirmation .modal__content{width:26.25rem}}.modal--confirmation .modal__actions{display:grid;column-gap:1rem;row-gap:1rem;grid-template-columns:1fr 1fr}.modal--confirmation .modal__actions button{min-width:unset}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"price1": "#d3048e",
	"price2": "#936d22",
	"price3": "#617e26",
	"price4": "#196e19",
	"price5": "#056868",
	"price6": "#5252b6",
	"price7": "#8d0dcd",
	"price8": "#bc2e2d"
};
module.exports = ___CSS_LOADER_EXPORT___;
