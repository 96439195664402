















import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class IframeContainer extends Vue {
  @Prop({ type: String }) name?: string
  @Prop({ type: String }) id?: string
  @Prop({ type: String, required: true }) src?: string
  @Prop({ type: Object }) params?: object
}
