import { ActionTree, MutationTree, GetterTree, ActionContext } from 'vuex'
import { RootState } from './types'
import { config } from '~/config'

export const name = 'session'

export const namespaced = true

export const types = {
  SET_REDIRECT: 'SET_REDIRECT',
  SET_AUTO_DONATION_APPLIED: 'SET_AUTO_DONATION_APPLIED',
  SET_AUTO_DONATION_REFUSED: 'SET_AUTO_DONATION_REFUSED',
  SET_AUTO_DONATION_BASKET_ID: 'SET_AUTO_DONATION_BASKET_ID',
  SET_SEAT_MAP_INSTRUCTIONS: 'SET_SEAT_MAP_INSTRUCTIONS',
  SET_SHOW_FEEDBACK_BAR: 'SET_SHOW_FEEDBACK_BAR',
  SET_CLASS_INFO_DISMISSED: 'SET_CLASS_INFO_DISMISSED',
}

export interface State {
  redirect?: string
  autoDonationApplied?: boolean
  autoDonationRefused?: boolean
  autoDonationBasketId?: number | null
  seatMapInstructionsDismissed?: boolean
  showFeedbackBar?: Boolean
  classInfoDismissed: boolean
}

/**
 * Initial state, empty array of events
 */
export const state = (): State => ({
  redirect: config.URLS.account,
  autoDonationApplied: false,
  autoDonationRefused: false,
  autoDonationBasketId: null,
  seatMapInstructionsDismissed: false,
  showFeedbackBar: true,
  classInfoDismissed: false,
})

export const getters: GetterTree<State, RootState> = {
  showFeedbackBar: (state: State): Boolean | undefined => {
    return state.showFeedbackBar
  },
}

export const actions: ActionTree<State, RootState> = {
  async setRedirect(
    context: ActionContext<State, RootState>,
    redirect: string
  ): Promise<boolean> {
    return new Promise((resolve) => {
      context.commit(types.SET_REDIRECT, redirect)
      resolve(true)
    })
  },

  async getRedirect(
    context: ActionContext<State, RootState>
  ): Promise<boolean> {
    return new Promise((resolve) => {
      resolve(context.state.redirect)
    })
  },

  async getAutoDonationBasketId(
    context: ActionContext<State, RootState>
  ): Promise<number> {
    return new Promise((resolve) => {
      resolve(context.state.autoDonationBasketId)
    })
  },

  setShowFeedbackBar(
    context: ActionContext<State, RootState>,
    payload: boolean
  ): void {
    context.commit(types.SET_SHOW_FEEDBACK_BAR, payload)
  },
}

export const mutations: MutationTree<State> = {
  [types.SET_REDIRECT](state: State, payload: string): void {
    state.redirect = payload
  },
  [types.SET_AUTO_DONATION_APPLIED](state: State, payload: boolean): void {
    state.autoDonationApplied = payload
  },
  [types.SET_AUTO_DONATION_REFUSED](state: State, payload: boolean): void {
    state.autoDonationRefused = payload
  },
  [types.SET_AUTO_DONATION_BASKET_ID](state: State, payload: number): void {
    state.autoDonationBasketId = payload
  },
  [types.SET_SEAT_MAP_INSTRUCTIONS](state: State, payload: boolean): void {
    state.seatMapInstructionsDismissed = payload
  },
  [types.SET_SHOW_FEEDBACK_BAR](state: State, payload: boolean): void {
    state.showFeedbackBar = payload
  },
  [types.SET_CLASS_INFO_DISMISSED](state: State, payload: boolean): void {
    state.classInfoDismissed = payload
  },
}
