export default async function({ store, redirect }) {
  if (store.getters['customer/isLoggedIn']) {
    let customer = false
    try {
      customer = await store.dispatch('customer/getActiveCustomer')
    } catch (err_) {
      customer = false
    }

    // If the user is authenticated
    if (customer) {
      return redirect('/account')
    }
  }
}
