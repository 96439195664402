import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component()
export default class Field extends Vue {
  /** Text field for the label */
  @Prop({ type: String, required: false }) label!: string
  /** Required, use v-model to pass through */
  @Prop({ type: String | Number }) value!: string | number
  /** Required, must be unique within form */
  @Prop(String) id!: string
  @Prop(String) vid?: string
  /** Placeholder text */
  @Prop(String) placeholder!: string
  /** Validation rules, see https://logaretm.github.io/vee-validate/guide/rules.html */

  @Prop({ type: String | Object }) rules?: any

  /** Validation mode, see https://logaretm.github.io/vee-validate/guide/interaction-and-ux.html#interaction-modes */
  @Prop({ type: String, default: 'eager' }) mode?: string
  /** text, email.. etc */
  @Prop({ type: String, default: 'text' }) fieldType!: string
  /** Flag to set as required, will merge with rules */
  @Prop({ type: String | Boolean, default: 'false' }) required?:
    | string
    | boolean
  /** Optional wrapper class for the containing div */
  @Prop({ type: String, default: '' }) wrapperClass?: string
  /** Regex pattern, will merge with rules */
  @Prop({ default: false }) pattern?: string | boolean
  /** Maxlength prop to add directly to input */
  @Prop({ default: null }) maxlength?: number
  @Prop({ default: false }) disabled?: string | boolean
  @Prop() min?: string | number
  @Prop() max?: string | boolean
  @Prop({ type: Boolean, default: true }) visible?: boolean

  get requiredBoolean() {
    return this.required == '' ||
      this.required == 'false' ||
      this.required === false
      ? false
      : true
  }

  get disabledBoolean() {
    return this.disabled == '' ||
      this.disabled == 'false' ||
      this.disabled === false
      ? false
      : true
  }

  get compiledRules() {
    if (this.rules !== undefined && this.rules != '' && this.requiredBoolean) {
      return `${this.rules}|required`
    } else if (this.rules == '' && this.requiredBoolean) {
      return `required`
    } else {
      return this.rules
    }
  }

  get localValue() {
    return this.value
  }

  set localValue(localValue) {
    this.$emit('input', localValue)
  }

  onInput(event) {
    this.$emit('input', event.target.value)
  }
}
