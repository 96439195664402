









































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import Multiselect from 'vue-multiselect'
import ArrowDownSVG from '~/static/images/sprites/arrow-down.svg'

@Component({
  components: {
    Multiselect,
    ArrowDownSVG,
  },
})
/**
 * This is a wrapper around the vue-multiselect component
 * @version 1.0.0
 * @author [After Digital](https://afterdigital.co.uk)
 * @since Version 1.0.0
 */
export default class AutoSuggestField extends Vue {
  @Prop({ type: Array }) options?: Array<any>
  @Prop({ type: String }) label?: string
  @Prop({ type: String }) fieldLabel?: string
  @Prop({ type: String }) id?: string
  @Prop() preset?: any
  @Prop(String) trackBy?: string
  @Prop(String) placeholder?: string
  @Prop(String) rules?: string

  optionLabel(option) {
    return option[this.label]
  }

  onSelect(value) {
    this.$emit('input', value)
    this.$emit('auto-suggest:clicked', value)
  }
}
