// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".text--widen{letter-spacing:.15px}.radio-wrapper{justify-content:center;margin:-.5rem;display:flex;flex-direction:column}.radio-wrapper>*{margin-left:.5rem;margin-right:.5rem;margin-top:.5rem;margin-bottom:.5rem}@media(min-width:64em){.radio-wrapper{flex-direction:row;flex-wrap:wrap}}.radio-wrapper--column{margin-left:-.5rem;margin-right:-.5rem;flex-direction:column}.radio-wrapper--column>*{margin-left:.5rem;margin-right:.5rem}.radio-wrapper .radio--wrapped{flex-wrap:wrap}.radio-wrapper .radio--wrapped input[type=radio]+label{display:flex;justify-content:flex-start}.radio-wrapper .radio--wrapped svg{fill:#017d85}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"price1": "#d3048e",
	"price2": "#936d22",
	"price3": "#617e26",
	"price4": "#196e19",
	"price5": "#056868",
	"price6": "#5252b6",
	"price7": "#8d0dcd",
	"price8": "#bc2e2d"
};
module.exports = ___CSS_LOADER_EXPORT___;
