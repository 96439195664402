






























import { Component, Vue, namespace } from 'nuxt-property-decorator'
import ChevronUpCircleSVG from '@icons/material/svg/chevron-up-circle.svg'
import BullhornSVG from '@icons/material/svg/bullhorn-outline.svg'

const session = namespace('session')

@Component({
  components: {
    ChevronUpCircleSVG,
    BullhornSVG,
  },
})
export default class FeedbackBar extends Vue {
  @session.Getter
  showFeedbackBar

  @session.Action
  setShowFeedbackBar

  public message = null

  getMessage() {
    this.message = this.$cm('alert', 'alert')
  }

  dismiss(): void {
    this.setShowFeedbackBar(false)
  }

  show(): void {
    this.setShowFeedbackBar(true)
  }

  beforeEnter() {
    if (this.$refs.wrapper) {
      this.$refs.wrapper.style.height = 0
    }
  }

  enter() {
    if (this.$refs.wrapper) {
      this.$refs.wrapper.style.height = this.$refs.wrapper.scrollHeight + 'px'
    }
  }

  beforeLeave(el) {
    if (this.$refs.wrapper) {
      el.style.height = this.$refs.wrapper.scrollHeight + 'px'
    }
  }

  leave(el) {
    if (this.$refs.wrapper) {
      el.style.height = 0
    }
  }

  async created() {
    // console.log(this.$store)
    await this.$store.dispatch('custom_messages/getCustomMessages')
    this.getMessage()
  }
}
