export const childFormSettings = {
  associations: {
    authorized_pickup: 70,
    emergency_contact: 71,
  },
  research: {
    medical: 6,
    allergies: 7,
  },
  attributes: {
    read_privacy_statement: 705,
    agree_privacy_statement: 706,
    read_photo_release: 707,
    accept_photo_release: 708,
    read_waiver: 709,
    has_had_opportunity_to_discuss: 710,
    give_up_legal_rights: 711,
    accept_waiver_and_release: 712,
  },
  issues: {
    medical: {
      category: 38,
      activity: 253,
      contact_type: 7,
      actions: {
        requirements: [46, 47, 48, 49],
        medical_items: [43, 44, 45],
      },
    },
    allergies: {
      category: 37,
      activity: 252,
      contact_type: 7,
      actions: {
        peanut: [18, 19, 20],
        tree_nut: [21, 22, 23],
        eggs: [24, 25, 26],
        milk: [27, 28, 29],
        wheat: [30, 31, 32],
        shellfish: [33, 34, 35],
        sting: [36, 37, 38],
        latex: [39, 40, 41],
        epipen: 42,
      },
    },
    special_needs: {
      category: 39,
      activity: 254,
      contact_type: 7,
      actions: {
        requirements: [50, 51, 52, 53, 54, 55, 56, 57, 58],
      },
    },
  },
}

export const childForm = [
  {
    name: 'authorized_pickups',
    type: 'group',
    fields: [
      {
        type: 'paragraph',
        label:
          'Only authorized people may collect children from Zoo Class. By indicating a person on this form, you confirm that the designated driver possesses a non-probationary driver&sbquo;s license and is not otherwise restricted from driving minors home. Please list at least two contacts and assign at least two emergency contacts. Emergency contacts must be able to make medical and behavioral decisions on behalf of your child.',
        subtype: 'p',
      },
      {
        min: 3,
        name: 'authorized_pickups',
        type: 'repeater',
        require: [1, 2],
        subfields: [
          {
            name: 'name',
            type: 'text',
            label: 'First and Last Name',
            width: '33',
            required: true,
            subtype: 'text',
          },
          {
            name: 'relationship',
            type: 'text',
            label: 'Relationship to Child',
            width: '33',
            required: true,
            subtype: 'text',
          },
          {
            name: 'phone',
            type: 'text',
            label: 'Phone Number',
            width: '33',
            required: true,
            subtype: 'tel',
          },
          {
            name: 'emergency_contact',
            type: 'checkbox',
            value: true,
            label: 'Emergency Contact?',
            required: true,
            width: '100',
          },
          {
            name: 'association_ref',
            type: 'hidden',
          },
        ],
      },
    ],
  },
  {
    name: 'allergies',
    type: 'group',
    fields: [
      {
        type: 'paragraph',
        label: 'Does your child have any allergies?',
        subtype: 'p',
      },
      {
        type: 'radio',
        name: 'has_allergies',
        choices: [
          { label: 'No', value: false },
          { label: 'Yes', value: true },
        ],
        required: true,
        value: null,
      },
      {
        name: 'allergies',
        type: 'checkbox',
        label:
          'Please indicate if your child has any of the following allergies.',
        conditional: {
          has_allergies: true,
        },
        choices: [
          {
            label: 'Peanuts',
            value: 'peanut',
          },
          {
            label: 'Tree Nuts',
            value: 'tree_nut',
          },
          {
            label: 'Eggs',
            value: 'egg',
          },
          {
            label: 'Milk/Lactose',
            value: 'milk',
          },
          {
            label: 'Wheat/Celiac Disease',
            value: 'wheat',
          },
          {
            label: 'Fish/Shellfish',
            value: 'shellfish',
          },
          {
            label: 'Bee, Wasp or Hornet',
            value: 'sting',
          },
          {
            label: 'Latex',
            value: 'latex',
          },
          {
            label: 'Other',
            value: 'other',
          },
        ],
        required: true,
      },
      {
        name: 'peanut_severity',
        type: 'select',
        label: 'How severe is the peanut allergy?',
        choices: [
          { label: 'Can have traces', value: 18 },
          { label: 'Cannot have traces', value: 19 },
          { label: 'Life-threatening reaction', value: 20 },
        ],
        conditional: {
          has_allergies: true,
          allergies: 'peanut',
        },
        required: true,
      },
      {
        name: 'tree_nut_severity',
        type: 'select',
        label: 'How severe is the tree nut allery?',
        choices: [
          { label: 'Can have traces', value: 21 },
          { label: 'Cannot have traces', value: 22 },
          { label: 'Life-threatening reaction', value: 23 },
        ],
        conditional: {
          has_allergies: true,
          allergies: 'tree_nut',
        },
        required: true,
      },
      {
        name: 'egg_severity',
        type: 'select',
        label: 'How severe is the egg allergy?',
        choices: [
          { label: 'Can have traces', value: 24 },
          { label: 'Cannot have traces', value: 25 },
          { label: 'Life-threatening reaction', value: 26 },
        ],
        conditional: {
          has_allergies: true,
          allergies: 'egg',
        },
        required: true,
      },
      {
        name: 'milk_severity',
        type: 'select',
        label: 'How severe is the lactose allergy?',
        choices: [
          { label: 'Can have traces', value: 27 },
          { label: 'Cannot have traces', value: 28 },
          { label: 'Life-threatening reaction', value: 29 },
        ],
        conditional: {
          has_allergies: true,
          allergies: 'milk',
        },
        required: true,
      },
      {
        name: 'wheat_severity',
        type: 'select',
        label: 'How severe is the wheat allergy?',
        choices: [
          { label: 'Can have traces', value: 30 },
          { label: 'Cannot have traces', value: 31 },
          { label: 'Life-threatening reaction', value: 32 },
        ],
        conditional: {
          has_allergies: true,
          allergies: 'wheat',
        },
        required: true,
      },

      {
        name: 'shellfish_severity',
        type: 'select',
        label: 'How severe is the shellfish allergy?',
        choices: [
          { label: 'Can have traces', value: 33 },
          { label: 'Cannot have traces', value: 34 },
          { label: 'Life-threatening reaction', value: 35 },
        ],
        conditional: {
          has_allergies: true,
          allergies: 'shellfish',
        },
        required: true,
      },
      {
        name: 'sting_severity',
        type: 'select',
        label: 'How severe is the sting allergy?',
        choices: [
          { label: 'Can have traces', value: 36 },
          { label: 'Cannot have traces', value: 37 },
          { label: 'Life-threatening reaction', value: 38 },
        ],
        conditional: {
          has_allergies: true,
          allergies: 'sting',
        },
        required: true,
      },
      {
        name: 'latex_severity',
        type: 'select',
        label: 'How severe is the latex allergy?',
        choices: [
          { label: 'Can have traces', value: 39 },
          { label: 'Cannot have traces', value: 40 },
          { label: 'Life-threatening reaction', value: 41 },
        ],
        conditional: {
          has_allergies: true,
          allergies: 'latex',
        },
        required: true,
      },
      {
        type: 'paragraph',
        label:
          'If you have answered yes to any of the above then we require that you complete the following two fields.',
        conditional: {
          has_allergies: true,
        },
        subtype: 'p',
      },
      {
        type: 'paragraph',
        label: 'Does your child carry an EpiPen?',
        subtype: 'p',
        conditional: {
          has_allergies: true,
        },
      },
      {
        type: 'radio',
        name: 'epipen',
        choices: [
          { label: 'No', value: false },
          { label: 'Yes', value: 42 },
        ],
        conditional: {
          has_allergies: true,
        },
        value: false,
        required: true,
      },
      {
        name: 'details',
        type: 'textarea',
        label:
          'If yes to any of the above, please give additional details about the specific allergy/allergies (e.g. gets hives, lips swell, severe asphyxiation, etc.).',
        subtype: 'textarea',
        required: true,
        conditional: {
          has_allergies: true,
        },
      },
    ],
  },
  {
    name: 'medical',
    type: 'group',
    fields: [
      {
        type: 'paragraph',
        label: 'Does your child have any medical requirements?',
        subtype: 'p',
      },
      {
        type: 'radio',
        name: 'has_medical_requirements',
        choices: [
          { label: 'No', value: false },
          { label: 'Yes', value: true },
        ],
        required: true,
        value: null,
      },
      {
        type: 'paragraph',
        label: 'Please indicate if your child has any of the following needs.',
        subtype: 'p',
        conditional: {
          has_medical_requirements: true,
        },
      },
      {
        name: 'requirements',
        type: 'checkbox',
        label: 'Please indicate if your child has any of the following needs.',
        choices: [
          {
            label: 'Asthma',
            value: 46,
          },
          {
            label: 'Diabetes',
            value: 47,
          },
          {
            label: 'Epilepsy or Seizures',
            value: 48,
          },
          {
            label: 'Other',
            value: 49,
          },
        ],
        required: true,
        conditional: {
          has_medical_requirements: true,
        },
      },
      {
        type: 'paragraph',
        label: 'Will your child be bringing any of the below items to class?',
        subtype: 'p',
        conditional: {
          has_medical_requirements: true,
        },
      },
      {
        name: 'medical_items',
        type: 'checkbox',
        label: 'Will your child be bringing any of the below items to class?',
        choices: [
          {
            label: 'Insulin',
            value: 43,
          },
          {
            label: 'Inhaler',
            value: 44,
          },
          {
            label: 'Oral Medication',
            value: 45,
          },
        ],
        conditional: {
          has_medical_requirements: true,
        },
      },
      {
        name: 'details',
        type: 'textarea',
        label: 'If yes to any of the above, please describe.',
        subtype: 'textarea',
        required: true,
        conditional: {
          has_medical_requirements: true,
        },
      },
    ],
  },
  {
    name: 'special_needs',
    type: 'group',
    fields: [
      {
        type: 'paragraph',
        label: 'Does your child have any disability requirements?',
        subtype: 'p',
      },
      {
        type: 'radio',
        name: 'has_special_needs',
        choices: [
          { label: 'No', value: false },
          { label: 'Yes', value: true },
        ],
        required: true,
        value: null,
      },
      {
        name: 'needs',
        type: 'checkbox',
        label: 'Please indicate if your child has any of the following needs.',
        required: true,
        choices: [
          {
            label: 'ADD/ADHD',
            value: 50,
          },
          {
            label: 'Autism',
            value: 51,
          },
          {
            label: 'Down Syndrome',
            value: 52,
          },
          {
            label: 'Wheelchair/other physical restriction',
            value: 53,
          },
          {
            label: 'Visual or auditory needs',
            value: 54,
          },
          {
            label: 'Behavioral challenges',
            value: 55,
          },
          {
            label: 'Cognitive',
            value: 56,
          },
          {
            label: 'Child Aide',
            value: 57,
          },
          {
            label: 'Other',
            value: 58,
          },
        ],
        conditional: {
          has_special_needs: true,
        },
      },
      {
        name: 'details',
        type: 'textarea',
        label: 'If yes to any of the above, please describe.',
        subtype: 'textarea',
        required: true,
        conditional: {
          has_special_needs: true,
        },
      },
    ],
  },
  {
    name: 'privacy',
    type: 'group',
    fields: [
      {
        type: 'paragraph',
        label:
          "Child and Legal Guardian understand that ZSM has requested and only intends to use the above information relating to medical conditions and allergies for the purpose of better ensuring the safety of child and allowing ZSM's employees and volunteers to better respond to child's needs during his/her participation during ZSM camps or classes.",
        subtype: 'p',
      },
      {
        type: 'paragraph',
        label:
          "The information requested will not be shared with any third party without written permission from child's Legal Guardian, except in the case of an emergency or as otherwise permitted by applicable federal, state, and local law. ",
        subtype: 'p',
      },
      {
        type: 'paragraph',
        label:
          'Child and Legal Guardian acknowledge and agree that the information provided is complete and accurate to the best of their knowledge.',
        subtype: 'p',
      },
      {
        type: 'paragraph',
        label:
          "Child and Legal Guardian further understand and agree that ZSM is not acting as a medical provider of your child. Child or Legal Guardian will seek medical advice, care, or treatment from a medical provider if child or Legal Guardian have questions or concerns regarding child's physical or mental health or well-being.",
        subtype: 'p',
      },
      {
        type: 'paragraph',
        label:
          'Completion of the following two checkboxes is requirement for participation in Zoo Camps and Classes. Checkbox is equal to signature of Legal Guardian.',
        subtype: 'p',
      },
      {
        type: 'paragraph',
        label:
          'Signature is valid for a full calendar year (January 1 – December 31). Once completed for a calendar year, the Privacy Statement checkboxes cannot be edited. Please call Conservation Education with any questions.',
        subtype: 'p',
      },
      {
        type: 'paragraph',
        label: 'Regarding the foregoing privacy statement:',
        subtype: 'p',
      },
      {
        name: 'read_privacy_statement',
        type: 'checkbox',
        editable: false,
        label: 'I have read and understand the Privacy Statement.',
        required: true,
      },
      {
        name: 'agree_privacy_statement',
        type: 'checkbox',
        editable: false,
        label: 'I hereby accept and agree to the Privacy Statement.',
        required: true,
      },
    ],
  },
  {
    name: 'consent',
    type: 'group',
    fields: [
      {
        type: 'paragraph',
        label:
          'Please open and read the below attached Photo and Video Release and Consent form in its entirety before proceeding. Checkbox is equal to signature of Legal Guardian.',
        subtype: 'p',
      },
      {
        type: 'paragraph',
        label:
          'Signature is valid for a full calendar year (January 1 – December 31). Once completed for a calendar year, the Photo and Video Release and Consent checkboxes cannot be edited. Please call Conservation Education with any questions.',
        subtype: 'p',
      },
      {
        type: 'paragraph',
        label:
          'Regarding the following Photo and Video Release and Consent form:',
        subtype: 'p',
      },
      {
        type: 'download',
        label: 'Photo and Video Release and Consent Document',
        file: '/files/zsm_student_legal_guardian_photo_video_release_consent.pdf',
      },
      {
        name: 'read_photo_release',
        type: 'checkbox',
        editable: false,
        label:
          'I have read and understand the Photo and Video Release and Consent.',
        required: false,
        immediate: true,
      },
      {
        name: 'accept_photo_release',
        type: 'checkbox',
        editable: false,
        label:
          'I hereby accept and execute the Photo and Video Release and Consent.',
        required: false,
        immediate: true,
      },
    ],
  },
  {
    name: 'waiver',
    type: 'group',
    fields: [
      {
        type: 'paragraph',
        label:
          'Please open and read the below attached Waiver and Release of Liability Form before proceeding. Completion of the following four checkboxes is a requirement for participation in Zoo Camps and Classes. Checkbox is equal to signature of Legal Guardian.',
        subtype: 'p',
      },
      {
        type: 'paragraph',
        label:
          'Signature is valid for a full calendar year (January 1 – December 31). Once completed for a calendar year, the Waiver and Release of Liability checkboxes cannot be edited. Please call Conservation Education with any questions.',
        subtype: 'p',
      },
      {
        type: 'paragraph',
        label: 'Regarding the following Waiver and Release of Liability Form: ',
        subtype: 'p',
      },
      {
        type: 'download',
        label: 'Waiver and Release of Liability Document',
        file: '/files/zsm_student_legal_guardian_waiver_release_of_liability.pdf',
      },
      {
        name: 'read_waiver',
        type: 'checkbox',
        editable: false,
        label: 'I have read and understood the Waiver and Release of Liability',
        required: true,
      },
      {
        name: 'has_had_opportunity_to_discuss',
        type: 'checkbox',
        editable: false,
        label: 'I have had the opportunity to discuss it with ZSM',
        required: true,
      },
      {
        name: 'give_up_legal_rights',
        type: 'checkbox',
        editable: false,
        label: 'I know it requires me to give up legal rights',
        required: true,
      },
      {
        name: 'accept_waiver_and_release',
        type: 'checkbox',
        editable: false,
        label:
          'I hereby accept and execute the Waiver and Release of Liability',
        required: true,
      },
    ],
  },
]
