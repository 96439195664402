import { render, staticRenderFns } from "./VerticalNavigation.vue?vue&type=template&id=39b06b82&scoped=true&"
import script from "./VerticalNavigation.vue?vue&type=script&lang=ts&"
export * from "./VerticalNavigation.vue?vue&type=script&lang=ts&"
import style0 from "./VerticalNavigation.vue?vue&type=style&index=0&id=39b06b82&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39b06b82",
  null
  
)

export default component.exports