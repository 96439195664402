






























import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ArrowDropdownSVG from '~/static/images/sprites/arrow-dropdown.svg'
import ChevronRightSVG from '~/static/images/sprites/chevron-right.svg'

interface IOption {
  name: string
  label: string
}

/**
 * The button dropdown displays a collapsible list of nav options,
 * at the moment it is only used within the account area on mobile view
 */
@Component({
  components: {
    ArrowDropdownSVG,
    ChevronRightSVG
  }
})
export default class ButtonDropdown extends Vue {
  /** List of options */
  @Prop({ type: Array, required: true }) options!: IOption

  /** Default selected option */
  @Prop({ type: Object, required: true }) selected!: IOption

  public expanded: boolean = false
  selectedTab: Object = this.selected

  onClickTab(tab: IOption): void {
    // this.$eventBus.$emit('my-account:update-tab', tab)
    this.selectedTab = tab
    this.$router.push(`${tab.name}`)
    this.expanded = false
  }

  toggle(): void {
    if (this.expanded) {
      this.expanded = false
    } else {
      this.expanded = true
    }
  }
}
