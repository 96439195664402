





import { Component, Prop, Vue } from 'nuxt-property-decorator'

export interface Images {
  banner?: string
  cta?: string
  large?: string
  medium?: string
  original?: string
  small?: string
}

@Component
export default class CardImage extends Vue {
  @Prop({ type: Object, required: true }) images!: Images
  @Prop({ type: String, required: true }) alt!: string

  private img = null
  private loaded = false

  get imageSrc(): string {
    const uri = this.images[this.imageSize || 'small']
    return uri.includes('http') 
      ? uri
      : `${this.$config.get('ASSET_PATH')}/${uri}`
  }

  get backgroundStyle(): string {
    return `background-image: url('${this.imageSrc}');`
  }

  get imageSize(): string {
    if (this.loaded) {
      if (
        this.img.height &&
        this.img.width &&
        this.img.height >= this.img.width
      ) {
        return 'cta'
      } else {
        return 'small'
      }
    }

    return 'small'
  }

  mounted() {
    try {
      this.img = new Image()
      this.img.src = this.$config.get('ASSET_PATH') + '/' + this.images.original

      this.img.onload = () => {
        this.loaded = true
      }
    } catch (err_) {
      this.loaded = true
    }
  }
}
