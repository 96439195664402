/**
 * Middleware to guard against accessing the cart if class consent forms have not been completed
 */
export default async function({ store, redirect }) {
  await store.restored
  if (store.getters['basket/basketIsEmpty']) {
    await store.dispatch('basket/getBasket', 'network-only')
  }
  const recipientsInBasket = store.getters['basket/childrenBookedOnClasses']
  const validRecipients = store.state.basket.valid_recipients

  const difference = recipientsInBasket.filter(
    (part) => !validRecipients.includes(part)
  )

  const shouldRedirect = difference && difference.length > 0

  if (shouldRedirect) {
    return redirect('/classes/participants/')
  }
}
