























import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ChevronRightSvg from '~/static/images/sprites/chevron-right.svg'
import AlertCircleOutlineIcon from '@icons/material/svg/alert-circle-outline.svg'

interface ITab {
  name: string
  label: string
}

/**
 * The vertical navigation component displays a list of navigation option,
 * at the moment it is only used within the account area on desktop
 */
@Component({
  components: {
    ChevronRightSvg,
    AlertCircleOutlineIcon,
  },
})
export default class VerticalNavigatioon extends Vue {
  /** List of options */
  @Prop({ type: Array, required: true }) items!: Array<ITab>
  @Prop({ type: Array }) expiringMemberships?: string[]

  isExpiring(membershipName): Boolean {
    return !!this.expiringMemberships?.includes(membershipName)
  }
}
