






































import { Component, mixins, Prop, VModel } from 'nuxt-property-decorator'
import Field from './Field.ts'

interface SelectOption {
  label: string
  value: string | number
}

@Component()
export default class SelectField extends mixins(Field) {
  @Prop(Array) options?: SelectOption[]
  @Prop({ type: String }) name!: string
  @Prop() selected?: number | string
  @Prop() disabled?: boolean
  @Prop() placeholder?: string

  @VModel() localValue
}
