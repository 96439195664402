















































import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator'
import DownSVG from '~/static/images/sprites/down.svg'
import UpSVG from '~/static/images/sprites/up.svg'
import LoadingWheel from '~/components/ui/LoadingWheel.vue'

@Component({
  components: {
    DownSVG,
    UpSVG,
    LoadingWheel,
  },
})
export default class Accordion extends Vue {
  @Prop({ type: String }) title?: string
  @Prop({ type: String }) AccordionContent?: string
  @Prop() id!: string
  @Prop() icon?: Array<string>
  @Prop({ default: true }) has_icon?: boolean
  @Prop() trigger_styles?: object
  @Prop() trigger_classes?: string
  @Prop({ type: Boolean, default: false }) defaultOpen?: boolean
  @Prop({ type: Boolean }) isLoading?: boolean

  @Watch('defaultOpen')
  onPropertyChanged(newVal) {
    if (newVal === true) {
      this.$nextTick(() => {
        this.content = this.$refs.content

        if (this.content) {
          this.getContentHeight()

          this.open()
        }
      })
    } else {
      this.$nextTick(() => {
        this.content = this.$refs.content

        if (this.content) {
          this.getContentHeight()

          this.close()
        }
      })
    }
  }

  public isOpen: boolean = false
  protected content: HTMLElement | null = null

  protected maxHeight: number = null

  get height(): number {
    return this.isOpen ? this.maxHeight : 0
  }

  beforeEnter(el) {
    if (this.content) {
      this.$nextTick(() => {
        this.content.style.height = '0'
      })
    } else {
      el.style.height = 0
    }
    el.style.opacity = 0
  }

  enter(el) {
    if (this.content) {
      this.$nextTick(() => {
        this.content.style.height = this.content.scrollHeight + 'px'
      })
    } else {
      el.style.height = el.scrollHeight + 'px'
    }
    el.style.opacity = 1
  }

  afterEnter(el) {
    if (this.content) {
      this.content.style.height = 'unset'
    } else {
      el.style.height = 'unset'
    }
    el.style.opacity = 1
  }

  beforeLeave(el) {
    el.style.height = el.scrollHeight + 'px'
    el.style.opacity = 1

    if (this.content) {
      this.content.style.height = this.content.scrollHeight + 'px'
    }
  }

  leave(el) {
    if (this.content) {
      this.content.style.height = 0
    } else {
      el.style.height = 0
    }
    el.style.opacity = 0
  }

  open(): void {
    this.isOpen = true
  }

  close(): void {
    this.isOpen = false
  }

  onClickToggle(): void {
    if (this.isOpen) {
      this.close()
      this.$emit('closed')
    } else {
      this.open()
      this.$emit('opened')
    }
  }

  getContentHeight() {
    this.maxHeight = 400
  }

  mounted(): void {
    this.content = this.$refs.content

    if (this.content) {
      this.getContentHeight()
    }

    if (this.defaultOpen) {
      this.open()
    }
  }
}
