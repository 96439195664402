/**
 * Add commonly used components to this file to prevent the need to import them
 * all over the place
 */

import Vue from 'vue'
import Error from '~/components/errors/Error'
import UIButton from '~/components/ui/buttons/UIButton'
import Accordion from '~/components/ui/accordions/Accordion'
import Modal from '~/components/ui/modals/Modal'
import ModalConfirmation from '~/components/ui/modals/ModalConfirmation'
import Loader from '~/components/ui/Loader'
import SkipLink from '~/components/ui/SkipLink'
import TextField from '~/components/ui/forms/fields/TextField'
import DateField from '~/components/ui/forms/fields/DateField'
import TextareaField from '~/components/ui/forms/fields/TextareaField'
import SelectField from '~/components/ui/forms/fields/SelectField'
import UIRadioButton from '~/components/ui/forms/fields/UIRadioButton'
import WrappedRadio from '~/components/ui/forms/fields/WrappedRadio'
import WrappedRadioGroup from '~/components/ui/forms/fields/WrappedRadioGroup'
import RadioGroup from '~/components/ui/forms/fields/RadioGroup'
import UICheckbox from '~/components/ui/forms/fields/UICheckbox'
import CheckboxGroup from '~/components/ui/forms/fields/CheckboxGroup'
import LoqateField from '~/components/ui/forms/fields/LoqateField'
import AutoSuggestField from '~/components/ui/forms/fields/AutoSuggestField'
import PhoneFormatField from '~/components/ui/forms/fields/PhoneFormatField'
import PasswordFieldReveal from '~/components/ui/forms/fields/PasswordFieldReveal'
import ReadOnlyEditable from '~/components/ui/forms/fields/ReadOnlyEditable'
import StaticNotification from '~/components/ui/notifications/StaticNotification'
import InfoBox from '~/components/ui/notifications/InfoBox'
import IframeContainer from '~/components/ui/media/IframeContainer'
import UIPanel from '~/components/ui/panels/UIPanel'
import UICard from '~/components/ui/cards/UICard'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/en.json'

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule], // assign message
  })
})

extend('required', {
  message: 'This field is required',
})

extend('confirmed', {
  message: 'The password confirmation does not match',
})

extend('regex', {
  message: 'Please use alphabetic characters only',
})

extend('password', (value) => {
  if (
    value.match(/[0-9]/g) !== null &&
    value.match(/[a-zA-Z]/g) !== null &&
    value.length > 7
  ) {
    return true
  }
  return 'The {_field_} must contain at least 1 number and be at least 8 characters long'
})

extend('zipcode', (value) => {
  const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)

  return isValidZip ? true : 'The field does not look like a valid Zip Code'
})

const components = {
  Error,
  UIButton,
  Loader,
  SkipLink,
  Modal,
  ModalConfirmation,
  TextField,
  DateField,
  TextareaField,
  ValidationProvider,
  ValidationObserver,
  SelectField,
  UIRadioButton,
  WrappedRadio,
  WrappedRadioGroup,
  RadioGroup,
  AutoSuggestField,
  LoqateField,
  PhoneFormatField,
  PasswordFieldReveal,
  ReadOnlyEditable,
  Accordion,
  UICheckbox,
  CheckboxGroup,
  StaticNotification,
  InfoBox,
  IframeContainer,
  UIPanel,
  UICard,
}

Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component)
})
