














































import Vue from 'vue'
import { Component, Prop, namespace } from 'nuxt-property-decorator'
import getCountryISO2 from 'country-iso-3-to-2'
import VuePhoneNumberInput from '~/components/ui/forms/fields/VuePhoneNumberInput'
import ChevronDownSVG from '~/static/images/chevron-down.svg'

const customer = namespace('customer')

/**
 * Wrapper around the VuePhoneNumberInput component
 * see - https://github.com/LouisMazel/vue-phone-number-input
 */
@Component({
  components: {
    VuePhoneNumberInput,
    ChevronDownSVG,
  },
})
export default class PhoneFormatField extends Vue {
  $refs!: {
    provider: InstanceType<typeof ValidationProvider>
  }

  @Prop({
    type: Array,
    default: () => ['GB', 'IE', 'US', 'FR', 'DE', 'ES', 'PT', 'PL', 'CA', 'DK'],
  })
  preferred_countries?: Array<String>

  @Prop() preloadNumber?: string
  @Prop() id!: string
  @Prop({ type: String }) rules?: string
  @Prop({ type: String }) value!: string
  @Prop(String) wrapperClass?: string
  @Prop({ type: Boolean, default: true }) visible?: boolean
  @Prop({ type: String, default: 'USA' }) country?: string

  @customer.State
  countries

  private phone_number: string = ''
  private focusStyle: string = ''

  onInputChange(value): void {
    this.$emit('input', value)
  }

  onUpdateChange(value): void {
    this.$emit('update', value)
  }

  onNumberBlur(): void {
    this.focusStyle = this.isValid() === true ? 'is-valid' : 'invalid'
    if (!this.isValid()) {
      this.$refs.provider.setErrors(['The phone number does not look valid'])
    }
  }

  onNumberFocussed(): void {
    this.focusStyle = 'is-active'
  }

  get errorMessage(): string | boolean {
    if (!this.isValid()) {
      return 'The phone field is required and must be a valid number'
    } else {
      return false
    }
  }

  get localModel(): string | number {
    return this.value
  }

  set localModel(val: string | number) {
    this.$emit('input', val)
  }

  get country_code() {
    if (this.countries.length) {
      const record = this.countries.find((c) => c.name === this.country)
      return record ? getCountryISO2(record.iso_code) : 'US'
    } else {
      return 'US'
    }
  }

  isValid(): boolean {
    if (this.$refs.numberInput) {
      return this.$refs.numberInput.isValid
    } else {
      return null
    }
  }

  mounted(): void {
    if (this.preloadNumber) {
      this.phone_number = this.preloadNumber
    }
  }
}
