


















































import { Component, Vue, namespace, Watch } from 'nuxt-property-decorator'
import Header from '~/components/layout/header/Header'
import AccountHeader from '~/components/account/AccountHeader'
import AlertNotification from '~/components/ui/notifications/AlertNotification'
import VerticalNavigation from '~/components/ui/navigation/VerticalNavigation'
import Footer from '~/components/layout/footer/Footer.vue'
import RotatingInfo from '~/components/ui/notifications/RotatingInfo'
import { addNotificationItem } from '~/components/account/helpers/notifications.ts'
import FeedbackBar from '~/components/ui/feedback-bar/FeedbackBar.vue'

const customer = namespace('customer')

@Component({
  components: {
    Header,
    AccountHeader,
    VerticalNavigation,
    Footer,
    AlertNotification,
    RotatingInfo,
    FeedbackBar,
  },
})
export default class AccountLayout extends Vue {
  private notificationMessages = []

  @customer.Getter
  isLoggedIn: boolean

  @customer.Getter
  membershipsByOrganisation

  @customer.Getter
  hasExpiringMemberships

  @Watch('expiringMemberships')
  onChangeExpiringMemberships(newVal) {
    this.notificationMessages = addNotificationItem(newVal)
  }

  get expiringMemberships() {
    return this.hasExpiringMemberships(this.$moment)
  }

  onClickReview(val, notificationMessages) {
    this.$router.push(notificationMessages[val].redirectUrl)
  }

  onDismissItem(val) {
    this.notificationMessages.pop(this.notificationMessages[val])
  }

  public items = [
    {
      label: 'Account overview',
      name: '/account',
      dev: false,
    },
    {
      label: 'Address book',
      name: '/account/addresses',
      cy: 'contact-details',
      dev: false,
    },
    {
      label: 'Personal details',
      name: '/account/personal',
      dev: false,
    },
    {
      label: 'Zoo Pass',
      name: '/account/membership',
      dev: false,
      membership_name: 'Zoo Pass',
    },
    {
      label: 'Platypus Circle',
      name: '/account/platypus-circle',
      dev: false,
      membership_name: 'Platypus Circle',
    },
    {
      label: 'Animal Sponsorships',
      name: '/account/sponsorships',
      dev: false,
    },
    {
      label: 'Gifted products',
      name: '/account/gifted-products',
      dev: false,
    },
    {
      label: 'Order history',
      name: '/account/orders',
      dev: false,
    },
    {
      label: 'Gift vouchers',
      name: '/account/vouchers',
      dev: false,
    },
    {
      label: 'Change password',
      name: '/account/password',
      dev: false,
    },
  ]

  get isSmall(): boolean {
    return this.$mq == 'sm' || this.$mq == 'md'
  }

  async logout() {
    await this.$store.dispatch('customer/logout')
    this.$router.push(this.$config.get('URLS').sign_in)
  }
}
