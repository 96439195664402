




import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class Error extends Vue {
  @Prop({ type: String | Number, default: 500 }) type: string | number

  public templates = {
    404: `<h1>404</h1>
    <h2><span>Error 404:</span> Page cannot be found</h2>
    <p>Sorry, we can't find the page you’re looking for.</p>
    <p class="secondary-info">
      Why not take a look at our what’s on page?
    </p>
    <a
      href="${this.$config.get('MARKETING_WHATS_ON')}"
      class="button button--primary"
      target="_blank"
      >What's on</a
    >`,
    500: `<h1>Error</h1>
    <h3>We're sorry, there was an error with our ticketing system</h3>
    <p>Please wait a moment and try again</p>
    <p class="secondary-info">
      This has been logged and our technical team are investigating. If you continue to experience difficulties, please reach out to us at 414-258-2333 for assistance.
    </p>
    `,
    basket_empty: `<h1 class="basket-empty__header">Cart Empty</h1>
    <h2>Your cart is currently empty</h2>
    <p class="weight-normal">This may be because the timer has run out on the tickets you selected</p>
    <p class="weight-normal">
    Please try again
    </p>
    <a
      href="${this.$config.get('MARKETING_WHATS_ON')}"
      class="button button--primary"
      target="_blank"
      >View what's on</a
    >`,
    seat_map_failed: `<h1 class="basket-empty__header">Error</h1>
    <h2>We're sorry, the seat map could not be loaded for this performance</h2>
    <p class="weight-normal">We are working on resolving this issue</p>
    <p class="weight-normal">
    Please try selecting the best seats, or contact us if the issue persists
    </p>
    <nuxt-link
      to="/basket"
      class="button button--primary"

      >Select best seats</nuxt-link
    >`,
    off_sale: `<h1 class="basket-empty__header">Event not on sale</h1>
    <h2>This event is not currently on sale</h2>
    <a
      href="${this.$config.get('MARKETING_WHATS_ON')}"
      class="button button--primary"
      >See What's On</a
    >`,
    pre_sale: `<h1 class="basket-empty__header">Event Pre-sale</h1>
    <h2>This event is currently only on sale to members</h2>
    <p>If you are a member, please sign in now</p>
    <nuxt-link
      to="/sign-in"
      class="button button--primary"
      >Sign in</nuxt-link
    >
    <a
      href="${this.$config.get('MARKETING_WHATS_ON')}"
      class="button button--primary"
      >See What's On</a
    >`,
  }

  render(h) {
    const div = document.createElement('div')
    div.setAttribute('class', 'error-component error--not-found')
    div.innerHTML = this.getTemplate(this.type)
    return h('div', [
      h(Vue.compile(div.outerHTML)), // compile and render 'code' string
    ])
  }

  getTemplate(type) {
    return this.templates[type] ? this.templates[type] : this.templates[500]
  }
}
