import Vue from 'vue'
import { ActionTree, MutationTree, ActionContext, GetterTree } from 'vuex'
import { sumBy, flatten, uniq, groupBy, cloneDeep } from 'lodash'
import moment from 'moment'
import { RootState } from './types'
import * as basket from '~/api/queries/basket.gql'
import {
  Basket,
  DeliveryMethod,
  BasketProperties,
  Message,
  AddFixedPackageInput,
  OrderItem,
  OrderGroup,
} from '~/@types/skyway'

export const name = 'basket'

export const namespaced = true

export const types = {
  SET_BASKET: 'SET_BASKET',
  SET_BASKET_EXPIRY: 'SET_BASKET_EXPIRY',
  SET_DELIVERY_METHODS: 'SET_DELIVERY_METHODS',
  SET_BASKET_PROPERTIES: 'SET_BASKET_PROPERTIES',
  SET_PROMO_CODE: 'SET_PROMO_CODE',
  SET_BASKET_MESSAGES: 'SET_BASKET_MESSAGES',
  SET_VALID_RECIPIENT: 'SET_VALID_RECIPIENT',
  ADD_GIFT_VOUCHER: 'ADD_GIFT_VOUCHER',
  REMOVE_GIFT_VOUCHER: 'REMOVE_GIFT_VOUCHER',
  SET_LATEST_ORDER_ID: 'SET_LATEST_ORDER_ID',
  SET_BASKET_FORMS: 'SET_BASKET_FORMS',
}

export interface State {
  basket?: Basket
  expiry?: any
  delivery_methods?: DeliveryMethod[]
  properties?: BasketProperties
  promo_code?: string
  messages?: Message[]
  gift_vouchers?: object
  valid_recipients?: number[]
  latest_order_id?: string
  forms?: Form[]
}

export const state = (): State => ({
  basket: undefined,
  expiry: undefined,
  delivery_methods: undefined,
  properties: undefined,
  promo_code: undefined,
  messages: undefined,
  gift_vouchers: undefined,
  valid_recipients: [],
  latest_order_id: undefined,
  forms: undefined,
})

export const findByValue = (o, val) => {
  if (o === val) return o
  if (o === NaN || o === Infinity || !o) return
  if (Array.isArray(o)) {
    let inArray = false
    for (let i = 0; i < o.length; i++) {
      inArray = findByValue(o[i], val)
      if (inArray) return o[i]
    }
  } else if (typeof o === 'object') {
    if (Object.values(o).includes(val)) return o
    for (const n of Object.values(o)) {
      const found = findByValue(n, val)
      if (found) return n
    }
  }
}

export const getters: GetterTree<State, RootState> = {
  basketIsEmpty: (
    state: State,
    getters: GetterTree<State, RootState>
  ): boolean => {
    return Boolean(
      state.basket === undefined ||
        state.basket === null ||
        (state.basket &&
          state.basket.groups &&
          state.basket.groups.length === 0 &&
          getters.giftMemberships.length === 0 &&
          getters.giftSponsorships.length === 0 &&
          state.basket.payments &&
          state.basket.payments.length === 0)
    )
  },

  basketExpiry: (state: State): number | undefined => {
    if (state.basket && state.basket.expiry) {
      return state.basket.expiry
    } else {
      return undefined
    }
  },

  lastAddedItemRef: (state: State, getters: GetterTree) => {
    const tickets = getters.tickets
    const packages = getters.packages
    const item_refs: any[] = []
    tickets.forEach((t) => item_refs.push(t.item_ref))
    packages.forEach((t) => item_refs.push(t.item_ref))
    item_refs.sort((a, b) => {
      if (a > b) return 1
      if (a < b) return -1
      return 0
    })
    return parseInt(item_refs.pop())
  },

  tickets: (state: State): OrderItem[] => {
    if (state.basket && state.basket.groups && state.basket.groups.length) {
      const tickets: { items: OrderItem }[] = state.basket.groups.reduce(
        (output: any, group) => {
          if (group && group.name === 'Performance') {
            output.push(group)
          }
          return output
        },
        []
      )

      return tickets.length ? tickets[0].items : []
    } else {
      return []
    }
  },

  packages: (state: State) => {
    if (state.basket && state.basket.groups && state.basket.groups.length) {
      const tickets: { items: OrderItem }[] = state.basket.groups.reduce(
        (output, group) => {
          if (group && group.name === 'Package') {
            output.push(group)
          }
          return output
        },
        []
      )

      return tickets.length ? tickets[0].items : []
    } else {
      return []
    }
  },

  giftSponsorships: (state: State) => {
    if (
      state.basket != undefined &&
      state.basket != null &&
      state.basket.payments &&
      state.basket.payments.length
    ) {
      const memberships = state.basket.payments.reduce((output, group) => {
        if (group.type.includes('Animal Sponsor')) {
          output.push(group)
        }
        return output
      }, [])
      return memberships
    } else {
      return []
    }
  },

  giftMemberships: (state: State) => {
    if (
      state.basket != undefined &&
      state.basket != null &&
      state.basket.payments &&
      state.basket.payments.length
    ) {
      const memberships = state.basket.payments.reduce((output, group) => {
        if (group.type.includes('Gift Membership')) {
          output.push(group)
        }
        return output
      }, [])
      return memberships
    } else {
      return []
    }
  },

  memberships: (state: State): OrderItem[] => {
    if (
      state.basket != undefined &&
      state.basket != null &&
      state.basket.groups &&
      state.basket.groups.length
    ) {
      const memberships = state.basket.groups.reduce(
        (output, group): OrderItem[] => {
          if (group && group.name == 'Membership') {
            output.push(group)
          }
          return output
        },
        []
      )

      return memberships && memberships.length ? memberships[0].items : []
    } else {
      return []
    }
  },

  membershipsByFund:
    (state: State, getters: GetterTree<State, RootState>) =>
    (fund: string): OrderItem[] => {
      return getters.memberships.filter((m) => m.extra && m.extra.fund == fund)
    },

  contributions: (state: State) => {
    if (
      state.basket != undefined &&
      state.basket != null &&
      state.basket.groups &&
      state.basket.groups.length
    ) {
      const contributions = state.basket.groups.reduce((output, group) => {
        if (group.name == 'Contribution') {
          output.push(group)
        }
        return output
      }, [])

      return contributions.length
        ? contributions.reduce((output, con) => {
            output.push(...con.items)
            return output
          }, [])
        : []
    } else {
      return []
    }
  },

  onAccountContributions: (state: State) => {
    if (state.basket && state.basket.payments && state.basket.payments.length) {
      const contributions = state.basket.payments.reduce((output, group) => {
        if (
          group.category === 'On Account' &&
          !group.type.includes('Gift Membership') &&
          !group.type.includes('Animal Sponsor')
        ) {
          output.push(group)
        }
        return output
      }, [])

      return contributions
    } else {
      return []
    }
  },

  contributionsByName:
    (state: State, getters: GetterTree<State, RootState>) =>
    (name: string): OrderItem[] => {
      return getters.contributions.filter((c) => c.extra && c.name == name)
    },

  vouchers: (state: State) => {
    if (
      state.basket != undefined &&
      state.basket != null &&
      state.basket.groups.length
    ) {
      const vouchers = state.basket.groups.reduce((output, group) => {
        if (group.name == 'GiftCertificate') {
          output.push(group)
        }
        return output
      }, [])

      return vouchers.length ? vouchers[0].items : []
    } else {
      return []
    }
  },

  promoCode: (state: State): string | undefined => {
    return state.promo_code != undefined ? state.promo_code : ''
  },

  seats: (state: State, getters: GetterTree<State, RootState>) => {
    const seats: Seat[] = []
    getters.tickets.forEach((ticket: OrderItem) => {
      if (ticket.children && ticket.children.length) {
        ticket.children.forEach((child): void => {
          seats.push({
            price: child.unit_price,
            instance_ref: ticket.extra.instance_ref,
            item_ref: ticket.item_ref,
            sub_item_ref: child.sub_item_ref,
            seat: {
              row: child.extra.seat_row,
              number: child.extra.seat_number,
              seat_type: child.extra.section,
              seat_ref: child.extra.seat_ref,
              screen_ref: child.extra.section_ref,
            },
          })
        })
      }
    })

    return seats
  },

  classes: (
    state: State,
    getters: GetterTree<State, RootState>
  ): OrderItem[] => {
    return getters.tickets.filter(
      (ticket: OrderItem) =>
        ticket.details &&
        (ticket.details.type == 'education' ||
          ticket.details.type == 'child-care')
    )
  },

  summerClasses: (
    state: State,
    getters: GetterTree<State, RootState>
  ): any[] => {
    const summerClasses: OrderItem[] = []

    if (
      state.basket != undefined &&
      state.basket != null &&
      state.basket.groups
    ) {
      state.basket.groups.filter((group) => {
        if (group && group.items && group.items.length > 0) {
          group.items.filter((item) => {
            if (item && item.type === 'package') {
              // check if the date of the package is in between June and August
              const first = item.children[0]?.extra
              const last = item.children[item.children.length - 1]?.extra
              const firstMonth = first && moment(first.date).month()
              const lastMonth = last && moment(last.date).month()
              // !!! Months are 0 indexed so January is 0
              // https://momentjs.com/docs/#/get-set/month/
              if (firstMonth >= 5 && lastMonth <= 7) {
                summerClasses.push(item)
              }
            }
            if (item && (item.type === 'event' || item.type === 'education')) {
              const date = item.extra?.date && moment(item.extra.date)
              const month = moment(date).month()
              if (month >= 5 && month <= 7) {
                summerClasses.push(item)
              }
            }
          })
        }
      })
    }
    return summerClasses
  },

  adultClassTickets: (
    state: State,
    getters: GetterTree<State, RootState>,
    rootState,
    rootGetters
  ) => {
    const output: object = {}

    let adults: { ref: number; name: string }[] = flatten(
      getters.classes.map((li) => {
        return li.children.map((sli) => {
          return { ref: sli.extra.recipient_ref || null, name: 'Adult Tickets' }
        })
      })
    )

    // Get a flat array of child refs
    const childRefs = rootGetters['customer/children'].reduce(
      (output, child) => {
        output.push(parseInt(child.customer_ref))
        return output
      },
      []
    )

    // adults will either have a null customer_ref or one not included in the above array
    adults = adults.filter((a) => {
      return Boolean(a != null && !childRefs.includes(parseInt(a.ref)))
    })

    const parseLineItems = (lineItems, ref) => {
      const result: any[] = []

      for (const item of lineItems) {
        if (item.children) {
          for (const child of item.children) {
            if (!childRefs.includes(child.extra.recipient_ref)) {
              child.details = item.details
              child.date = item.extra.date
              result.push(child)
            }
          }
        }
      }

      return result
    }

    const items = cloneDeep(getters.classes)

    for (let i = 0; i < adults.length; i++) {
      if (!adults[adults[i].name]) output[adults[i].name] = []
      output[adults[i].name] = parseLineItems(items, adults[i].ref)
    }

    return output
  },

  classesIndexedByChild: (
    state: State,
    getters: GetterTree<State, RootState>,
    rootState,
    rootGetters
  ) => {
    const output: object = {}

    let children: { ref: number; name: string }[] = flatten(
      getters.classes.map((li) => {
        return li.children.map((sli) =>
          sli.extra.recipient_ref
            ? {
                ref: parseInt(sli.extra.recipient_ref),
                name: sli.extra.recipient_name,
              }
            : null
        )
      })
    )

    // Filter any adults from this getter
    children = children.filter(
      (c) =>
        c != null &&
        rootGetters['customer/children'].some(
          (ch) => c.ref === parseInt(ch.customer_ref)
        )
    )

    for (let i = 0; i < children.length; i++) {
      if (!output[children[i].name]) output[children[i].name] = []

      output[children[i].name].push(
        ...getters.classes.filter((li) => {
          return li.children.find((sli) => {
            return parseInt(sli.extra.recipient_ref) === children[i].ref
          })
        })
      )

      output[children[i].name] = output[children[i].name].filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.item_ref === item.item_ref)
      )
    }

    return output
  },

  childrenBookedOnClasses: (
    state: State,
    getters: GetterTree<State, RootState>,
    rootState,
    rootGetters
  ): number[] => {
    const children: number[] = flatten(
      getters.classes.map((li) => {
        return li.children.map((sli) =>
          sli.extra && sli.extra.recipient_ref
            ? parseInt(sli.extra.recipient_ref)
            : null
        )
      })
    )

    return children
      ? children.filter(
          (c) =>
            c != null &&
            rootGetters['customer/children'].some(
              (ch) => c === parseInt(ch.customer_ref)
            )
        )
      : []
  },

  childrenBookedOnPackages: (
    state: State,
    getters: GetterTree<State, RootState>,
    rootState,
    rootGetters
  ): number[] => {
    const children: number[] = flatten(
      getters.packages.map((pkg) => {
        return flatten(
          pkg.children.map((li) =>
            li.extra.seats.map((sli) =>
              sli && sli.recipient_ref ? parseInt(sli.recipient_ref) : null
            )
          )
        )
      })
    )

    return children
      ? children.filter(
          (c, index, self) =>
            c != null &&
            rootGetters['customer/children'].some(
              (ch) => c === parseInt(ch.customer_ref)
            ) &&
            self.indexOf(c) === index
        )
      : []
  },

  packagesIndexedByChild: (
    state: State,
    getters: GetterTree<State, RootState>,
    rootState,
    rootGetters
  ) => {
    const output: object = {}
    const children: any = rootGetters['customer/children'].map((child) => {
      return {
        ...child,
        name: child.first_name + ' ' + child.last_name,
      }
    })

    for (let i = 0; i < children.length; i++) {
      if (!output[children[i].name]) output[children[i].name] = []

      for (const pkg of getters.packages) {
        if (findByValue(pkg, parseInt(children[i].customer_ref))) {
          output[children[i].name].push(pkg)
        }
      }

      output[children[i].name] = output[children[i].name].filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.item_ref === item.item_ref) &&
          self.length > 0
      )
    }

    return output
  },

  classesGroupedByDate: (
    state: State,
    getters: GetterTree<State, RootState>
  ) => {
    const classes =
      getters.classes && getters.classes.length
        ? cloneDeep(getters.classes)
        : []

    const data = classes.sort(
      (a, b) =>
        a.extra.date.substring(0, a.extra.date.indexOf('T')) -
        b.extra.date.substring(0, b.extra.date.indexOf('T'))
    )

    return groupBy(
      data.map((item: OrderItem) => {
        return {
          ...item,
          price: item.unit_price,
          date: item.extra.date.substring(0, item.extra.date.indexOf('T')),
        }
      }),
      'date'
    )
  },

  basket_forms: (state: State, getters) => {
    if (state.forms) {
      // get the instance ref associated with the relevent ticket
      state.forms.forEach((f) => {
        if (getters.tickets) {
          const ticket = getters.tickets.find(
            (t) => parseInt(t.extra.event_ref) === parseInt(f.event_ref)
          )
          if (ticket) {
            f.instance_ref = `${ticket.extra.instance_ref}`

            if (
              getters.ticketsByInstance &&
              getters.ticketsByInstance[f.instance_ref]
            ) {
              f.tickets = Object.values(
                getters.ticketsByInstance[f.instance_ref]
              ).map((t) => {
                return {
                  price_type_ref: t[0].extra.price_type_ref,
                  price_type: t[0].extra.price_type,
                  zone_ref: t[0].extra.zone_ref,
                  zone: t[0].extra.zone,
                  quantity: t.length,
                }
              })
            }
          }
        }
      })

      return state.forms
    } else {
      return null
    }
  },
}

export const actions: ActionTree<State, RootState> = {
  async getBasket(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted

    this.app.$eventBus.emit('basket:loading')

    const response = await client.query({
      query: basket.getBasket,
      fetchPolicy,
    })

    const { data } = response

    if (data && data.getBasket) {
      context.commit(types.SET_BASKET, data.getBasket)
    }

    if (data.getBasket != null) {
      this.app.$eventBus.emit('update_cookie', {
        basket_count: sumBy(data.getBasket.groups, (group) =>
          sumBy(group.items, (item) => item.children.length)
        ),
        basket_total: data.getBasket.total,
      })
    } else {
      this.app.$eventBus.emit('update_cookie', {
        basket_count: 0,
      })
    }

    this.app.$eventBus.emit('basket:finished-loading')

    return data.getBasket
  },

  async getBasketProperties(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.query({
      query: basket.getBasketProperties,
      fetchPolicy,
    })

    const { data } = response

    context.commit(types.SET_BASKET_PROPERTIES, data.getBasketProperties)
    return data.getBasketProperties
  },

  async getMessages(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.query({
      query: basket.getMessages,
      fetchPolicy,
    })

    const { data } = response

    context.commit(types.SET_BASKET_MESSAGES, data.getMessages)
    return data.getMessages
  },

  async getBasketExpiry(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted

    const response = await client.query({
      query: basket.getBasketExpiry,
      fetchPolicy,
    })

    const { data } = response

    if (data.getBasketExpiry != null) {
      this.app.$eventBus.$emit('update_cookie', {
        basket_expiry: data.getBasketExpiry,
      })
    }

    context.commit(types.SET_BASKET_EXPIRY, data.getBasketExpiry)
  },

  async getBasketComplete(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.query({
      query: basket.getBasketComplete,
      fetchPolicy,
    })

    const { data } = response

    return data.getBasketComplete
  },

  async addFixedPackages(
    context: ActionContext<State, RootState>,
    packages: any
  ): Promise<any> {
    let result = false
    for (const i in packages) {
      const pkg = packages[i]
      const input: AddFixedPackageInput = pkg
      result = await this.dispatch('basket/addFixedPackage', input)
    }
    return result
  },

  async addFixedPackage(
    context: ActionContext<State, RootState>,
    addFixedPackageInput: AddFixedPackageInput
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.addFixedPackage,
      variables: {
        addFixedPackageInput,
      },
    })

    const { data } = response

    this.app.$eventBus.emit('basket:item-added')

    return data.addFixedPackage
  },

  async addTickets(
    context: ActionContext<State, RootState>,
    tickets: any
  ): Promise<any> {
    let result = false
    for (const i in tickets) {
      const t = tickets[i]
      if (!t.extra) t.extra = {}
      const formatted = {
        instance_ref: t.instance_ref,
        qty: t.qty,
        extra: Object.assign(t.extra, {
          price_type_ref: t.price_type_ref,
          zone_ref: t.zone_ref,
          special_requests:
            t.special_requests !== undefined ? t.special_requests : '',
        }),
      }
      result = await this.dispatch('basket/addTicket', formatted)
    }
    return result
  },

  async addTicket(
    context: ActionContext<State, RootState>,
    { instance_ref, qty, seats, extra }
  ): Promise<any> {
    const client = this.app.$apollo
    try {
      const response = await client.mutate({
        mutation: basket.addTicket,
        variables: {
          instance_ref,
          seats,
          qty,
          extra,
        },
      })

      const { data } = response

      this.app.$eventBus.emit('basket:item-added')

      return data.addTicket
    } catch (err_) {
      return false
    }
  },

  async removeTickets(
    context: ActionContext<State, RootState>,
    { instance_ref, li_id }
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.removeTickets,
      variables: {
        li_id,
        instance_ref,
      },
    })

    const { data } = response

    return data.removeTickets
  },

  async removeTicket(
    context: ActionContext<State, RootState>,
    { li_id, sli_id }
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.removeTicket,
      variables: {
        li_id,
        sli_id,
      },
    })

    const { data } = response

    return data.removeTicket
  },

  async removeTicketsByInstance(
    context: ActionContext<State, RootState>,
    instance_ref
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.removeTicketsByInstance,
      variables: {
        instance_ref,
      },
    })

    if (response) {
      const { data } = response

      return data.removeTicketsByInstance
    } else {
      return false
    }
  },

  async removePackage(
    context: ActionContext<State, RootState>,
    { pkg_id, li_id }
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.removeFixedPackage,
      variables: {
        pkg_id,
        li_id,
      },
    })

    const { data } = response

    return data.removeFixedPackage
  },

  async removeContribution(
    context: ActionContext<State, RootState>,
    id
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.removeContribution,
      variables: {
        id: parseInt(id),
      },
    })

    const { data } = response

    this.app.$eventBus.emit('contribution:removed', id)

    return data.removeContribution
  },

  async removeMembership(
    context: ActionContext<State, RootState>,
    id
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.removeContribution,
      variables: {
        id: parseInt(id),
      },
    })

    const { data } = response

    // make sure user has default mode of sale when membership is removed from cart
    this.app.$eventBus.emit('revert_mode_of_sale')
    this.app.$eventBus.emit('membership-offer:removed')
    return data.removeContribution
  },

  async removeOnAccountPayment(
    context: ActionContext<State, RootState>,
    id
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.removeOnAccountPayment,
      variables: {
        payment_id: parseInt(id),
      },
    })

    const { data } = response

    return data.removeOnAccountPayment
  },

  async setSource(
    context: ActionContext<State, RootState>,
    id: number
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.mutate({
      mutation: basket.setSource,
      variables: {
        id,
      },
    })

    return response
  },

  async setModeOfSale(
    context: ActionContext<State, RootState>,
    id: number
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.mutate({
      mutation: basket.setModeOfSale,
      variables: {
        id,
      },
    })

    return response
  },

  async revertModeOfSale(
    context: ActionContext<State, RootState>
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.mutate({
      mutation: basket.revertModeOfSale,
    })

    return response
  },

  async setDeliveryMethod(
    context: ActionContext<State, RootState>,
    id: number
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.mutate({
      mutation: basket.setDeliveryMethod,
      variables: {
        id,
      },
    })

    return response
  },

  async setBillingAddress(
    context: ActionContext<State, RootState>,
    id: number
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.mutate({
      mutation: basket.setBillingAddress,
      variables: {
        id,
      },
    })

    return response
  },

  async setDeliveryAddress(
    context: ActionContext<State, RootState>,
    id: number
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.mutate({
      mutation: basket.setDeliveryAddress,
      variables: {
        id,
      },
    })

    return response
  },

  async getDeliveryMethods(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<DeliveryMethod[]> {
    const client = this.app.$apollo
    const response = await client.query({
      query: basket.getDeliveryMethods,
      fetchPolicy,
    })

    const { data } = response

    context.commit(types.SET_DELIVERY_METHODS, data.getAvailableDeliveryMethods)

    return data.getAvailableDeliveryMethods
  },

  async applyPromoCode(
    context: ActionContext<State, RootState>,
    code: string
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.mutate({
      mutation: basket.applyPromoCode,
      variables: {
        code,
      },
    })

    const { data } = response

    if (data && data.applyPromoCode) {
      context.commit(types.SET_PROMO_CODE, code)
    }

    return data.applyPromoCode
  },

  async resetPromoCode(context: ActionContext<State, RootState>): Promise<any> {
    const client = this.app.$apollo

    const response = await client.mutate({
      mutation: basket.resetPromoCode,
    })

    context.commit(types.SET_PROMO_CODE, '')

    return response
  },

  async getThirdPartyContactPermissions(
    context: ActionContext<State, RootState>
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: basket.getThirdPartyContactPermissions,
    })

    const { data } = response

    return data.getThirdPartyContactPermissions
  },

  async addGiftCertificate(
    context: ActionContext<State, RootState>,
    addGiftCertificateInput
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.addGiftCertificate,
      variables: {
        addGiftCertificateInput: addGiftCertificateInput.input,
      },
    })

    const { data } = response

    if (data.addGiftCertificate !== false) {
      context.commit(types.ADD_GIFT_VOUCHER, {
        id: data.addGiftCertificate,
        details: addGiftCertificateInput,
      })
    }

    return data.addGiftCertificate
  },

  async removeGiftCertificate(
    context: ActionContext<State, RootState>,
    gc_no
  ): Promise<any> {
    // remove the voucher from the store so we don't send any email on order completion
    const vouchers = this.getters['basket/vouchers']
    const id = vouchers.find((v) => v.extra.certificate_reference == gc_no)
    if (id) {
      context.commit(types.REMOVE_GIFT_VOUCHER, id.item_ref)
    }

    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.removeGiftCertificate,
      variables: {
        gc_no,
      },
    })

    const { data } = response

    return data.removeGiftCertificate
  },

  async processGiftCertificateConfirmations(
    context: ActionContext<State, RootState>,
    order
  ): Promise<any> {
    if (context.state.gift_vouchers != undefined) {
      const self = this

      const vouchers = order.groups.find(
        (group) => group.name == 'GiftCertificate'
      )

      const results = Object.keys(context.state.gift_vouchers).map(
        async (id) => {
          const details = context.state.gift_vouchers[id]
          const recipient = details.recipient
          const voucher = vouchers.items.find((v) => v.item_ref == id)

          if (recipient && voucher) {
            const client = this.app.$apollo
            const response = await client.mutate({
              mutation: basket.sendCustomerEmail,
              variables: {
                email: recipient,
                subject: `${context.$config.get('CLIENT_NAME')} Gift Voucher`,
                body: `
                <p>Dear ${details.input.name},</p>
                <p>Great news! ${
                  details.from
                } has sent you a £${details.input.amount.toFixed(
                  2
                )} gift voucher for ${context.$config.get('CLIENT_NAME')}.</p>
                <p>You can exchange your voucher for tickets to one of our <a href="https://www.zoosociety.org/whats-on/">world-class cultural events</a> or a <a href="https://www.zoosociety.org/support/membership">${context.$config.get(
                  'CLIENT_NAME'
                )} Membership</a> – and it doesn't have an expiry date, so you can take your time choosing.</p>
                <p>Use the voucher over the phone, in person or online by quoting your code: ${
                  voucher.extra.certificate_reference
                }</p>
                <p>Here's a message from ${details.from}:</p>
                <p><em>${details.message}</em></p>
                <p>We look forward to welcoming you to the ${context.$config.get(
                  'CLIENT_NAME'
                )}.</p>
              `,
              },
            })

            context.commit(types.REMOVE_GIFT_VOUCHER, voucher.item_ref)

            const { data } = response

            return data.sendCustomerEmail
          }
        }
      )

      return Promise.all(results).then((res) => true)
    }
  },

  async applyGiftCertificate(
    context: ActionContext<State, RootState>,
    code
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.applyGiftCertificate,
      variables: {
        code,
      },
    })

    const { data } = response

    return data.applyGiftCertificate
  },

  async unapplyGiftCertificate(
    context: ActionContext<State, RootState>,
    gc_no
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.unapplyGiftCertificate,
      variables: {
        gc_no,
      },
    })

    const { data } = response

    return data.unapplyGiftCertificate
  },

  async applyCreditToBasket(
    context: ActionContext<State, RootState>,
    applyCreditToBasketInput
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.applyCreditToBasket,
      variables: {
        applyCreditToBasketInput,
      },
    })

    const { data } = response

    return data.applyCreditToBasket
  },

  async removeCreditFromBasket(
    context: ActionContext<State, RootState>,
    payment_id
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.removeCreditFromBasket,
      variables: {
        payment_id,
      },
    })

    const { data } = response

    return data.removeCreditFromBasket
  },

  async clearBasket(context: ActionContext<State, RootState>): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.clearBasket,
      variables: {},
    })

    const { data } = response

    return data.clearBasket
  },

  async transferBasket(context: ActionContext<State, RootState>): Promise<any> {
    await this.dispatch('customer/transferSession', 'network-only')
    this.app.$eventBus.$emit('basket:transferred-session')
    context.commit(types.SET_BASKET, null)
  },

  async getVerfiedDirectDebitAddresses(
    context: ActionContext<State, RootState>,
    postcode
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: basket.getVerfiedAddresses,
      variables: {
        postcode,
      },
    })

    const { data } = response

    return data.getVerfiedAddresses
  },

  async checkBankAccountDetails(
    context: ActionContext<State, RootState>,
    account_details
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: basket.checkBankAccountDetails,
      variables: {
        account_details,
      },
    })

    const { data } = response

    return data.checkBankAccountDetails
  },

  async verifyBankAccountOwnership(
    context: ActionContext<State, RootState>,
    account_owner
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: basket.verifyBankAccountOwnership,
      variables: {
        account_owner,
      },
    })

    const { data } = response

    return data.verifyBankAccountOwnership
  },

  async directDebitCheckout(
    context: ActionContext<State, RootState>,
    membership
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.directDebitCheckout,
      variables: {
        membership,
      },
    })

    const { data } = response

    return data.directDebitCheckout
  },

  async directDebitCreateGiftMembershipPledge(
    context: ActionContext<State, RootState>,
    values
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.directDebitCreateMembershipPledge,
      variables: {
        values,
      },
    })

    const { data } = response

    return data.execute
  },

  async getRelatedProductsByEventRef(
    context: ActionContext<State, RootState>,
    event_ref
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.getRelatedProductsByEventRef,
      variables: {
        event_ref,
      },
    })

    const { data } = response

    return data.getRelatedProductsByEventRef
  },

  async getRelatedProductsByDate(
    context: ActionContext<State, RootState>,
    date
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.getRelatedProductsByDate,
      variables: {
        date,
      },
    })

    const { data } = response

    return data.getRelatedProductsByDate
  },

  async completeFreeCheckout(
    context: ActionContext<State, RootState>
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.completeFreeCheckout,
    })

    const { data } = response

    return data.completeFreeCheckout
  },

  async updateBasketExpiry(
    context: ActionContext<State, RootState>,
    expiryTime
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.updateBasketExpiry,
      variables: {
        expiryTime,
      },
    })

    const { data } = response

    return data.updateBasketExpiry
  },

  async getDynamicShippingMethods(
    context: ActionContext<State, RootState>
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: basket.getDynamicShippingMethods,
    })

    const { data } = response

    return data.custom.response
  },

  async applyLoyaltyPointsToBasket(
    context: ActionContext<State, RootState>
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.applyLoyaltyPointsToBasket,
    })

    const { data } = response

    return data.response
  },

  async removeLoyaltyPointsFromBasket(
    context: ActionContext<State, RootState>
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: basket.removeLoyaltyPointsFromBasket,
    })

    const { data } = response

    return data.response
  },

  async getBasketForms(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted

    const response = await client.query({
      query: basket.getBasketForms,
      fetchPolicy,
    })

    const { data } = response

    context.commit(types.SET_BASKET_FORMS, data.getBasketForms)
    return data.getBasketForms
  },
}

export const mutations: MutationTree<State> = {
  [types.SET_BASKET](state: State, payload: Basket): void {
    state.basket = payload
    if (payload && payload.expiry) {
      state.expiry = payload.expiry
      Vue.set(state.basket, 'expiry', payload.expiry)
    } else {
      state.expiry = null
    }
  },
  [types.SET_BASKET_EXPIRY](state: State, payload: any): void {
    state.expiry = payload
    if (state.basket) {
      Vue.set(state.basket, 'expiry', payload)
    }
  },
  [types.SET_PROMO_CODE](state: State, payload: any): void {
    state.promo_code = payload
  },
  [types.SET_DELIVERY_METHODS](state: State, payload: DeliveryMethod[]): void {
    state.delivery_methods = payload
  },
  [types.SET_BASKET_PROPERTIES](state: State, payload: BasketProperties): void {
    state.properties = payload
  },
  [types.SET_BASKET_MESSAGES](state: State, payload: Message[]): void {
    state.messages = payload
  },
  [types.ADD_GIFT_VOUCHER](state: State, payload: any): void {
    if (state.gift_vouchers === undefined) {
      state.gift_vouchers = {}
    }
    state.gift_vouchers[payload.id] = payload.details
  },
  [types.REMOVE_GIFT_VOUCHER](state: State, payload: any): void {
    if (state.gift_vouchers[payload] !== undefined) {
      delete state.gift_vouchers[payload]
    }
  },
  /**
   * When a consent form is completed, we set a simple array in the store to
   * use as a guard against direct access to the basket
   * this is persisted in a cookie for 30 mins to allow for refresh
   */
  [types.SET_VALID_RECIPIENT](state: State, payload: number | string): void {
    if (state.valid_recipients && !state.valid_recipients.includes(payload)) {
      state.valid_recipients.push(parseInt(payload))
    }
  },

  [types.SET_BASKET_FORMS](state: State, payload: Form[]): void {
    state.forms = payload
  },
}
