
























import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { Customer } from '~/@types/skyway'
import ButtonDropdown from '~/components/ui/navigation/ButtonDropdown'

interface ITab {
  name: string
  label: string
}

@Component({
  components: {
    ButtonDropdown
  }
})
export default class AccountHeader extends Vue {
  /** logged in customer object */
  @Prop({ type: Object }) customer?: Customer
  /** Array of tabs to display on mobile */
  @Prop({ type: Array, required: true }) tabs!: ITab[]
  /** Selected Tab */
  @Prop({ type: Object, required: true }) selected!: ITab

  get isSmall(): boolean {
    return this.$mq == 'sm' || this.$mq == 'md'
  }
}
