



























import { Component, Vue, Prop } from 'nuxt-property-decorator'
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import SearchIconSvg from '~/static/images/sprites/search.svg'
import CustomerAddress from '~/@types/skyway'
import _debounce from 'lodash/debounce'

@Component({
  components: {
    Multiselect
  }
})
export default class LoqateField extends Vue {
  @Prop({ type: String, default: 'Address finder' }) inputLabel?: string
  @Prop({ type: Object }) presetAddress?: CustomerAddress
  @Prop({ type: String, default: '' }) rules?: string

  protected axios_source = null
  protected key: string = this.$config.get('LOQATE_KEY')
  protected timeout = null

  public loading: boolean = false
  public results: Array = []
  public selected = null
  public query: string = null

  public placeholder: string = 'Search by postcode or address'

  public input = null

  public debounceMakeFindRequest = _debounce(this.makeFindRequest, 500)

  /**
   * Responses from the web service
   */
  public responses: {
    find?: any
    retrieve?: any
  } = { find: null, retrieve: null }

  /**
   * See https://www.loqate.com/resources/support/apis/Capture/Interactive/Find/1.1/
   */
  makeFindRequest(container: string): Promise<any> {
    this.doFindRequest(container).then((res) => {
      this.handleFindRequest(res)
    })
  }

  doFindRequest(container: string): Promise<any> {
    // cancel any running requests to prevent any race issues with returning data
    if (this.axios_source != null) {
      this.axios_source.cancel()
    }

    const cancel_token = axios.CancelToken
    this.axios_source = cancel_token.source()

    return axios
      .get('https://api.addressy.com/Capture/Interactive/Find/v1.10/json3.ws', {
        params: { key: this.key, text: this.query, container },
        cancelToken: this.axios_source.token
      })
      .then((res) => {
        this.axios_source = null
        this.loading = false
        return res
      })
      .catch((err) => {
        this.axios_source = null
        this.loading = false
        return false
      })
  }

  /**
   * See https://www.loqate.com/resources/support/apis/Capture/Interactive/Retrieve/1/
   */
  makeRetrieveRequest(Id: string): Promise<any> {
    return axios.get(
      'https://services.postcodeanywhere.co.uk/Capture/Interactive/Retrieve/v1.00/json3.ws',
      {
        params: { key: this.key, Id }
      }
    )
  }

  /**
   * Process the result of a find request
   */
  handleFindRequest(response: Promise<any>): void {
    this.responses['find'] = response.data

    if (!this.hasNoResults('find') && !this.hasError('find')) {
      this.results = this.responses['find'].Items
    }
    this.loading = false
  }

  /**
   * Process the result of a retrieve request and emit to parent
   */
  handleRetrieveRequest(response: Promise<any>): void {
    this.responses['retrieve'] = response.data

    if (!this.hasNoResults('retrieve') && !this.hasError('retrieve')) {
      this.results = this.responses['retrieve'].Items
    }
    this.$emit('loqate:address-selected', this.responses['retrieve'].Items[0])
  }

  /**
   * Handle the input changing and trigger a find request
   */
  onInputChange(query: string): void {
    this.loading = true
    this.query = query
    this.debounceMakeFindRequest()
  }

  /**
   * When the user clicks on a result
   *
   * If the result is an address, we can retrieve the fields and continue
   *
   * If it is something else, i.e BuildingNumber then we need to make
   * a second find request to allow the user to select their exact address
   */
  onResultSelect(selected) {
    if (selected != null && selected.Type === 'Address') {
      this.makeRetrieveRequest(selected.Id).then((response) => {
        this.handleRetrieveRequest(response)
      })
    } else {
      this.doFindRequest(selected.Id)
        .then((response) => {
          this.resetAfter = false
          this.handleFindRequest(response)
        })
        .then(() => {
          this.placeholder = 'Choose a flat/door number'
          this.input.focus()
        })
    }
  }

  optionLabel(option) {
    return option.Text !== undefined
      ? `${option.Text} - ${option.Description}`
      : ''
  }

  /**
   * Convenience helper methods
   */
  hasError(key: string): boolean {
    if (this.responses[key] && this.responses[key].Items) {
      return (
        this.responses[key].Items.length === 1 &&
        this.responses[key].Items[0].Error !== undefined
      )
    } else {
      return false
    }
  }

  hasNoResults(key: string): boolean {
    if (this.responses[key] && this.responses[key].Items) {
      return this.responses[key].Items.length === 0
    } else {
      return false
    }
  }

  get id(): string {
    return `${this._uid}_loqate`
  }

  /**
   * Grab the multiselect input element when the component mounts so we can focus it
   */
  mounted() {
    console.log(this.input)
    this.input = this.$refs.container.querySelector('.multiselect__input')
  }
}
