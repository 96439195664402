export default [
  {
    label: 'About The Society',
    target: 'about-the-zoological-society',
    modifier: 'about',
    megaLinks: [
      {
        label: 'Mission & Impact',
        target: '#',
        icon: 'about/MissionImpact.jpeg',
      },
      {
        label: 'The Society In The News',
        target: '#',
        icon: 'about/News.jpeg',
      },
      {
        label: 'Society Office Hours',
        target: '#',
        icon: 'about/OfficeHours.jpeg',
      },
      {
        label: 'Board & Leadership',
        target: '#',
        icon: 'about/BoardLeadership.png',
      },
      {
        label: 'Annual Report',
        target: '#',
        icon: 'about/AnnualReport.jpeg',
      },
      {
        label: 'Publications & Archive',
        target: '#',
        icon: 'about/Publication.jpeg',
        sublinks: [
          {
            label: 'Alive Magazine',
            target: '#',
          },
          {
            label: 'Wild Things Newsletter',
            target: '#',
          },
          {
            label: 'Publication Archive',
            target: '#',
          },
        ],
      },
      {
        label: 'Careers & Internships',
        target: '#',
        icon: 'about/Careers.jpeg',
      },
      {
        label: 'Request A Donation',
        target: '#',
        icon: 'about/RequestDonation.jpeg',
      },
    ],
  },
  {
    label: 'Zoo Pass & Membership',
    target: 'zoo-pass-membership',
    modifier: 'zoo-pass',
    megaLinks: [
      {
        label: 'Zoo Pass',
        target: '#',
        icon: 'zoo-pass/ZooPass.jpeg',
        sublinks: [
          {
            label: 'Zoo Pass Pricing',
            target: '#',
          },
          {
            label: 'Zoo Pass FAQ',
            target: '#',
          },
        ],
      },
      {
        label: 'Platypus Circle Membership',
        target: '#',
        icon: 'zoo-pass/Platypus.jpeg',
        sublinks: [
          {
            label: 'Platypus Membership FAQ',
            target: '#',
          },
        ],
      },
      {
        label: 'Reciprocal Zoo List',
        target: '#',
        icon: 'zoo-pass/Reciprocal.jpeg',
      },
    ],
  },
  {
    label: 'Conservation',
    target: 'conservation',
    modifier: 'conservation',
    megaLinks: [
      {
        label: 'Conservation Opportunities',
        target: '#',
        icon: 'conservation/Opportunities.jpeg',
      },
      {
        label: 'Zoo Conservation & Research Support',
        target: '#',
        icon: 'conservation/ZooConservation.jpeg',
      },
      {
        label: 'Local & Regional Initiatives',
        target: '#',
        icon: 'conservation/LocalRegional.jpeg',
        sublinks: [
          {
            label: 'Pollinators: Birds, Bats, Bees & Butterflies',
            target: '#',
          },
        ],
      },
      {
        label: 'Global Initiatives',
        target: '#',
        icon: 'conservation/Global.jpeg',
        sublinks: [
          {
            label: 'Bonobo SSP',
            target: '#',
          },
          {
            label: "AZA's Saving Animals From Extinction",
            target: '#',
          },
          {
            label: 'Great Lakes Initiatives',
            target: '#',
          },
        ],
      },
      {
        label: 'Green Initiatives',
        icon: 'conservation/GreenInitiatives.jpeg',
        target: '#',
      },
      {
        label: 'History',
        icon: 'conservation/History.jpeg',
        target: '#',
      },
    ],
  },
  {
    label: 'Education',
    modifier: 'education',
    target: 'education',
    megaLinks: [
      {
        label: 'Zoo Classes & Camps',
        target: '#',
        icon: 'education/ClassesCamps.jpeg',
        sublinks: [
          {
            label: 'FAQ',
            target: '#',
          },
        ],
      },
      {
        label: 'Virtual Programs',
        target: '#',
        icon: 'education/Virtual.jpeg',
        sublinks: [
          {
            label: 'Virtual School Programs',
            target: '#',
          },
        ],
      },
      {
        label: 'School And Community Programs',
        target: '#',
        icon: 'education/SchoolComm.jpeg',
        sublinks: [
          {
            label: 'At The Zoo',
            target: '#',
          },
          {
            label: 'At School',
            target: '#',
          },
          {
            label: 'In The Community',
            target: '#',
          },
        ],
      },
      {
        label: "Kohl's Wild Theater",
        icon: 'education/KWT.jpeg',
        target: '#',
        sublinks: [
          {
            label: 'Request A Performance',
            target: '#',
          },
          {
            label: 'Meet Our Crew',
            target: '#',
          },
        ],
      },
      {
        label: 'Financial Aid',
        target: '#',
        icon: 'education/FinancialAid.jpeg',
      },
    ],
  },
  {
    label: 'Support',
    modifier: 'support',
    target: 'support',
    megaLinks: [
      {
        label: 'Help The Society',
        target: '#',
        icon: 'support/HelpSociety.jpeg',
        sublinks: [
          {
            label: 'Sponsor An Animal',
            target: '#',
          },
          {
            label: 'Platypus Circle Membership',
            target: '#',
          },
          {
            label: 'Zoo Pass & Membership',
            target: '#',
          },
          {
            label: 'Donate Now',
            target: '#',
          },
          {
            label: 'Adventure Africa Campaign',
            target: '#',
          },
          {
            label: 'Grants',
            target: '#',
          },
          {
            label: 'Planned Giving',
            target: '#',
          },
          {
            label: 'Memorials',
            target: '#',
          },
          {
            label: 'Annual Appeal',
            target: '#',
          },
          {
            label: 'Corporate Sponsorship',
            target: '#',
          },
          {
            label: 'Matching Gifts Program',
            target: '#',
          },
          {
            label: 'Auction & Raffle Donations',
            target: '#',
          },
          {
            label: 'Volunteer Opportunities',
            target: '#',
          },
        ],
      },
      {
        label: 'How We Support The Zoo',
        target: '#',
        icon: 'support/HowSupport.jpeg',
      },
      {
        label: 'Limited-Time Animal Sponsorship',
        target: '#',
        icon: 'support/LTO.jpeg',
      },
    ],
  },
  {
    label: 'Things To Do',
    modifier: 'things-to-do',
    target: 'things-to-do',
    megaLinks: [
      {
        label: 'Visit The Zoo',
        target: '#',
        icon: 'things-to-do/VisitZoo.jpeg',
      },
      {
        label: 'Animal Webcams',
        target: '#',
        icon: 'things-to-do/Webcams.jpeg',
      },
      {
        label: 'Explore The Zoo',
        target: '#',
        icon: 'things-to-do/ZooMap.jpeg',
      },
    ],
  },
]
