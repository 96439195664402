// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".text--widen{letter-spacing:.15px}.info-box{background-color:#f2f9f9;padding:.75em;display:flex;margin-bottom:1em;align-items:center;text-align:left}.info-box svg{flex-grow:0;flex-shrink:0;flex-basis:1.5rem;margin-right:.75em;fill:#017d85}.info-box p{margin:0;font-size:.8125rem;line-height:1.2;font-weight:600}.info-box--horizontal{display:flex;flex-direction:column}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"price1": "#d3048e",
	"price2": "#936d22",
	"price3": "#617e26",
	"price4": "#196e19",
	"price5": "#056868",
	"price6": "#5252b6",
	"price7": "#8d0dcd",
	"price8": "#bc2e2d"
};
module.exports = ___CSS_LOADER_EXPORT___;
