import { render, staticRenderFns } from "./AccountHeader.vue?vue&type=template&id=469d9e6f&scoped=true&"
import script from "./AccountHeader.vue?vue&type=script&lang=ts&"
export * from "./AccountHeader.vue?vue&type=script&lang=ts&"
import style0 from "./AccountHeader.vue?vue&type=style&index=0&id=469d9e6f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "469d9e6f",
  null
  
)

export default component.exports