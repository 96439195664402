
































































































import { Component, Vue } from 'nuxt-property-decorator'
import ChevronIcon from '@icons/material/svg/chevron-right.svg'
import TwitterIcon from '@icons/material/svg/twitter.svg'
import InstagramIcon from '@icons/material/svg/instagram.svg'
import FacebookIcon from '@icons/material/svg/facebook.svg'

@Component({
  components: {
    ChevronIcon,
    TwitterIcon,
    InstagramIcon,
    FacebookIcon,
  },
})
export default class Footer extends Vue {
  private baseURL = 'https://www.zoosociety.org/'
}
