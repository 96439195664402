







































































import { Component, Prop, mixins } from 'nuxt-property-decorator'
import _debounce from 'lodash/debounce'
import Field from './Field.ts'

/**
 * This is a date field that uses three inputs for mm/dd/yyyy
 * @version 1.0.0
 * @author [After Digital](https://afterdigital.co.uk)
 * @since Version 1.0.0
 */
@Component
export default class DateField extends mixins(Field) {
  /** Optional additional description for field, added in a span */
  @Prop({ type: String }) descriptiveLabel?: string
  /** @Prop - min date in YYYY-MM-DD format **/
  @Prop({ type: String }) minDate?: string
  /** @Prop - max date in YYYY-MM-DD format **/
  @Prop({ type: String }) maxDate?: string

  private day: string = this.date
    ? `${this.$moment(this.date).date()}`.padStart(2, '0')
    : ``

  private month: string = this.date
    ? `${this.$moment(this.date).month() + 1}`.padStart(2, '0')
    : ``

  private year: string = this.date ? `${this.$moment(this.date).year()}` : ``

  private debouncedValidate: Function = _debounce(this.validateDate, 1000)

  get date() {
    return this.value && this.value.includes('T')
      ? this.value.substring(0, this.value.indexOf('T'))
      : this.value
  }

  updateDay() {
    if (!this.day.length || parseInt(this.day, 10) < 4) {
      return
    }
    this.$refs.year.select()
  }

  updateMonth() {
    if (!this.month.length || parseInt(this.month, 10) < 2) {
      return
    }
    this.$refs.day.select()
  }

  updateValue() {
    if (!isNaN(parseInt(this.year))) {
      const timestamp = this.$moment.utc(
        `${this.year.padStart(4, 0)}-${this.month.padStart(
          2,
          0
        )}-${this.day.padStart(2, 0)}`
      )

      this.debouncedValidate(timestamp)

      this.$emit(`input`, timestamp.format('YYYY-MM-DD'))
    }
  }

  validateDate(timestamp): void {
    const max = this.$moment.utc(this.maxDate)
    const min = this.$moment.utc(this.minDate)

    if (this.$moment(timestamp).isAfter(max)) {
      this.$refs.provider.applyResult({
        errors: ['The date of birth field cannot be in the future'],
        valid: false,
        failedRules: {},
      })

      return
    }

    if (this.$moment(timestamp).isBefore(min)) {
      this.$refs.provider.applyResult({
        errors: [
          'It looks like that date of birth is too old. Children must be under 18',
        ],
        valid: false,
        failedRules: {},
      })

      return
    }

    this.$refs.provider.applyResult({
      errors: [],
      valid: true,
      failedRules: {},
    })
  }
}
