import { ActionTree, MutationTree, ActionContext, GetterTree } from 'vuex'

import { RootState } from './types'
import * as interests from '~/api/queries/interests.gql'
import { InterestType } from '~/@types/skyway'

export const name = 'interests'

export const namespaced = true

export const types = {
  SET_INTEREST_TYPES: 'SET_INTEREST_TYPES'
}

export interface State {
  interest_types?: Array<InterestType>
}

/**
 * Initial state, empty array of events
 */
export const state = (): State => ({
  interest_types: []
})

export const getters: GetterTree<State, RootState> = {
  interestTypesByCategory: (state: State) => (
    category_ref: number | string
  ) => {
    return state.interest_types.filter(
      (type) => parseInt(type.category_ref) === parseInt(category_ref)
    )
  }
}

export const actions: ActionTree<State, RootState> = {
  async getInterestTypes(
    context: ActionContext<State, RootState>
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: interests['getInterestTypes'],
      context: {
        public: true
      }
    })

    const { data } = response

    context.commit(types.SET_INTEREST_TYPES, data.getInterestTypes)
    return data.getInterestTypes
  }
}

export const mutations: MutationTree<State> = {
  [types.SET_INTEREST_TYPES](state: State, payload: Venue[]): void {
    state.interest_types = payload
  }
}
