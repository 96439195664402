// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".text--widen{letter-spacing:.15px}.feedback-bar{z-index:999;position:sticky;top:0}.feedback-bar p{margin:0;font-size:1rem}.feedback-bar a{color:#fff}.feedback-bar__wrapper{background:#017d85;color:#fff;padding:.5rem 1.25rem;display:grid;grid-template-columns:1fr minmax(0,1440px) minmax(30px,1fr);transition:height .2s ease-in-out;overflow:hidden}.feedback-bar__wrapper *{font-size:1rem}.feedback-bar__content{display:flex;flex-direction:column;grid-column:2/span 1;justify-content:center;align-items:center;text-align:center;margin-top:-.5rem;margin-bottom:-.5rem}.feedback-bar__content>*{margin-top:.5rem;margin-bottom:.5rem}@media(min-width:48em){.feedback-bar__content{flex-direction:row;margin:0 -.5rem}.feedback-bar__content>*{margin-top:0;margin-bottom:0;margin-left:.5rem;margin-right:.5rem}}.feedback-bar__link{background:#fff;border-radius:2px;padding:.25rem 1.25rem;text-decoration:none;color:#000;font-weight:400;grid-column:3/span 1;transition:all .2s ease-in-out}.feedback-bar__link:hover{background:#e37705;color:#fff}.feedback-bar__dismiss{display:flex;justify-content:flex-end;align-items:center}.feedback-bar__dismiss svg{fill:#e37705;background:#fff;border-radius:50%;cursor:pointer;transition:all .2s ease-in-out}.feedback-bar__dismiss svg:hover{background:#e37705;fill:#fff}.feedback-bar__show{position:fixed;bottom:.3125rem;left:.3125rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"price1": "#d3048e",
	"price2": "#936d22",
	"price3": "#617e26",
	"price4": "#196e19",
	"price5": "#056868",
	"price6": "#5252b6",
	"price7": "#8d0dcd",
	"price8": "#bc2e2d"
};
module.exports = ___CSS_LOADER_EXPORT___;
