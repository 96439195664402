





























import Vue from 'vue'
import { Component, Prop, VModel } from 'vue-property-decorator'

@Component
export default class UIRadioButton extends Vue {
  @Prop() id!: string
  @Prop({ type: String }) label?: string
  @Prop() value!: string | number
  @Prop() val!: string | number
  @Prop() name!: string
  @Prop({ type: Boolean, default: false }) required?: boolean
  @Prop({ type: Boolean, default: false }) disabled?: boolean

  @VModel({ type: String | Boolean }) localModel!: string

  get checked(): boolean {
    return this.val == this.value
  }

  getName(): string {
    return this.name ? this.name : this.id
  }

  selectInput() {
    this.$refs.input.checked = true
    this.localModel = this.$refs.input.value
  }
}
