<template>
  <div class="layout">
    <client-only>
      <VueAnnouncer />
      <FeedbackBar />
    </client-only>
    <Header id="header" />

    <main id="main" class="main">
      <client-only>
        <AlertNotification />
      </client-only>
      <nuxt />
    </main>

    <Footer />
    <client-only>
      <portal-target name="after-footer" multiple></portal-target>
    </client-only>
  </div>
</template>
<script>
import Header from '~/components/layout/header/Header'
import Footer from '~/components/layout/footer/Footer'
import AlertNotification from '~/components/ui/notifications/AlertNotification'
import FeedbackBar from '~/components/ui/feedback-bar/FeedbackBar.vue'

export default {
  components: {
    Header,
    AlertNotification,
    Footer,
    FeedbackBar,
  },

  head() {
    return {
      titleTemplate: '%s | Zoo Society of Milwaukee',
    }
  },

  methods: {
    onWindowFocusChange(e) {
      let inView = false
      if ({ focus: 1, pageshow: 1 }[e.type]) {
        if (inView) return
        this.$emit('tab-focus-change', true)
        inView = true
      } else if (inView) {
        this.$emit('tab-focus-change', false)
        inView = false
      }
    },

    detectFocusChange() {
      window.addEventListener('focus', this.onWindowFocusChange)
      window.addEventListener('blur', this.onWindowFocusChange)
      window.addEventListener('pageshow', this.onWindowFocusChange)
      window.addEventListener('pagehide', this.onWindowFocusChange)
    },
  },

  mounted() {
    this.detectFocusChange()
  },

  beforeDestroy() {
    window.removeEventListener('focus', this.onWindowFocusChange)
    window.removeEventListener('blur', this.onWindowFocusChange)
    window.removeEventListener('pageshow', this.onWindowFocusChange)
    window.removeEventListener('pagehide', this.onWindowFocusChange)
  },
}
</script>

<style lang="scss" scoped>
.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
}
#header {
  background-color: $bg;
}
</style>
