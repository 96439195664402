














import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import LoadingWheel from '~/components/ui/LoadingWheel.vue'

/**
 * This is a standard UIButton component.  It is globally imported see plugins/global-components.js
 * @version 1.0.0
 * @author [After Digital](https://afterdigital.co.uk)
 * @since Version 1.0.0
 */
@Component({
  components: {
    LoadingWheel,
  },
})
export default class UIButton extends Vue {
  /** Pass the to Prop to route within the app on button press, by default the button emits a click event */
  @Prop({ default: false }) to?: string
  /** Standard HTML button type */
  @Prop({ default: 'button' }) type?: string
  /** Depracated, use the slot instead for more flexibility */
  @Prop({ default: '' }) label?: string
  @Prop({ type: Boolean, default: false }) disabled?: boolean
  @Prop({ type: Boolean, default: false }) loading?: boolean
  @Prop({ type: String, default: 'Loading' }) loadingLabel?: string
  @Prop({ type: String | Array }) modifier?: string | string[]

  handleClick($event) {
    if (this.to && !this.to.includes('http')) {
      this.$router.push(this.to)
    } else if (this.to) {
      window.location.href = this.to
    } else {
      this.$emit('click', $event)
    }
  }

  get buttonClass(): String | undefined {
    if (this.modifier) {
      if (typeof this.modifier === 'string') {
        return `button--${this.modifier}`
      } else {
        return this.modifier.reduce((acc, modifier, index) => {
          if (index === 0) {
            return (acc += `button--${modifier}`)
          } else {
            return (acc += ` button--${modifier}`)
          }
        }, '')
      }
    }
  }
}
