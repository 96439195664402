import 'core-js/features/object/entries'
import 'core-js/features/array/includes'
import 'core-js/features/array/find'
import 'core-js/features/array/from'
import 'core-js/es/promise'
import 'core-js/features/object/assign'
import 'core-js/es/symbol'
import 'core-js/es/number'
import 'whatwg-fetch'
import fetchPonyfill from 'fetch-ponyfill'
const { fetch, Request, Response, Headers } = fetchPonyfill()
import { remove } from './polyfills/remove'
import { closest } from './polyfills/closest'

import Vue from 'vue'
import moment from 'moment'

Vue.prototype.$moment = moment // hack for storybook
