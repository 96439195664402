// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".text--widen{letter-spacing:.15px}.mini-basket{position:relative;background-color:#000;height:100%}.mini-basket,.mini-basket a{display:flex;align-items:center}.mini-basket svg{width:1.0625rem;height:1.0625rem;margin-right:.3125rem}.mini-basket svg path{fill:#fff}.meta-list__list-item-link,.meta-list__list-item-link .count{font-family:\"Poppins\",Arial,sans-serif;color:#fff;font-weight:600;text-decoration:none;display:block}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"price1": "#d3048e",
	"price2": "#936d22",
	"price3": "#617e26",
	"price4": "#196e19",
	"price5": "#056868",
	"price6": "#5252b6",
	"price7": "#8d0dcd",
	"price8": "#bc2e2d"
};
module.exports = ___CSS_LOADER_EXPORT___;
