// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".text--widen{letter-spacing:.15px}.radio-group{display:flex;flex-wrap:wrap;margin-top:-.5rem;margin-bottom:-.5rem;flex-direction:column}.radio-group>*{margin-top:.5rem;margin-bottom:.5rem}@media(min-width:40em){.radio-group{margin:0 -.5rem;align-items:center;flex-direction:row}.radio-group>*{margin-top:0;margin-bottom:0;margin-left:.5rem;margin-right:.5rem}}.radio-group__label{flex:1 0 100%;margin-bottom:0}.radio-group--column{flex-direction:column;align-items:flex-start}.radio-group--column .radio{margin-bottom:1em}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"price1": "#d3048e",
	"price2": "#936d22",
	"price3": "#617e26",
	"price4": "#196e19",
	"price5": "#056868",
	"price6": "#5252b6",
	"price7": "#8d0dcd",
	"price8": "#bc2e2d"
};
module.exports = ___CSS_LOADER_EXPORT___;
