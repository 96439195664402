














import { Component, Vue } from 'vue-property-decorator'
// import Svgcart from '~/static/images/sprites/cart.svg'
import Basket from '~/static/images/sprites/shopping-basket-solid.svg'

@Component({
  components: {
    // Svgcart,
    Basket
  }
})
export default class MiniBasket extends Vue {
  public loaded: boolean = false
}
