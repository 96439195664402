// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".text--widen{letter-spacing:.15px}.footer{background:#333;color:#fff;padding:3.75rem 0;text-align:center}@media(min-width:75em){.footer{text-align:left}}.footer__wrapper{gap:4.0625rem;display:grid;grid-template-columns:1fr}@media(min-width:48em){.footer__wrapper{grid-template-columns:repeat(2,1fr)}}@media(min-width:75em){.footer__wrapper{grid-template-columns:repeat(4,1fr)}}.footer__logo-link{margin-bottom:1rem}.footer__logo{max-height:6.375rem}.footer h2{color:#e37705}.footer p{font-size:1.0625rem;line-height:1.625rem}.footer a{color:#e8e4dc;text-decoration:none;font-size:1.0625rem;font-weight:400;display:inline-flex;align-items:center;transition:all .2s ease-in-out}.footer a:hover{color:#e37705}.footer a svg{fill:#fff}.footer span{display:block}.footer__social-media a svg{fill:#e37705;width:2.4375rem;height:2.4375rem}.footer__get-in-touch-links{display:flex;gap:1rem;justify-content:center}@media(min-width:75em){.footer__get-in-touch-links{justify-content:flex-start}}.footer__get-in-touch-links a{font-weight:700;position:relative}.footer__get-in-touch-links a:after{position:absolute;left:0;right:0;margin:0 auto;bottom:-.3125rem;content:\"\";display:block;width:100%;height:1px;background:#e8e4dc;transition:width .2s ease-in-out}.footer__get-in-touch-links a:hover:after{width:25%}.footer__charity-links{margin-top:3.125rem;display:flex;gap:1rem;justify-content:center}@media(min-width:75em){.footer__charity-links{justify-content:flex-start}}.footer__visit-zoo{max-width:8.3125rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"price1": "#d3048e",
	"price2": "#936d22",
	"price3": "#617e26",
	"price4": "#196e19",
	"price5": "#056868",
	"price6": "#5252b6",
	"price7": "#8d0dcd",
	"price8": "#bc2e2d"
};
module.exports = ___CSS_LOADER_EXPORT___;
