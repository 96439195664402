import Vue from 'vue'
import VueAxios from 'vue-axios'
import VueAuthenticate from 'vue-authenticate'
import axios from 'axios'
import { config } from '~/config'

const generateNonce = () => {
  var length = 32;
  var charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._'
  var result = ''

  while (length > 0) {
      var bytes = new Uint8Array(16);
      var random = window.crypto.getRandomValues(bytes);

      random.forEach(function(c) {
          if (length == 0) {
              return;
          }
          if (c < charset.length) {
              result += charset[c];
              length--;
          }
      });
  }

  localStorage.setItem('oidc_nonce', result);

  return result;
}

const baseUrl = config.APP_URL;

Vue.use(VueAxios, axios)
Vue.use(VueAuthenticate, {
  baseUrl: `${baseUrl}`,
  tokenName: 'access_token',
  
  providers: {
    google: {
      clientId: config.GOOGLE_CLIENT_ID,
      authorizationEndpoint: 'https://accounts.google.com/o/oauth2/v2/auth',
      redirectUri: `${baseUrl}/auth/callback/`,
      url: null,
      tokenPath: "id_token",
      responseType: 'id_token',
      scope: ['profile', 'email', 'openid'],
      responseParams: {
        id_token: 'id_token'
      },
      requiredUrlParams: ['scope','nonce'],
      nonce: generateNonce
    },
    facebook: {
      clientId: '',
      clientSecret: '',
      redirectUri: `${baseUrl}/auth/callback/` 
    },
    twitter: {
      clientId: '',
      clientSecret: '',
      redirectUri: `${baseUrl}/auth/callback/` 
    },
    instagram: {
      clientId: '',
      clientSecret: '',
      redirectUri: `${baseUrl}/auth/callback/` 
    }
  }
})