import VuexPersistence from 'vuex-persist'
import Cookies from 'js-cookie'

export default ({ store }) => {
  new VuexPersistence({
    storage: window.localStorage, // or window.sessionStorage or localForage instance.
    // // Function that passes the state and returns the state with only the objects you want to store.
    reducer: (state) => ({
      customer: {
        logged_in: state.customer.logged_in,
        token: state.customer.token,
      },
      giftaid: state.giftaid,
      selection: {
        delivery_address: state.selection.delivery_address,
        seats: state.selection.seats,
        event: state.selection.event,
        instance: state.selection.instance,
        prices: state.selection.prices,
        designation: state.selection.designation,
        membership: state.selection.membership,
        children: state.selection.children,
        classes: state.selection.classes,
        membershipOptions: state.selection.membershipOptions,
        sponsorshipOptions: state.selection.sponsorshipOptions,
        expiryDate: state.selection.expiryDate,
      },
      csi: {
        csis: state.csi.csis,
      },
      seats: {
        best_available_selection: state.seats.best_available_selection,
      },
      basket: {
        promo_code: state.basket.promo_code,
        gift_vouchers: state.basket.gift_vouchers,
        valid_participants: state.basket.valid_participants,
      },
      redirect: state.redirect,
      forms: {
        submissions: state.forms.submissions,
        deferred_submissions: state.forms.deferred_submissions,
      },
      gifts: {
        gift_vouchers: state.gifts.gift_vouchers,
        gift_memberships: state.gifts.gift_memberships,
      },
    }),
  }).plugin(store)

  const expireIn30Minutes = 1 / 48

  const vuexCookie = new VuexPersistence({
    restoreState: (key, storage) => Cookies.getJSON(key),
    saveState: (key, state, storage) =>
      Cookies.set(key, state, {
        expires: expireIn30Minutes,
      }),
    reducer: (state) => ({
      basket: {
        promo_code: state.basket.promo_code,
      },
      session: {
        redirect: state.session.redirect,
        classInfoDismissed: state.classInfoDismissed,
      },
    }),
  }).plugin(store)
}
