






























import { Component, Prop, mixins } from 'nuxt-property-decorator'
import Field from './Field'

@Component
export default class RadioGroup extends mixins<Field>(Field) {
  @Prop({ type: Array, required: true }) options!: Array<{
    label: string
    value: any
  }>

  get compiledRules() {
    if (this.rules !== undefined && this.rules != '' && this.requiredBoolean) {
      return `${this.rules}|required`
    } else if (this.rules == '' && this.requiredBoolean) {
      return `required`
    } else {
      return this.rules
    }
  }
}
