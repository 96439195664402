

























import { Component, Prop, VModel, Vue } from 'nuxt-property-decorator'

@Component
export default class WrappedRadio extends Vue {
  @Prop() id!: string
  @Prop() label!: string
  @Prop() val!: string | number | boolean
  @Prop() name!: string
  @Prop({ type: Boolean, default: false }) disabled?: boolean
  @Prop({ type: Boolean, default: false }) isVertical!: boolean
  @Prop({ type: String | Array }) modifiers?: string | string[]

  @VModel({ type: String | Number | Boolean }) localModel!:
    | string
    | number
    | boolean

  get checkedClass(): string {
    return this.value === this.val ? 'radio--checked' : ''
  }

  get isVerticalClass(): string | boolean {
    return this.isVertical && 'radio--vertical'
  }

  get classModifiers(): string[] | undefined {
    const modifiers: string[] = []

    if (this.value == this.val) {
      modifiers.push('radio--checked')
    }

    if (this.isVertical) {
      modifiers.push('radio--vertical')
    }

    if (this.disabled) {
      modifiers.push('radio--disabled')
    }

    if (this.modifiers) {
      if (typeof this.modifiers === 'string') {
        modifiers.push(`radio--${this.modifiers}`)
      } else {
        const modifiersStr = this.modifiers.reduce((acc, modifier, index) => {
          if (index === 0) {
            return (acc += `radio--${modifier}`)
          } else {
            return (acc += ` radio--${modifier}`)
          }
        }, '')

        modifiers.push(modifiersStr)
      }
    }

    return modifiers
  }

  selectInput() {
    this.$refs.input.checked = true
    this.localModel = this.$refs.input.value
  }
}
