// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".text--widen{letter-spacing:.15px}.my-account{min-height:60vh}.my-account__container{max-width:100%;margin:0 1.5rem}@media(min-width:64em){.my-account__container{max-width:63.75rem;margin:0 auto}}@media(min-width:64em){.my-account__main{display:grid;grid-column-gap:1.5rem;grid-template-columns:16.25rem 1fr}}.my-account__side-nav{display:none}@media(min-width:64em){.my-account__side-nav{display:block}}.my-account__notifications{margin-bottom:1.5rem}.my-account__logout-button{margin-top:1.5rem}.my-account__logout-button button{width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"price1": "#d3048e",
	"price2": "#936d22",
	"price3": "#617e26",
	"price4": "#196e19",
	"price5": "#056868",
	"price6": "#5252b6",
	"price7": "#8d0dcd",
	"price8": "#bc2e2d"
};
module.exports = ___CSS_LOADER_EXPORT___;
