import Vue from 'vue'
import { config } from '~/config'

/**
 * Injects a $formatCurrency function globally so we can define the locale in a single place rather than
 * hardcoding dollar and pound signs all over the shop
 */
export default (context, inject) => {
  /**
   * @param Number - the money amount to be formatted
   * @param Boolean - Override default and round down to whole numbers
   *
   * @return String - Currency formatted to the locale set in .env
   */
  const formatCurrency = (number = 0, roundWhole = true) => {
    if (!number) {
      number = 0
    }
    let maximumFractionDigits = 2
    let minimumFractionDigits = 2
    if (Math.floor(number) === number && roundWhole) {
      maximumFractionDigits = 0
      minimumFractionDigits = 0
    }
    return number.toLocaleString(context.app.$config.get('LOCALE'), {
      style: 'currency',
      currency: context.app.$config.get('CURRENCY'),
      maximumFractionDigits,
      minimumFractionDigits
    })
  }
  Vue.prototype.$formatCurrency = formatCurrency
  inject('formatCurrency', formatCurrency)
}
