














































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Field from '~/components/ui/forms/fields/Field.ts'

@Component
export default class PasswordFieldReveal extends Field {
   /** Optional additional class to add to input element */
  @Prop() inputClass?: string
  @Prop({ type: String }) errors: string
  @Prop({ type: String }) name: string
  @Prop({ type: Boolean, default: false }) hasCheckbox?: boolean

  public is_showing: boolean = false

  toggle(): void {
    this.is_showing = !this.is_showing
  }

  @Watch
  onPasswordErrors(oldVal, newVal) {
    if (newVal) {
      this.$refs.form.setErrors({
        password: newVal
      })
    }
  }
}
