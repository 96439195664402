import { Vue } from 'nuxt-property-decorator'
import { gtmProductsTransformer } from '~/js-modules/helpers/gtmProductsTransformer'
import { gtmG4ProductsTransformer } from '~/js-modules/helpers/gtmG4ProductsTransformer'
import { sumBy } from 'lodash'
import moment from 'moment-timezone'

export default ({ app }) => {
  /**
   * SOURCE ID
   */
  app.$eventBus.on('update_source', (source) => {
    if (source != undefined && source > 0) {
      app.store.dispatch('basket/setSource', parseInt(source))
    }
  })

  /**
   * MODE OF SALE
   */
  app.$eventBus.on('update_mode_of_sale', (mos) => {
    if (mos != undefined && mos > 0) {
      app.store.dispatch('basket/setModeOfSale', parseInt(mos))
    }
  })

  app.$eventBus.on('revert_mode_of_sale', () => {
    app.store.dispatch('basket/revertModeOfSale')
  })

  /**
   * GIFT AID
   * set up a listener so that gift aid requests will be processed on login
   */
  app.$eventBus.on('login', () => {
    app.store.dispatch('giftaid/processRequests')
  })

  /**
   * GIFT AID
   * set up a listener so that gift aid requests will be processed on login
   */
  app.$eventBus.on('login', () => {
    app.store.dispatch('memberships/processSecondMember')
  })

  /**
   * COOKIES
   */
  app.$eventBus.on('update_cookie', (params) => {
    let cookie = {}
    if (app.$cookies.get('bridge_user_status')) {
      cookie = app.$cookies.get('bridge_user_status')
    }

    const cookie_contents = Object.assign(cookie, params)

    app.$cookies.set('bridge_user_status', JSON.stringify(cookie_contents))

    //app.$eventBus.$emit('cookie_updated')
  })

  app.$eventBus.on('clear_cookie', (params) => {
    if (app.$cookies.get('bridge_user_status')) {
      app.$cookies.remove('bridge_user_status')
    }
  })

  // if item is added to an empty basket then we should update the expiry time as if this was a new basket
  app.$eventBus.on('basket:item-added', () => {
    let cookie = {}
    if (app.$cookies.get('bridge_user_status')) {
      cookie = app.$cookies.get('bridge_user_status')
    }

    if (cookie.basket_count === 1) {
      app.store.dispatch(
        'basket/updateBasketExpiry',
        moment().utc().add(20, 'minutes').toDate()
      )
    }
  })

  /**
   * GOOGLE ANALYTICS
   */
  app.$eventBus.on('gtm:add-to-basket', (product) => {
    if (app.$gtm) {
      app.$gtm.trackEvent({
        event: 'addToCart',
        ecommerce: {
          currencyCode: 'GBP',
          add: {
            products: [product],
          },
        },
      })
    }
  })

  app.$eventBus.on('gtm:remove-from-basket', (product) => {
    if (app.$gtm) {
      app.$gtm.trackEvent({
        event: 'removeFromCart',
        ecommerce: {
          remove: {
            products: [product],
          },
        },
      })
    }
  })

  app.$eventBus.on('gtm:select-delivery-method', ({ step, option }) => {
    if (app.$gtm) {
      app.$gtm.trackEvent({
        event: 'checkoutOption',
        ecommerce: {
          currencyCode: 'GBP',
          checkout_option: {
            actionField: {
              step: step,
              option: option,
            },
          },
        },
      })
    }
  })

  app.$eventBus.on('gtm:proceed-to-delivery', (products) => {
    if (app.$gtm) {
      app.$gtm.trackEvent({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 1,
            },
            products: gtmProductsTransformer(products),
          },
        },
      })
    }
  })

  app.$eventBus.on('gtm:proceed-to-payment', (params) => {
    if (app.$gtm) {
      app.$gtm.trackEvent({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 2,
              option: params.deliveryMethodName,
            },
            products: gtmProductsTransformer(params.products),
          },
        },
      })
    }
  })

  app.$eventBus.on('gtm:complete-transaction', ({ order, promo }) => {
    if (app.$gtm) {
      app.$gtm.trackEvent({
        event: 'purchase',
        action: 'purchase',
        transaction_id: order ? order.order_ref : '',
        value: order ? order.total : '',
        currency: 'USD',
        affiliation: app.$config.get('CLIENT_NAME'),
        items: gtmG4ProductsTransformer(order.groups),
      })
    }
  })
}
