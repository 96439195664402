



























































import { Component, Prop, mixins, VModel } from 'nuxt-property-decorator'
import Field from './Field'

/**
 * This is a wrapper around a standard text field, which uses the vee-validate Provider to give us a standard validation interface and layout for all text fields
 * @version 1.0.0
 * @author [After Digital](https://afterdigital.co.uk)
 * @since Version 1.0.0
 */
@Component
export default class TextField extends mixins<Field>(Field) {
  /** Optional additional class to add to input element */
  @Prop() inputClass?: string
  /** Optional additional description for field, added in a span */
  @Prop() descriptiveLabel?: string
  @Prop({ type: String, default: 'text' }) fieldType?: string

  @VModel() localModel!: any

  checkForDate() {
    if (
      this.fieldType == 'date' &&
      this.$moment(this.localValue, this.$moment.ISO_8601, true).isValid()
    ) {
      const dob = this.$moment(this.localValue).format('YYYY-MM-DD')
      this.$emit('input', dob)
    }
  }

  get showRequired(): boolean {
    try {
      return Boolean(
        this.rules && (this.rules.required || this.rules.includes('required'))
      )
    } catch (err_) {
      return false
    }
  }

  mounted() {
    this.checkForDate()
  }
}
