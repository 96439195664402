






import Vue from 'vue'
import { Component } from 'nuxt-property-decorator'

@Component
export default class SkipToContent extends Vue {}
