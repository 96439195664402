









































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import Field from '~/components/ui/forms/fields/Field.ts'
import EditSVG from '~/static/images/edit.svg'

@Component({
  components: {
    EditSVG
  }
})
export default class ReadOnlyEditable extends Field {
  /**
   * Pass method as prop to override the default behaviour when the pencil is clicked
   */
  @Prop({ type: Function }) editAction: Function

  public isEditable: boolean = false

  toggle(): void {
    if (this.editAction) {
      this.editAction()
    } else {
      this.isEditable ? this.close() : this.open()
    }
  }

  open(): void {
    this.isEditable = true
    this.$el.querySelector('input').focus()
  }

  close(): void {
    this.isEditable = false
  }

  mounted(): void {
    this.$el.querySelector('input').addEventListener('blur', () => {
      this.isEditable = false
    })
  }
}
