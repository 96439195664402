export type ConfigOptions = {
  APP_ENV: string
  APP_URL: string
  PORT: string
  GRAPHQL_ENDPOINT: string
  QRCODE_ENDPOINT: string
  CURRENCY_SYMBOL: string
  DEFAULT_COUNTRY_NAME: string
  DEFAULT_COUNTRY_CODE: string
  ASSET_PATH: string
  CURRENCY: string
  LOCALE: string
  SYSTEM_ERROR_MESSAGE: string
  MARKETING_WHATS_ON: string
  MARKETING_TERMS_AND_CONDITIONS: string
  LOQATE_KEY: string
  URLS: object
  GOOGLE_CLIENT_ID: string
  GOOGLE_RECAPTCHA_CLIENT_ID: string
  GOOGLE_TAG_MANAGER: string
  WINDCAVE_ERRORS: object
  DEFAULT_ADDRESS: object
  BASKET_DONATION_FUND: number
  CLIENT_NAME: string
  REGISTRATION_TYPE_REF: string
  MEMBERSHIP_FUND_REFS: string[]
  ON_ACCOUNT_PAYMENT_REF: string
  ORNAMENTS_INSTANCE_REF: () => number
  SUMMER_TEE_INSTANCE_REF: () => number
}

export const config: ConfigOptions = {
  APP_ENV: `${process.env.APP_ENV}`,
  APP_URL: `${process.env.APP_URL}`,
  PORT: `${process.env.PORT}`,
  GRAPHQL_ENDPOINT: `${process.env.skywayEndpoint}`,
  QRCODE_ENDPOINT: `${process.env.qrEndpoint}`,
  CURRENCY_SYMBOL: '$',
  DEFAULT_COUNTRY_NAME: 'GB',
  DEFAULT_COUNTRY_CODE: '209',
  ASSET_PATH: `${process.env.assetPath}`,
  CURRENCY: `${process.env.currency}`,
  LOCALE: `${process.env.locale}`,
  SYSTEM_ERROR_MESSAGE:
    'There is a temporary error with our ticketing system.  Please try again in a moment.',
  MARKETING_WHATS_ON: 'https://www.zoosociety.org/things-to-do/events/',
  MARKETING_TERMS_AND_CONDITIONS: 'https://www.zoosociety.org/policies/',
  LOQATE_KEY: `${process.env.LOQATE_KEY}`,
  URLS: {
    select_seats: '/select-seats/',
    best_available: '/best-available/',
    timed_entry: '/timed-entry/',
    memberships: '/memberships/',
    calendar: '/calendar/',
    donations: '/donations',
    select_area: '/select-area/',
    forgot_password: '/forgotten-password/',
    gift_vouchers: '/gift-vouchers/',
    sign_in: '/sign-in/',
    basket: '/cart/',
    account: '/account/',
    register: '/register/',
    payment: '/payment/',
    success: '/success/',
    direct_debit: '/direct-debit/',
    direct_debit_confirmation: '/direct-debit/confirmation/',
    membership_details: '/membership-details/',
    delivery: '/delivery/',
    whats_on: '/events/',
    classes: '/classes/',
    zoo_pass: '/zoo-pass/',
    designations: '/animal-sponsorships/',
    designation: '/animal-sponsorship/',
  },
  GOOGLE_CLIENT_ID: `${process.env.google.clientId}`,
  GOOGLE_RECAPTCHA_CLIENT_ID: `${process.env.google.recaptchaClientId}`,
  GOOGLE_TAG_MANAGER: `${process.env.google.tagManagerId}`,
  WINDCAVE_ERRORS: {
    default: 'Your payment was cancelled.',
    declined:
      'Your bank has declined this transaction. Please check your billing address details are correct and that you have sufficient funds in your account, and try again. If the problem persists please contact our Ticket Office by emailing customer@southbankcentre.co.uk.',
    QI: 'Please check your card details and try again.',
    QJ: 'Please check your card details and try again.',
    QK: 'Please check your card details and try again.',
    QL: 'Please check your card details and try again.',
    U9: 'Sorry, we were unable to process the transaction. Please try again.',
    E0: 'There was an issue with your 3D Secure submission. Please try again.',
    RC: '',
  },
  DEFAULT_ADDRESS: {
    street1: 'Zoological Society of Milwaukee',
    street2: '10005 West Bluemound Road',
    city: 'Milwaukee',
    state: 'WI',
    postal_code: '53226-4383',
    country_ref: 1,
  },
  BASKET_DONATION_FUND: 41,
  CLIENT_NAME: 'Zoological Society of Milwaukee',
  REGISTRATION_TYPE_REF: '1',
  MEMBERSHIP_FUND_REFS: ['46'],
  ON_ACCOUNT_PAYMENT_REF: '62',
  ORNAMENTS_INSTANCE_REF: () => 14379,
  SUMMER_TEE_INSTANCE_REF: () => 11655,
}
