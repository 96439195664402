import Vue from 'vue'
import { ActionTree, MutationTree, GetterTree, ActionContext } from 'vuex'
import { cloneDeep, groupBy } from 'lodash'
import { RootState } from './types'
import {
  Event,
  Instance,
  Package,
  Seat,
  Membership,
  DesignationDetail,
  Customer,
  CustomerChild,
  CustomerAddress,
} from '~/@types/skyway'
import { config } from '~/config'

export const name = 'selection'

/**
 * The selection store module is concerned with storing customer options
 * client side, prior to being pushed to the basket, it gives us a central
 * place to access details about selected seats, etc. without having to rely
 * on local component state or the event bus
 *
 * We should aim to make this the single source of truth
 */

export interface OptionsModel {
  type?: string | null
  step?: number
  renewal?: boolean | null
  adults?: number
  children?: number | null
  guests?: number | null
  gift?: boolean | null
  level?: string | null
  send_to?: string | null
  parking?: boolean | null
  years?: number | null
  recipient_id?: number | null
  primary_recipient?: object | null
  secondary_recipient?: object | null
  personal_message?: string
  waive_benefits?: boolean | string | null
  selected_benefits?: string[] | null
  recognition_board_name?: string | null
  anonymous: boolean
  amount: number
  designation?: string | null
  fund?: string | null
  send_date?: string | Date | null
  recipient_type?: string
  certificate_date?: Date | null
  employer_matching_gift?: boolean | null
  employer_name?: string | null
  is_memorial?: boolean | null
  renewal_to?: string | null
  estate_plans?: boolean
  additional_info?: string | null
}
export interface ClassSelection extends Instance {
  children?: Array<CustomerChild>
  prices?: any
}

export interface ClassPackageSelection extends Package {
  children?: Array<CustomerChild>
  prices?: any
}

export interface PriceSelection {
  price_type_ref?: any
  instance_ref?: any
  qty?: any
  zone_ref?: any
  special_requests?: string
}

export interface State {
  event?: Event
  instance?: Instance
  prices?: Array<PriceSelection>
  seats?: Array<Seat>
  membership?: Membership
  designation?: DesignationDetail
  deliveryAddress?: Object
  membershipOptions: OptionsModel
  classes?: Array<ClassSelection>
  class_packages?: Array<ClassPackageSelection>
  children?: Array<CustomerChild>
  childFilters: CustomerChild[]
  expiryDate: Date
}

export const customerAddress: CustomerAddress = {
  street1: config.DEFAULT_ADDRESS.street1,
  street2: config.DEFAULT_ADDRESS.street2,
  city: config.DEFAULT_ADDRESS.city,
  postal_code: config.DEFAULT_ADDRESS.postal_code,
  state: config.DEFAULT_ADDRESS.state,
  state_ref: null,
  country: null,
  country_ref: config.DEFAULT_ADDRESS.country_ref,
  type: null,
  type_ref: 1,
}

export const secondaryRecipient: Customer = {
  customer_ref: null,
  first_name: '',
  last_name: '',
  meta: {
    email: null,
    phone: null,
  },
}

export const primaryRecipient: Customer = {
  customer_ref: null,
  first_name: '',
  last_name: '',
  meta: {
    email: null,
    address: customerAddress,
    phone: null,
  },
}

export const initialOptionState: OptionsModel = {
  type: null,
  step: 0,
  renewal: null,
  adults: 0,
  children: null,
  guests: null,
  gift: null,
  level: null,
  parking: null,
  years: null,
  send_to: null,
  recipient_id: null,
  primary_recipient: primaryRecipient,
  secondary_recipient: secondaryRecipient,
  personal_message: '',
  waive_benefits: null,
  selected_benefits: null,
  recognition_board_name: '',
  anonymous: false,
  amount: 0,
  designation: null,
  send_date: null,
  recipient_type: 'individual',
  certificate_date: null,
  is_memorial: false,
  employer_matching_gift: false,
  employer_name: null,
  estate_plans: false,
  renewal_to: null,
  additional_info: '',
}

const baseState = () => {
  return {
    event: undefined,
    instance: undefined,
    membership: undefined,
    prices: [],
    seats: [],
    deliveryAddress: {},
    membershipOptions: initialOptionState,
    classes: [],
    class_packages: [],
    children: [],
    childFilters: [],
    expiryDate: new Date(),
  }
}

export const state = baseState

export const types = {
  SET_EVENT: 'SET_EVENT',
  SET_INSTANCE: 'SET_INSTANCE',
  SET_MEMBERSHIP: 'SET_MEMBERSHIP',
  SET_DESIGNATION: 'SET_DESIGNATION',
  SET_DELIVERY_ADDRESS: 'SET_DELIVERY_ADDRESS',
  CLEAR_SELECTION: 'CLEAR_SELECTION',
  CLEAR_PRICES: 'CLEAR_PRICES',
  SET_PRICE: 'SET_PRICE',
  SET_SEAT: 'SET_SEAT',
  REMOVE_SEAT: 'REMOVE_SEAT',
  CLEAR_SEATS: 'CLEAR_SEATS',
  CLEAR_DELIVERY_ADDRESS: 'CLEAR_DELIVERY_ADDRESS',
  SET_OPTION: 'SET_OPTION',
  SET_OPTIONS: 'SET_OPTIONS',
  CLEAR_OPTIONS: 'CLEAR_OPTIONS',
  SET_CLASS: 'SET_CLASS',
  SET_CLASS_PACKAGE: 'SET_CLASS_PACKAGE',
  SET_CHILD: 'SET_CHILD',
  CLEAR_CHILDREN: 'CLEAR_CHILDREN',
  REMOVE_CLASS: 'REMOVE_CLASS',
  REMOVE_CLASS_PACKAGE: 'REMOVE_CLASS_PACKAGE',
  CLEAR_CLASSES: 'CLEAR_CLASSES',
  SET_EXPIRY_DATE: 'SET_EXPIRY_DATE',
  SET_CHILD_FILTERS: 'SET_CHILD_FILTERS',
}

export const getters: GetterTree<State, RootState> = {
  getTicketSelection: (state: State): Array<PriceSelection> | null => {
    if (state.prices) {
      return state.prices
    }

    return null
  },

  getSelectedEvent: (state: State): Event | null => {
    if (state.event) {
      return state.event
    }

    return null
  },

  getSelectedInstance: (state: State): Instance | null => {
    if (state.instance) {
      return state.instance
    }

    return null
  },

  getSelectedMembership: (state: State): Membership | null => {
    if (state.membership) {
      return state.membership
    }

    return null
  },

  classesGroupedByDate: (state: State): any => {
    const data =
      state.classes && state.classes.length
        ? cloneDeep(state.classes).sort(
            (a, b) =>
              a.date_time.substring(0, a.date_time.indexOf('T')) -
              b.date_time.substring(0, b.date_time.indexOf('T'))
          )
        : []

    return groupBy(
      data.map((item: ClassSelection) => {
        const price =
          item.prices && item.prices.length
            ? item.prices.find((type) => type.base)
            : false
        return {
          ...item,
          price: price ? price.price : item.prices[0].price,
          date: item.date_time.substring(0, item.date_time.indexOf('T')),
        }
      }),
      'date'
    )
  },

  selectedChildren: (state: State): Array<CustomerChild> => {
    return state.classes && state.classes.children ? state.classes.children : []
  },

  isExpired: (state: State): Boolean => {
    return new Date(state.expiryDate).getTime() < new Date().getTime()
  },
}

export const actions: ActionTree<State, RootState> = {
  async setSelectedInstance(
    context: ActionContext<State, RootState>,
    payload: Instance
  ): Promise<void> {
    return new Promise((res, rej) => {
      context.commit(types.SET_INSTANCE, payload)
      res()
    })
  },

  async setSelectedMembership(
    context: ActionContext<State, RootState>,
    payload: Membership
  ): Promise<void> {
    return new Promise((res, rej) => {
      context.commit(types.SET_MEMBERSHIP, payload)
      res()
    })
  },

  async setSelectedDesignation(
    context: ActionContext<State, RootState>,
    payload: DesignationDetail
  ): Promise<void> {
    return new Promise((res, rej) => {
      context.commit(types.SET_DESIGNATION, payload)
      res()
    })
  },
}

export const mutations: MutationTree<State> = {
  [types.SET_EVENT](state: State, payload: Event) {
    state.event = payload
  },
  [types.SET_INSTANCE](state: State, payload: Instance) {
    state.instance = payload
  },
  [types.SET_MEMBERSHIP](state: State, payload: Membership) {
    state.membership = payload
  },
  [types.SET_DESIGNATION](state: State, payload: DesignationDetail) {
    state.designation = payload
  },
  [types.SET_PRICE](state: State, payload: any) {
    if (state.prices) {
      const index = state.prices.findIndex(
        (price) => price.price_type_ref == payload.price_type_ref
      )

      if (index !== -1) {
        if (payload.qty === 0 || isNaN(payload.price_type_ref)) {
          state.prices.splice(index, 1)
        } else {
          state.prices[index] = payload
        }
      } else {
        if (payload.qty > 0 && !isNaN(payload.price_type_ref)) {
          state.prices.push(payload)
        }
      }
    }
  },
  [types.CLEAR_SELECTION](state: State) {
    Object.assign(state, baseState())
  },
  [types.CLEAR_PRICES](state: State) {
    state.prices = []
  },
  [types.CLEAR_SEATS](state: State) {
    state.seats = []
  },
  [types.SET_SEAT](
    state: State,
    payload: { seat: Seat; price_type_ref: number }
  ) {
    if (state.seats) {
      const index = state.seats.findIndex(
        (seat) => seat.seat.seat_ref == payload.seat.seat_ref
      )

      if (index == -1) {
        state.seats.push(payload)
      }
    }
  },
  [types.REMOVE_SEAT](state: State, payload: Seat) {
    state.seats.splice(
      state.seats.findIndex((seat) => seat.seat_ref === payload.seat_ref),
      1
    )
  },
  [types.REMOVE_CLASS](state: State, payload: ClassSelection) {
    state.classes.splice(
      state.classes.findIndex(
        (zooClass) => zooClass.instance_ref === payload.instance_ref
      ),
      1
    )
  },
  [types.SET_CLASS](state: State, payload: ClassSelection) {
    if (state.classes && payload) {
      const index = state.classes.findIndex(
        (zooClass: ClassSelection) =>
          zooClass.instance_ref == payload.instance_ref
      )

      if (index == -1) {
        state.classes.push(payload)
      }
    }
  },
  [types.REMOVE_CLASS_PACKAGE](state: State, payload: ClassPackageSelection) {
    state.class_packages.splice(
      state.class_packages.findIndex(
        (zooClassPackage) => zooClassPackage.package_ref === payload.package_ref
      ),
      1
    )
  },
  [types.SET_CLASS_PACKAGE](state: State, payload: ClassPackageSelection) {
    if (state.class_packages && payload) {
      const index = state.class_packages.findIndex(
        (zooClassPackage: ClassPackageSelection) =>
          zooClassPackage.package_ref == payload.package_ref
      )

      if (index == -1) {
        state.class_packages.push(payload)
      }
    }
  },
  [types.SET_CHILD](state: State, payload: CustomerChild) {
    if (state.children) {
      const index = state.children.findIndex(
        (child) => child.customer_ref == payload.customer_ref
      )

      if (index == -1) {
        state.children.push(payload)
      }
    }
  },
  [types.CLEAR_CHILDREN](state: State) {
    state.children = []
  },
  [types.CLEAR_CLASSES](state: State) {
    state.classes = []
  },
  [types.CLEAR_CLASS_PACKAGES](state: State) {
    state.class_packages = []
  },
  [types.SET_DELIVERY_ADDRESS](state: State, payload: Object) {
    state.deliveryAddress = payload
  },
  [types.CLEAR_DELIVERY_ADDRESS](state: State) {
    state.deliveryAddress = {}
  },
  [types.SET_OPTION](state: State, { payload, key }) {
    if (state.membershipOptions[key] != payload) {
      console.log({ payload, key })
      Vue.set(state.membershipOptions, key, payload)
    }
  },
  [types.SET_OPTIONS](state: State, payload: OptionsModel): void {
    Vue.set(
      state,
      'membershipOptions',
      Object.assign({}, state.membershipOptions, payload)
    )
  },
  [types.CLEAR_OPTIONS](state: State) {
    Object.keys(state.membershipOptions).forEach((key) => {
      Vue.set(state.membershipOptions, key, initialOptionState[key])
    })
  },
  [types.SET_EXPIRY_DATE](state: State, payload) {
    if (!payload) {
      const date = new Date()
      // expire in one day.
      date.setDate(date.getDate() + 1)

      state.expiryDate = date
    } else {
      state.expiryDate = payload
    }
  },
  [types.SET_CHILD_FILTERS](state: State, payload: CustomerChild[]) {
    state.childFilters = payload
  },
}
