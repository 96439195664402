






































/**
 * Confirmation modal works in combination with the mixin
 * and provides UI to allow the user to confirm or cancel an action
 */
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'
import Lock from 'vue-focus-lock'
import MenuCloseSvg from '~/static/images/sprites/close.svg'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'

@Component({
  components: {
    Lock,
    MenuCloseSvg,
  },
})
export default class ModalConfirmation extends Vue {
  @Prop({ type: Boolean, default: false }) open: boolean

  @Watch('open')
  async onOpenStateChange(val, oldVal) {
    if (val === true) {
      await this.$nextTick()
      disableBodyScroll(document.querySelector('.modal--confirmation'))
    } else {
      clearAllBodyScrollLocks()
    }
  }
}
