





import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class UIPanel extends Vue {}
