/**
 * If the basket is already complete, but user somehow ended up away from the success page
 * redirect to success page with orderID
 */
export default async function ({ store, redirect }) {
  const basket = await store.dispatch('basket/getBasket')

  if (!basket || (basket.groups.length === 0 && basket.payments.length === 0)) {
    return redirect('/cart')
  }
}
