import { ActionTree, MutationTree, ActionContext, GetterTree } from 'vuex'
import { RootState } from './types'
import * as seats from '~/api/queries/seats.gql'

import { Seat, Screen, Zone, ScreenZone, ZonePrice } from '~/@types/skyway'

export interface BestAvailableSelection {
  price_type_ref?: any
  instance_ref?: any
  qty?: any
  zone_ref?: any
  special_requests?: string
}

export interface Recipient {
  full_name: String
}

export const name = 'seats'

export const namespaced = true

export const types = {
  SET_SCREENS: 'SET_SCREENS',
  SET_ZONES: 'SET_ZONES',
  SET_CHOSEN_SCREEN: 'SET_CHOSEN_SCREEN',
  SET_AVAILABILITY: 'SET_AVAILABILITY',
  SET_SCREEN_AVAILABILITY: 'SET_SCREEN_AVAILABILITY',
  SET_BEST_PRICES: 'SET_BEST_PRICES',
  SET_SECTIONS: 'SET_SECTIONS',
  SET_PRICE_FILTERS: 'SET_PRICE_FILTERS',
  UPDATE_PRICE_FILTERS: 'UPDATE_PRICE_FILTERS',
  CLEAR_BEST_AVAILABLE_SELECTION: 'CLEAR_BEST_AVAILABLE_SELECTION',
  SET_BEST_AVAILABLE_SELECTION: 'SET_BEST_AVAILABLE_SELECTION',
  SET_RECIPIENT: 'SET_RECIPIENT',
  SET_RECIPIENTS: 'SET_RECIPIENTS',
  CLEAR_RECIPIENTS: 'CLEAR_RECIPIENTS',
  CLEAR_INSTANCE_RECIPIENTS: 'CLEAR_INSTANCE_RECIPIENTS',
  REMOVE_RECIPIENT: 'REMOVE_RECIPIENT',
}

export interface State {
  screens?: Array<Screen>
  zones?: Array<Zone>
  chosen_screen?: number
  screen_availability?: Array<Screen>
  available_seats: ?Array<Seat>
  best_prices: ?Array<ZonePrice>
  sections: Array<ScreenZone>
  price_filters?: Array<ZonePrice>
  best_available_selection: BestAvailableSelection[]
  recipients: { [key: string]: Recipient[] }
}

/**
 * Initial state
 */
export const state = (): State => ({
  screens: [],
  zones: [],
  chosen_screen: undefined,
  screen_availability: [],
  available_seats: [],
  best_prices: [],
  sections: [],
  price_filters: [],
  best_available_selection: [],
  recipients: {},
})

export const getters: GetterTree<State, RootState> = {
  /**
   * Get a list of seats by screen_ref
   */
  seats: (state: State) => (screen: number) => {
    const screen = state.screens.find((s) => s.screen_ref == screen)

    if (screen && screen.seats) {
      return screen.seats
    } else {
      return []
    }
  },
  /**
   * Get a list of available seats by screen ref
   */
  available: (state: State) => (screen: number) => {
    const seats = state.available_seats.filter(
      (s) => s.screen_ref == screen.screen_ref
    )
    return seats || []
  },

  uniquePrices: (state: State) => {
    if (state.best_prices) {
      return state.best_prices.reduce((unique, price) => {
        if (!unique.some((p) => price.price === p.price)) {
          unique.push(price)
        }
        return unique
      }, [])
    } else {
      return []
    }
  },

  getPricesByZoneRef: (state: State) => (zone_ref: number) => {
    let prices = []
    const zones = state.zones
    if (zones && zones.length) {
      zones.forEach((zone) => {
        if (zone.prices && zone.prices.length) {
          zone.prices.forEach((zonePrice) => {
            if (zonePrice.zone_ref === zone_ref) {
              prices.push(zonePrice)
            }
          })
        }
      })
    }
    return prices
  },
}

export const actions: ActionTree<State, RootState> = {
  /**
   * Get the seat map without availability or zones
   */
  async getSeatMap(
    context: ActionContext<State, RootState>,
    instance_ref: number
  ): Promise<any> {
    const response = await this.app.$apolloNonPersisted.query({
      query: seats['getSeatMap'],
      variables: {
        instance_ref: instance_ref,
      },
      context: {
        public: true,
      },
      fetchPolicy: 'network-only',
    })

    const { data } = response

    context.commit(types.SET_SCREENS, data.getSeatMap)

    return data.getSeatMap
  },

  /**
   * Get the seat map without availability
   */
  async getSeatMapZones(
    context: ActionContext<State, RootState>,
    instance_ref: number
  ): Promise<any> {
    const response = await this.app.$apolloNonPersisted.query({
      query: seats['getSeatMapZones'],
      variables: {
        instance_ref: instance_ref,
      },
      fetchPolicy: 'network-only',
    })

    const { data } = response

    context.commit(types.SET_ZONES, data.getSeatMap)
    context.commit(types.SET_BEST_PRICES, data.getSeatMap)

    return data.getSeatMap
  },

  /**
   * Get the seat availability,
   * only returns available seats, we match this against the map
   * to show available seats
   */
  async getSeatAvailability(
    context: ActionContext<State, RootState>,
    instance_ref: number
  ): Promise<Array<Seat>> {
    const response = await this.app.$apolloNonPersisted.query({
      query: seats['getSeatAvailability'],
      variables: {
        instance_ref: instance_ref,
        screens: [this.chosenScreen],
      },
      fetchPolicy: 'network-only',
    })

    const { data } = response

    const getSeatAvailability = data.getSeatAvailability
    const chosenScreen = context.rootState.seats.chosen_screen

    context.commit(types.SET_AVAILABILITY, getSeatAvailability)
    return data.getSeatAvailability
  },

  /**
   * Get a list of screens with availability and price range
   */
  async getScreenAvailability(
    context: ActionContext<State, RootState>,
    instance_ref: number
  ): Promise<Array<Screen>> {
    const response = await this.app.$apolloNonPersisted.query({
      query: seats['getScreensWithAvailability'],
      variables: {
        instance_ref: instance_ref,
      },
      fetchPolicy: 'network-only',
    })

    const { data } = response

    context.commit(types.SET_SCREEN_AVAILABILITY, data.getScreenAvailability)
    return data.getScreenAvailability
  },

  async getScreenZones(
    context: ActionContext<State, RootState>,
    { instance_ref, facility_ref }: any
  ): Promise<any> {
    const response = await this.app.$apolloNonPersisted.query({
      query: seats['getScreenZones'],
      variables: {
        instance_ref,
        facility_ref,
      },
      fetchPolicy: 'network-only',
    })

    const { data } = response

    context.commit(types.SET_SECTIONS, data.getScreenZones)
    return data.getScreenZones
  },
}

export const mutations: MutationTree<State> = {
  [types.SET_SCREENS](state: State, payload: any): void {
    state.screens = payload.screens
  },
  [types.SET_ZONES](state: State, payload: any): void {
    state.zones = payload.zones || []
  },
  [types.SET_CHOSEN_SCREEN](state: State, payload: number): void {
    state.chosen_screen = payload
  },
  [types.SET_AVAILABILITY](state: State, payload: any): void {
    state.available_seats = payload
  },
  [types.SET_SCREEN_AVAILABILITY](state: State, payload): void {
    state.screen_availability = payload
  },

  /**
   * Set the best price for each zone
   *
   * We are looping around each zone to build an array of best best_prices in each
   *
   * state.prices should be an Array of best ZonePrices
   */
  [types.SET_BEST_PRICES](state: State, payload): void {
    const prices: Array<ZonePrice> = []
    if (payload.zones && payload.zones.length) {
      payload.zones.forEach((zone) => {
        const best = zone.prices.find((price) => price.enabled && price.best)

        if (best) {
          prices.push(best)

          // if no best price is found then just use the first one as best
        } else if (zone.prices.length) {
          prices.push(zone.prices[0])
        }
      })
    }
    state.best_prices = prices
  },

  [types.SET_SECTIONS](state: State, payload: Array<ScreenZone>): void {
    state.sections = payload
  },
  [types.SET_PRICE_FILTERS](state: State, payload: any): void {
    state.price_filters = payload
  },
  [types.UPDATE_PRICE_FILTERS](state: State, payload: any): void {
    if (state.price_filters) {
      const index = state.price_filters.findIndex(
        (filter) => filter.price == payload.price
      )

      if (index == -1) {
        state.price_filters.push(payload)
      } else {
        state.price_filters.splice(index, 1)
      }
    }
  },
  [types.SET_BEST_AVAILABLE_SELECTION](state: State, payload: any) {
    if (state.best_available_selection) {
      let index
      if (payload.zone_ref) {
        index = state.best_available_selection.findIndex(
          (price) =>
            parseInt(price.price_type_ref) ===
              parseInt(payload.price_type_ref) &&
            parseInt(price.zone_ref) === parseInt(payload.zone_ref)
        )
      } else {
        index = state.best_available_selection.findIndex(
          (price) =>
            parseInt(price.price_type_ref) === parseInt(payload.price_type_ref)
        )
      }
      console.log('index', index)
      if (index !== -1) {
        if (payload.qty === 0 || isNaN(payload.price_type_ref)) {
          state.best_available_selection.splice(index, 1)
        } else {
          state.best_available_selection[index] = payload
        }
      } else if (payload.qty > 0 && !isNaN(payload.price_type_ref)) {
        state.best_available_selection.push(payload)
      }
    }
  },
  [types.CLEAR_BEST_AVAILABLE_SELECTION](state: State) {
    state.best_available_selection = []
  },
  [types.CLEAR_RECIPIENTS](state: State) {
    state.recipients = {}
  },
  [types.CLEAR_INSTANCE_RECIPIENTS](state: State, payload: string) {
    state.recipients[payload] = []
  },
  [types.SET_RECIPIENTS](state: State, payload: any) {
    state.recipients = payload
  },
  [types.SET_RECIPIENT](state: State, payload: any) {
    if (payload && state.recipients) {
      if (!state.recipients[payload.instance_ref]) {
        Vue.set(state.recipients, payload.instance_ref, [])
      }
      const index = state.recipients[payload.instance_ref].findIndex(
        (r) => r.email && r.email === payload.recipient.email
      )

      if (index === -1) {
        state.recipients[payload.instance_ref].push(payload)
      }
    }
  },
  [types.REMOVE_RECIPIENT](state: State, payload: Recipient) {
    state.recipients.splice(
      state.recipients.findIndex((r) => r.email && r.email === payload.email),
      1
    )
  },
}
