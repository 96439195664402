
















































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import InfoSVG from 'material-design-icons/action/svg/production/ic_info_outline_24px.svg'

export interface InfoItem {
  message: string
}

@Component({
  components: {
    InfoSVG,
  },
})
export default class RotatingInfo extends Vue {
  @Prop({ type: Array, default: () => [] }) items?: InfoItem[]

  private current: number | null = null
  private height: number = 0
  private containerHeight: number | null = null
  private loading: boolean = true

  get itemStyle(): string {
    let style = ''
    if (!this.loading) {
      if (this.activePositionTranslate) {
        style += `transform: translateY(-${this.activePositionTranslate}%)`
      } else {
        style += `transform: translateY(0%)`
      }
    }

    return style
  }

  get navItemHeight(): string {
    let height = 100
    if (!this.loading && this.items.length > 1) {
      height = height / this.items.length
    }
    return `height: ${height}%`
  }

  get activePositionTranslate(): number {
    if (this.items.length > 1) {
      return this.current * 100
    }
  }

  get itemHeights(): number {
    let height = this.height

    this.$refs.item.forEach((item) => {
      if (item.clientHeight > height) {
        height = item.clientHeight
      }
    })

    return height
  }

  get getContainerHeight(): number {
    return this.height * this.items.length
  }

  scrollToActiveItem() {
    this.$refs.container.scrollTop = this.current * this.height
  }

  onClickDismiss(): void {
    if (this.current !== 0) {
      this.current--
    } else {
      this.current = 1
    }

    this.$emit('dismiss-item', this.current)
  }

  onClickReview(): void {
    this.$emit('review-item', this.current)
  }

  goToItem(item: number): void {
    if (this.items[item]) {
      this.current = item
      this.scrollToActiveItem()
    }
  }

  getScrollHeight(): void {
    if (this.$refs.container.scrollTop % this.height === 0) {
      this.current = this.$refs.container.scrollTop / this.height
      this.scrollToActiveItem()
    }
  }

  mounted(): void {
    this.current = 0

    this.$nextTick(() => {
      this.height = this.itemHeights
      this.$refs.item.forEach((item) => (item.style.height = '100%'))
      this.$refs.container.addEventListener('scroll', this.getScrollHeight)
    })

    this.loading = false
  }

  beforeDestroy(): void {
    this.$refs.container.removeEventListener('scroll', this.getScrollHeight)
  }
}
