import Vue from 'vue'
import { ActionTree, MutationTree, ActionContext, GetterTree } from 'vuex'
import { groupBy, flatten } from 'lodash'
import { RootState } from './types'
import * as instance from '~/api/queries/instance.gql'

import { Instance, Event } from '~/@types/skyway'

export const name = 'instance'

/**
 * Interface for app state, transform properties for convienience
 */
export type InstanceTransformed<T> = Partial<T> & {
  min_age: number
  max_age: number
}

export const namespaced = true

export const types = {
  SET_INSTANCE: 'SET_INSTANCE',
  SET_INSTANCES: 'SET_INSTANCES',
  UPDATE_INSTANCES: 'UPDATE_INSTANCES',
  SET_INSTANCE_AVAILABILITY: 'SET_INSTANCE_AVAILABILITY',
  SET_INSTANCES_AVAILABILITY: 'SET_INSTANCES_AVAILABILITY',
}

export type ViewOptions = 'list' | 'grid'

export interface State {
  instance?: Instance
  instances: Array<Instance>
}

/**
 * Initial state, empty array of events
 */
export const state = (): State => ({
  instance: undefined,
  instances: [],
})

export const getters: GetterTree<State, RootState> = {
  instance: (state: State): Instance | undefined => {
    return state.instance
  },

  instances: (state: State): Array<Instance> | undefined => {
    return state.instances
  },

  instancesGroupedByDate:
    (state: State) =>
    (types: string[]): any => {
      const data = types.length
        ? state.instances.filter(
            (instance) =>
              instance.event &&
              instance.event.type &&
              types.includes(instance.event.type) &&
              !instance.packaged
          )
        : state.instances
      return groupBy(
        data.map((instance) => {
          return {
            ...instance,
            date: instance.date_time.substring(
              0,
              instance.date_time.indexOf('T')
            ),
          }
        }),
        'date'
      )
    },

  sections: (state: State): Array<Section> | undefined => {
    return state.sections
  },
}

export const fetchInstancesByDateRange = async (
  client,
  range,
  context,
  query = 'getInstancesByDateRange'
) => {
  return await client.query({
    query: instance[query],
    variables: {
      range,
    },
    fetchPolicy: 'network-only',
  })
}

export const actions: ActionTree<State, RootState> = {
  async getInstance(
    context: ActionContext<State, RootState>,
    id: number
  ): Promise<any> {
    const response = await this.app.$apollo.query({
      query: instance.getInstance,
      variables: {
        id,
      },
      context: {
        public: false,
      },
      fetchPolicy: 'network-only',
    })

    const { data } = response

    context.commit(types.SET_INSTANCE, data.instance)

    return data.instance
  },

  async getInstanceByRef(
    context: ActionContext<State, RootState>,
    instance_ref: number
  ): Promise<any> {
    const response = await this.app.$apolloNonPersisted.query({
      query: instance.getInstanceByRef,
      variables: {
        instance_ref,
      },
      fetchPolicy: 'network-only',
      extensions: {
        persistedQuery: {
          version: 12345,
        },
      },
    })

    const { data } = response

    context.commit(types.SET_INSTANCE, data.getInstanceByRef)
    return data.getInstanceByRef
  },

  async getInstancesByEventId(
    context: ActionContext<State, RootState>,
    event_id: number
  ): Promise<any> {
    const response = await this.app.$apollo.query({
      query: instance.GetIntancesByEvent,
      variables: {
        event_id,
      },
    })

    const { data } = response

    context.commit(types.SET_INSTANCES, data.getInstancesByEventId)
  },

  async getInstanceAvailability(
    context: ActionContext<State, RootState>,
    id: number
  ): Promise<any> {
    const response = await this.app.$apollo.query({
      query: instance.getInstanceAvailability,
      variables: {
        id,
      },
      fetchPolicy: 'network-only',
    })

    const { data } = response

    context.commit(types.SET_INSTANCE_AVAILABILITY, data.instance)

    return data.instance
  },

  async getInstancePriceTypesByRef(
    context: ActionContext<State, RootState>,
    instance_ref: number
  ): Promise<any> {
    const response = await this.app.$apollo.query({
      query: instance.getInstancePriceTypesByRef,
      variables: {
        instance_ref,
      },
      fetchPolicy: 'network-only',
    })

    const { data } = response

    return data.getInstanceByRef
  },

  async getInstances(
    context: ActionContext<State, RootState>
  ): Promise<Array<Instance>> {
    const client = this.app.$apollo

    const response = await client.query({
      query: instance.getInstances,
    })

    const { data } = response

    context.commit(types.SET_INSTANCES, data.getInstances)

    return data.getInstances
  },

  async getInstancesByDateRange(
    context: ActionContext<State, RootState>,
    range: { from: string; to: string }
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await fetchInstancesByDateRange(client, range, context)

    const { data } = response

    context.commit(types.SET_INSTANCES, data.getInstancesByDateRange)

    return data.getInstancesByDateRange
  },

  async loadMoreInstancesByDateRange(
    context: ActionContext<State, RootState>,
    range: { from: string; to: string }
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await fetchInstancesByDateRange(
      client,
      range,
      context,
      'getInstancesByDateRangeBrief'
    )

    const { data } = response

    context.commit(types.UPDATE_INSTANCES, data.getInstancesByDateRange)

    return data.getInstancesByDateRange
  },

  async getInstancesByDateRangeBrief(
    context: ActionContext<State, RootState>,
    range: { from: string; to: string }
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted

    const response = await client.query({
      query: instance.getInstancesByDateRangeBrief,
      variables: {
        range,
        brief: true,
      },
      fetchPolicy: 'network-only',
    })

    const { data } = response

    context.commit(types.SET_INSTANCES, data.getInstancesByDateRange)

    return data.getInstancesByDateRange
  },

  async getAllInstanceDatesByType(
    context: ActionContext<State, RootState>,
    type: string
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.query({
      query: instance.getAllInstanceDatesByType,
      variables: {
        type,
      },
    })

    const { data } = response

    return flatten(data.getEvents.map((e) => e.instances))
  },

  async getInstancesAvailabilityByDateRange(
    context: ActionContext<State, RootState>,
    range: { from: string; to: string }
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.query({
      query: instance.getInstancesAvailabilityByDateRange,
      variables: {
        range,
      },
      fetchPolicy: 'network-only',
    })

    const { data } = response

    if (data && data.getInstancesByDateRange) {
      data.getInstancesByDateRange.forEach((instance: Instance) => {
        context.commit(types.SET_INSTANCES_AVAILABILITY, instance)
      })

      return data.getInstancesByDateRange
    } else {
      return false
    }
  },
}

const getAges = (event: Event): { min_age: number; max_age: number } => {
  const range = event.production_title
    ? event.production_title.replace('Age ', '')
    : '0-18'
  let output = {
    min_age: range.includes('-')
      ? parseInt(range.split('-')[0])
      : parseInt(range),
    max_age: range.includes('-')
      ? parseInt(range.split('-')[1])
      : parseInt(range),
  }
  if (
    (isNaN(output.min_age) && isNaN(output.max_age)) ||
    (event.extra &&
      event.extra.minimum_age !== null &&
      event.extra.maximum_age !== null)
  ) {
    output = {
      min_age: event.extra.minimum_age ? event.extra.minimum_age : 0,
      max_age: event.extra.maximum_age ? event.extra.maximum_age : 18,
    }
  }

  return output
}

const transformInstance = (
  instance: Instance
): InstanceTransformed<Instance> => {
  const range = instance.event
    ? getAges(instance.event)
    : { min_age: 0, max_age: 18 }
  return {
    ...instance,
    ...range,
  }
}

export const mutations: MutationTree<State> = {
  [types.SET_INSTANCE](state: State, payload: Instance): void {
    state.instance = payload
  },

  /**
   * Mutate min_age and max_age from the production_title on to the instance object
   * to save data gymnastics within components
   */
  [types.SET_INSTANCES](state: State, payload: Instance[]): void {
    state.instances = payload.map(
      (instance: Instance): InstanceTransformed<Instance> => {
        return transformInstance(instance)
      }
    )
  },

  [types.UPDATE_INSTANCES](state: State, payload: Instance[]): void {
    for (const i of payload) {
      if (
        !state.instances.find((inst) => inst.instance_ref === i.instance_ref)
      ) {
        state.instances.push(transformInstance(i))
      }
    }
  },

  [types.SET_INSTANCES_AVAILABILITY](state: State, payload: Instance): void {
    if (state.instances) {
      const index = state.instances.findIndex(
        (instance: Instance) => instance.instance_ref == payload.instance_ref
      )
      if (index !== -1) {
        Vue.set(
          state.instances,
          index,
          Object.assign({}, state.instances[index], payload)
        )
      }
    }
  },

  [types.SET_INSTANCE_AVAILABILITY](state: State, payload: Instance): void {
    if (state.instance && state.instance.instance_ref == payload.instance_ref) {
      Vue.set(state.instance, 'total_availability', payload.total_availability)
      Vue.set(state.instance, 'availability', payload.availability)
    }
  },
}
