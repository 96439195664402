import _flatten from 'lodash/flatten'

export const gtmProductsTransformer = (product_groups) => {
  const products = product_groups.map((group) => {
    const category = group.name
    return group.items.map((item) => {
      return {
        name: item.name,
        id: item.item_ref,
        price: item.unit_price,
        category: category,
        quantity: item.qty,
      }
    })
  })

  return _flatten(products)
}
