// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".text--widen{letter-spacing:.15px}.skip-to-content{position:absolute;top:0;left:0;width:100%;height:0;z-index:9999;overflow:visible;background-color:#fff}.skip-to-content ul{list-style:none}.skip-to-content a{margin:0 auto;position:absolute;width:80%;height:auto;text-align:center;display:block;padding:24px;transform:translateX(-100%);z-index:9999}.skip-to-content a:focus{transform:translateY(0)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"price1": "#d3048e",
	"price2": "#936d22",
	"price3": "#617e26",
	"price4": "#196e19",
	"price5": "#056868",
	"price6": "#5252b6",
	"price7": "#8d0dcd",
	"price8": "#bc2e2d"
};
module.exports = ___CSS_LOADER_EXPORT___;
