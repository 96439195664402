import { ActionTree, MutationTree, ActionContext } from 'vuex'
import { findKey } from 'lodash'
import { RootState } from './types'
import * as csi from '~/api/queries/csi.gql'

import {
  Customer,
  CreateCustomerServiceIssueInput,
  CreateCustomerServiceActionInput,
} from '~/@types/skyway'

export const name = 'csi'

export const namespaced = true

export const types = {
  SET_CSI: 'SET_CSI',
  CLEAR_CSI: 'CLEAR_CSI',
  CLEAR_ALL: 'CLEAR_ALL',
  UPDATE_ALL: 'UPDATE_ALL',
  ADD_CUSTOMER: 'ADD_CUSTOMER',
}

export interface State {
  csis: Array<CreateCustomerServiceIssueInput>
}

export const state = (): State => ({
  csis: [],
})

export const actions: ActionTree<State, RootState> = {
  async processCsi(
    context: ActionContext<State, RootState>,
    createCustomerServiceIssueInput: CreateCustomerServiceIssueInput & {
      created_at?: string
    }
  ): Promise<any> {
    if (createCustomerServiceIssueInput.activity_type_ref) {
      if (createCustomerServiceIssueInput.created_at) {
        if (
          this.app
            .$moment(createCustomerServiceIssueInput.created_at)
            .isBefore(this.app.$moment().subtract(30, 'minutes'))
        ) {
          console.log('[CSI NOT SENT]', {
            timestamp: createCustomerServiceIssueInput.created_at,
            createCustomerServiceIssueInput,
          })
          context.commit(types.CLEAR_CSI, createCustomerServiceIssueInput)
          return false
        } else {
          console.warn('[CSI WAS SENT]', {
            timestamp: createCustomerServiceIssueInput.created_at,
            createCustomerServiceIssueInput,
          })
          delete createCustomerServiceIssueInput.created_at

          const response = await this.app.$apollo.mutate({
            mutation: csi.createCustomerServiceIssue,
            variables: {
              createCustomerServiceIssueInput,
            },
          })
          // remove CSI from store once it has been processed
          context.commit(types.CLEAR_CSI, createCustomerServiceIssueInput)

          const { data } = response

          return data.createCustomerServiceIssue
        }
      } else {
        const response = await this.app.$apollo.mutate({
          mutation: csi.createCustomerServiceIssue,
          variables: {
            createCustomerServiceIssueInput,
          },
        })
        // remove CSI from store once it has been processed
        context.commit(types.CLEAR_CSI, createCustomerServiceIssueInput)

        const { data } = response

        return data.createCustomerServiceIssue
      }

      return false
    }
  },

  async updateCsi(
    context: ActionContext<State, RootState>,
    updateCustomerServiceIssueInput: UpdateCustomerServiceIssueInput
  ): Promise<any> {
    const response = await this.app.$apollo.mutate({
      mutation: csi.updateCustomerServiceIssue,
      variables: {
        updateCustomerServiceIssueInput,
      },
    })

    const { data } = response

    return data.updateCustomerServiceIssue
  },

  async createCsiAction(
    context: ActionContext<State, RootState>,
    createCustomerServiceActionInput: CreateCustomerServiceActionInput
  ): Promise<any> {
    const response = await this.app.$apollo.mutate({
      mutation: csi.createCustomerServiceAction,
      variables: {
        createCustomerServiceActionInput,
      },
    })

    const { data } = response

    return data.createCustomerServiceAction
  },

  async deleteCsiByActivityType(
    context: ActionContext<State, RootState>,
    { activity_type_ref, customer_ref }
  ): Promise<any> {
    const response = await this.app.$apollo.mutate({
      mutation: csi.deleteCustomerServiceIssueByActivityType,
      variables: {
        activity_type_ref,
        customer_ref,
      },
    })

    const { data } = response

    return data.deleteCustomerServiceIssueByActivityType
  },

  async getActivityTypes(
    context: ActionContext<State, RootState>
  ): Promise<[]> {
    const response = await this.app.$apollo.query({
      query: csi.getActivityTypes,
    })

    const { data } = response

    // this query is actually a call to the getReferenceData endpoint,
    // so this looks wrong, but it isn't!
    return data.getReferenceData && data.getReferenceData
      ? data.getReferenceData.response
      : []
  },

  async setCsi(
    context: ActionContext<State, RootState>,
    input: CreateCustomerServiceIssueInput
  ) {
    return await new Promise((resolve) => {
      const created_at = this.app.$moment().format()

      const data = { ...input, created_at }

      console.log(
        '[CSI STORED TO BE PROCESSED AT CONFIRMATION]',
        { timestamp: created_at },
        { data }
      )

      context.commit(types.SET_CSI, data)

      resolve(true)
    })
  },
}

export const mutations: MutationTree<State> = {
  /**
   * Push a CSI onto the stack to be processed later
   */
  [types.SET_CSI](
    state: State,
    payload: CreateCustomerServiceIssueInput
  ): void {
    state.csis.push(payload)

    state.csis = state.csis.filter((c) => c)
  },

  /**
   * Append info to all CSI notes in the stack
   * Payload should be formatted with newlines
   * after each piece of information
   */
  [types.UPDATE_ALL](state: State, payload: string): void {
    for (const c of state.csis) {
      if (c && c.note) {
        c.note += payload
      }
    }
  },

  /**
   * Triggered when a customer logs in, we loop through CSIs
   * and add the customer_ref to any CSIS that are in local
   * storage prior to checkout
   */
  [types.ADD_CUSTOMER](state: State, payload: Customer): void {
    for (const c of state.csis) {
      if (c && !c.customer_ref) {
        c.customer_ref = payload.customer_ref
      }
    }
  },

  [types.CLEAR_CSI](
    state: State,
    payload: CreateCustomerServiceIssueInput
  ): void {
    const exists = findKey(state.csis, (c: CreateCustomerServiceIssueInput) => {
      return (
        c.activity_type_ref == payload.activity_type_ref &&
        (!c.instance_ref || c.instance_ref == payload.instance_ref)
      )
    })
    if (exists && state.csis[exists]) {
      state.csis = [
        ...state.csis.slice(0, exists),
        ...state.csis.slice(exists + 1),
      ]
    }
  },

  [types.CLEAR_ALL](state: State): void {
    state.csis.splice(0, state.csis.length)
  },
}
