





























import { Component, Prop, mixins } from 'nuxt-property-decorator'
import Field from './Field.ts'

@Component
export default class TextareaField extends mixins(Field) {}
