import VueGtm from '@gtm-support/vue2-gtm'
import Vue from 'vue'
import { config } from '~/config'

export default ({ app, route }, inject) => {
  Vue.use(VueGtm, {
    id: [config.GOOGLE_TAG_MANAGER || 'GTM-MBJ765B'],
    enabled: true,
    debug: true,
    loadScript: true,
    vueRouter: app.router,
  })

  const gtm = Vue.gtm

  inject('gtm', gtm)
}
