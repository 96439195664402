
























import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class MegaMenu extends Vue {
  @Prop({ type: Array, required: true }) links!: Array<any>
  @Prop({ type: String }) modifier?: string

  get classModifier(): string | boolean {
    if (this.modifier) {
      return `mega-menu--${this.modifier}`
    } else {
      return false
    }
  }
}
