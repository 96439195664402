import { ActionTree, MutationTree, GetterTree, ActionContext } from 'vuex'

export const name = 'series'

import * as series from '~/api/queries/series.gql'
import { RootState } from './types'
import { Series } from '~/@types/skyway'

export const namespaced = true

export const types = {
  SET_SERIES: 'SET_SERIES'
}

export interface State {
  series?: Array<Series>
}

/**
 * Initial state, empty array of events
 */
export const state = (): State => ({
  series: []
})

export const getters: GetterTree<State, RootState> = {}

export const actions: ActionTree<State, RootState> = {
  async getSeries(context: ActionContext<State, RootState>): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: series['getSeries'],
      context: {
        public: true
      }
    })

    const { data } = response

    context.commit(types.SET_SERIES, data.getSeries)
  }
}

export const mutations: MutationTree<State> = {
  [types.SET_SERIES](state: State, payload: Series[]): void {
    state.series = payload
  }
}
