































































import { Component, Vue, Prop } from 'nuxt-property-decorator'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'
import MenuCloseSvg from '~/static/images/sprites/close.svg'
import FocusLock from 'vue-focus-lock'

@Component({
  components: {
    MenuCloseSvg,
    FocusLock,
  },
})
export default class Modal extends Vue {
  @Prop({ type: Boolean, default: true }) showClose?: boolean
  isOpen: boolean = false

  open(callback?: (args?: any) => any): void {
    this.isOpen = true

    disableBodyScroll(this.$refs.modal, {
      allowTouchMove: (el): any => {
        while (el && el !== document.body) {
          if (document.querySelector('.modal__content')?.contains(el)) {
            return true
          }

          el = el.parentElement
        }
      },
    })

    this.$emit('opened')

    if (typeof callback == 'function') {
      callback()
    }
  }

  close(): void {
    this.isOpen = false
    enableBodyScroll(this.$el)
    this.$emit('closed')
  }

  mounted() {
    this.$eventBus.on('modal:close', this.close)
  }

  beforeDestroy() {
    this.$eventBus.off('modal:close', this.close)
    clearAllBodyScrollLocks()
  }
}
