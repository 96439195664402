import { Vue } from 'nuxt-property-decorator'
import { ActionTree, MutationTree, ActionContext, GetterTree } from 'vuex'

import { getEvents } from '~/static/mocks/getEvents'

import { RootState } from './types'
import * as events from '~/api/queries/events.gql'
import { groupBy } from 'lodash'

import { Event } from '~/@types/skyway'

export const name = 'events'

export const namespaced = true

export const types = {
  SET_EVENTS: 'SET_EVENTS',
  SET_EVENT: 'SET_EVENT',
  SET_EVENT_AVAILABILITY: 'SET_EVENT_AVAILABILITY',
  SET_EVENTS_VIEW: 'SET_EVENTS_VIEW',
  SET_EVENT_PRICES: 'SET_EVENT_PRICES',
  SET_SELECTED_DATE: 'SET_SELECTED_DATE',
  SET_CALENDAR: 'SET_CALENDAR',
}

export type ViewOptions = 'list' | 'grid'

export interface State {
  event?: Event
  events: Array<Event>
  view: ViewOptions
  selectedDate?: any
  calendar?: Array<any>
}

/**
 * Initial state, empty array of events
 */
export const state = (context): State => ({
  event: undefined,
  events: [],
  view: 'grid',
  selectedDate: undefined,
  calendar: [],
})

export const getters: GetterTree<State, RootState> = {
  chosenView(state: State): ViewOptions {
    return state.view
  },
  /**
   * Convenience method to pull a single event from our state
   */
  eventById:
    (state: State) =>
    (id: number | string): Event | undefined => {
      return state.events.find((event) => event.id == id)
    },

  event: (state: State): Event | undefined => {
    return state.event
  },

  artistsByType: (state: State): ArtistType<Array<Artist>> => {
    return state.event ? groupBy(state.event.artists, 'type.role') : []
  },

  eventsByType:
    (state: State) =>
    (type: string): Array<Event> => {
      return state.events
        ? state.events.filter((event) => event.type == type)
        : []
    },

  /**
   * Return mock data
   */
  relatedEvents: (state: State): Array<Event> => {
    let related = state.event && state.event.related ? state.event.related : []

    if (related.length < 4) {
      const auto_related = getEvents.slice(0, 4 - related.length)
      related = [...related, ...auto_related]
    }

    return related
  },
}

export const actions: ActionTree<State, RootState> = {
  async getEvents(context: ActionContext<State, RootState>): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: events['getEvents'],
      context: {
        public: true,
      },
    })

    const { data } = response

    context.commit(types.SET_EVENTS, data.getEvents)

    return data.getEvents
  },

  async getEventsByDate(
    context: ActionContext<State, RootState>,
    range: { from: string; to: string }
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: events['getEventsByDate'],
      variables: {
        range,
      },
      context: {
        public: true,
      },
    })

    const { data } = response

    return data.getEvents
  },

  async getEvent(
    context: ActionContext<State, RootState>,
    id: number,
    fetchPolicy: string = 'cache-first'
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: events['getEvent'],
      variables: {
        id,
      },
      context: {
        public: true,
      },
      fetchPolicy,
    })

    const { data } = response

    context.commit(types.SET_EVENT, data.event)
    return data.event
  },

  async getEventDetailsByRef(
    context: ActionContext<State, RootState>,
    id: string
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: events['getEventDetailsByRef'],
      variables: {
        id,
      },
      context: {
        public: true,
      },
      fetchPolicy: 'network-only',
    })

    const { data } = response

    context.commit(types.SET_EVENT, data.getEventByRef)
    return data.getEventByRef
  },

  async getEventByRef(
    context: ActionContext<State, RootState>,
    id: number,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: events['getEventByRef'],
      variables: {
        id,
      },
      context: {
        public: true,
      },
      fetchPolicy,
    })

    const { data } = response

    context.commit(types.SET_EVENT, data.getEventByRef)
    return data.getEventByRef
  },

  async getEventWithAvailability(
    context: ActionContext<State, RootState>,
    id: number,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: events['getEventWithAvailability'],
      variables: {
        id,
      },
      fetchPolicy,
    })

    const { data } = response

    context.commit(types.SET_EVENT_AVAILABILITY, data.getEventByRef)
    return data.getEventByRef
  },

  async getEventPricesByRef(
    context: ActionContext<State, RootState>,
    id: number,
    fetchPolicy: string = 'cache-first'
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: events['getEventPricesByRef'],
      variables: {
        id,
      },
      fetchPolicy,
    })

    const { data } = response

    context.commit(types.SET_EVENT_PRICES, data.getEventByRef)
    return data.getEventByRef
  },

  async getEventBySlug(
    context: ActionContext<State, RootState>,
    slug: string,
    fetchPolicy: string = 'cache-first'
  ): Promise<any> {
    const preview =
      this.app.context.route.query != undefined &&
      this.app.context.route.query.preview != undefined
        ? true
        : false

    const client = this.app.$apollo
    const response = await client.query({
      query: events['getEventBySlug'],
      variables: {
        slug,
      },
      context: {
        public: true,
      },
      fetchPolicy,
    })

    const { data } = response

    context.commit(types.SET_EVENT, data.getEventBySlug)
    return data.getEventBySlug
  },

  async getTimedEventByRef(
    context: ActionContext<State, RootState>,
    id: number,
    fetchPolicy: string = 'cache-first'
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: events['getTimedEventByRef'],
      variables: {
        id,
      },
      context: {
        public: true,
      },
      fetchPolicy,
    })

    const { data } = response

    context.commit(types.SET_EVENT, data.getEventByRef)
    return data.getEventByRef
  },

  async getCalendarMonth(
    context: ActionContext<State, RootState>
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: events['getCalendarMonth'],
      variables: {
        year: this.app.$moment(context.state.selectedDate).year(),
        month: this.app.$moment(context.state.selectedDate).month(),
      },
    })

    const { data } = response

    context.commit(types.SET_CALENDAR, data.calendarMonth)
  },

  updateView(
    context: ActionContext<State, RootState>,
    view: ViewOptions
  ): void {
    context.commit(types.SET_EVENTS_VIEW, view)
  },
}

export const mutations: MutationTree<State> = {
  [types.SET_EVENTS](state: State, payload: Event[]): void {
    state.events = payload
  },
  [types.SET_EVENTS_VIEW](state: State, payload: ViewOptions): void {
    state.view = payload
  },
  [types.SET_EVENT](state: State, payload: Event): void {
    state.event = payload
  },
  [types.SET_EVENT_PRICES](state: State, payload: Event): void {
    if (state.event && payload.instances) {
      const instances = []
      for (const k in payload.instances) {
        const prices = payload.instances[k]
        let instance = state.event.instances?.find(
          (i) => i?.instance_ref === prices?.instance_ref
        )
        instance = Object.assign({}, instance, prices)
        instances.push(instance)
      }
      state.event.instances = instances
    }
  },
  [types.SET_EVENT_AVAILABILITY](state: State, payload: Event): void {
    if (state.event) {
      for (const i in payload.instances) {
        const inStore = state.event.instances?.find(
          (inst) =>
            inst &&
            payload.instances &&
            inst.instance_ref === payload.instances[i].instance_ref
        )

        if (inStore && state.event.instances) {
          Vue.set(
            state.event.instances,
            i,
            Object.assign(inStore, payload.instances[i])
          )
        }
      }
    } else {
      state.event = payload
    }
  },
  [types.SET_SELECTED_DATE](state: State, payload: any): void {
    state.selectedDate = payload
  },
  // [types.SET_CALENDAR](state: State, payload: any): void {
  //   state.calendar = payload
  // }
  [types.SET_CALENDAR](state: State, payload: any): void {
    if (state.calendar == undefined) state.calendar = []
    state.calendar[this.app.$moment(state.selectedDate).month()] =
      payload.filter((item) => {
        return (
          item.date >= this.app.$moment().format('D') ||
          this.app.$moment().format('M') <
            this.app.$moment(state.selectedDate).month() + 1 ||
          this.app.$moment().isBefore(this.app.$moment(state.selectedDate))
        )
      })
  },
}
