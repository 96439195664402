import Vue from 'vue'
import { ActionTree, MutationTree, ActionContext, GetterTree } from 'vuex'
import * as customer from '~/api/queries/customer.gql'
import { RootState } from './types'
import {
  Order,
  Customer,
  CustomerChild,
  CustomerPhoneInput,
  CustomerAddressInput,
  TokenSet,
  UpdatePasswordInput,
  GiftAidDeclarationInput,
  CustomerConstituencyInput,
  CustomerAttributeInput,
  CustomerContactPreferenceInput,
  CustomerInterestInput,
  CustomerInput,
  AffiliationInput,
  AssociationInput,
  AccountInput,
  CustomerResearchInput,
  MembershipInstance,
  CustomerContribution,
} from '~/@types/skyway'
import {
  formatChildAssociation,
  formatChildResearch,
  formatChildIssues,
  formatChildAttributes,
} from '~/components/account/helpers/childFormatter'
import { childFormSettings } from '~/components/account/forms/childForm'

export const name = 'customer'

export const namespaced = true

export const types = {
  SET_KNOWN_USERS: 'SET_KNOWN_USERS',
  SET_LOGGED_IN: 'SET_LOGGED_IN',
  SET_TOKEN: 'SET_TOKEN',
  SET_CUSTOMER: 'SET_CUSTOMER',
  SET_ADULTS: 'SET_ADULTS',
  SET_CHILDREN: 'SET_CHILDREN',
  SET_PRIMARY_MEMBERS: 'SET_PRIMARY_MEMBERS',
  SET_TICKET_HISTORY: 'SET_TICKET_HISTORY',
  SET_ORDER_HISTORY: 'SET_ORDER_HISTORY',
  SET_LATEST_ORDER_ID: 'SET_LATEST_ORDER_ID',
  SET_MEMBERSHIP_HISTORY: 'SET_MEMBERSHIP_HISTORY',
  SET_CONTRIBUTION_HISTORY: 'SET_CONTRIBUTION_HISTORY',
  SET_GIFT_MEMBERSHIPS: 'SET_GIFT_MEMBERSHIPS',
  SET_GIFT_SPONSORSHIPS: 'SET_GIFT_SPONSORSHIPS',
  SET_ISSUES: 'SET_GIFT_SPONSORSHIPS',
  SET_COUNTRIES: 'SET_COUNTRIES',
  SET_STATES: 'SET_STATES',
  TRANSFER_SESSION_FAILED: 'TRANSFER_SESSION_FAILED',
  LOGOUT: 'LOGOUT',
  SET_TEMPORARY_EMAIL: 'SET_TEMPORARY_EMAIL',
  CLEAR_TEMPORARY_EMAIL: 'CLEAR_TEMPORARY_EMAIL',
  SET_CUSTOMER_CONTACT_DETAILS: 'SET_CUSTOMER_CONTACT_DETAILS',
  SET_CUSTOMER_INTERESTS: 'SET_CUSTOMER_INTERESTS',
  SET_CUSTOMER_ATTRIBUTES: 'SET_CUSTOMER_ATTRIBUTES',
  SET_CUSTOMER_CONSTITUENCIES: 'SET_CUSTOMER_CONSTITUENCIES',
  SET_CUSTOMER_BALANCES: 'SET_CUSTOMER_BALANCES',
  SET_CUSTOMER_MEMBERSHIPS: 'SET_CUSTOMER_MEMBERSHIPS',
  SET_CUSTOMER_CONTRIBUTIONS: 'SET_CUSTOMER_CONTRIBUTIONS',
  SET_CUSTOMER_GIFT_AID: 'SET_CUSTOMER_GIFT_AID',
  SET_CUSTOMER_CONTACT_PREFERENCES: 'SET_CUSTOMER_CONTACT_PREFERENCES',
  ADD_ADDRESS: 'ADD_ADDRESS',
  UPDATE_ADDRESS: 'UPDATE_ADDRESS',
  DELETE_ADDRESS: 'DELETE_ADDRESS',
  UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
  UPDATE_ADULT: 'UPDATE_ADULT',
  SET_COUPONS: 'SET_COUPONS',
}

export interface State {
  logged_in?: boolean
  token?: string
  known_users: Array<string>
  customer?: any
  adults?: Array<Customer>
  children?: Array<CustomerChild>
  issues?: Array<CustomerServiceIssue>
  primary_members?: Array<Customer>
  temporary_email?: string
  membership_history?: Array<MembershipInstance>
  coupons?: Array<string>
  contribution_history?: Array<CustomerContribution>
  latest_order_id: Number
  gifts?: object
  states?: Array<any>
  countries?: Array<any>
}

/**
 *
 */
export const state = (): State => ({
  logged_in: false,
  token: undefined,
  known_users: [],
  customer: undefined,
  adults: undefined,
  children: undefined,
  issues: undefined,
  primary_members: undefined,
  temporary_email: undefined,
  membership_history: [],
  coupons: [],
  contribution_history: [],
  latest_order_id: 0,
  gifts: {
    memberships: [],
    sponsorships: [],
  },
  states: [],
  countries: [],
})

export const getters: GetterTree<State, RootState> = {
  token: (state: State): string | undefined => {
    return state.token
  },

  isLoggedIn: (state: State): boolean => {
    return state.logged_in === true
  },
  displayName: (state: State): string | undefined | null => {
    if (state.customer) {
      return state.customer.first_name
        ? state.customer.first_name
        : state.customer.display_name
    } else {
      return ''
    }
  },
  customer: (state: State): Customer | undefined => {
    return state.customer
  },
  adults: (state: State): Array<Customer> | undefined => {
    return state.adults ? state.adults : undefined
  },
  children: (state: State): Array<CustomerChild> | undefined => {
    return state.children ? state.children : []
  },
  updatedAt: (state: State): string | undefined => {
    return state.customer ? state.customer.updated_at : undefined
  },
  isMember: (state: State): boolean => {
    return state.customer ? state.customer.memberships.length > 0 : false
  },
  contribution_history: (state: State): Array<CustomerContribution> => {
    return state.contribution_history ? state.contribution_history : []
  },
  orders: (state: State): [] => {
    return state.customer && state.customer.orders ? state.customer.orders : []
  },
  tickets: (state: State): [] => {
    return state.customer && state.customer.tickets
      ? state.customer.tickets
      : []
  },
  addresses: (state: State): [] => {
    return state.customer && state.customer.addresses
      ? state.customer.addresses
      : []
  },
  billingAddress: (state: State): [] => {
    return state.customer &&
      state.customer.addresses &&
      state.customer.addresses.find((address) => address.primary)
      ? state.customer.addresses.find((address) => address.primary)
      : state.customer.addresses
      ? state.customer.addresses[0]
      : null
  },
  membershipsByOrganisation:
    (state: State) =>
    (organisation: string): Array<Membership> => {
      return state.membership_history && state.membership_history.length
        ? state.membership_history.filter(
            (membership) =>
              membership.membership_organisation &&
              membership.membership_organisation.indexOf(organisation) !== -1
          )
        : []
    },

  hasExpiringMemberships: (state: State) => (moment) => {
    if (state.membership_history && state.membership_history) {
      return state.membership_history.reduce((acc, membership) => {
        const a = moment(membership.dates?.renewal)
        const b = moment()

        if (a.diff(b, 'days') <= 0) {
          acc.push(membership.membership_type?.membership_organisation)
        }

        return acc
      }, [])
    }

    return []
  },

  membershipsCoupons: (state: State) => {
    return state.coupons
  },
}

export const actions: ActionTree<State, RootState> = {
  async init(context) {
    const knownUsers = this.app.$cookies.get('known_users') || []
    context.commit(types.SET_KNOWN_USERS, knownUsers)
  },

  /**
   * Social Login
   */
  async socialLogin(
    context: ActionContext<State, RootState>,
    input
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['socialLogin'],
      variables: {
        input,
      },
      context: {
        credentials: 'include',
      },
    })

    const { data } = response

    return data.socialLogin
  },

  /**
   * Login and fetch the active customer details if successful
   */
  async login(
    context: ActionContext<State, RootState>,
    { username, password }
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['login'],
      variables: {
        username,
        password,
      },
      context: {
        credentials: 'include',
      },
    })

    const { data } = response

    return data.login
  },

  /**
   * Login and fetch the active customer details if successful
   */
  async idLogin(
    context: ActionContext<State, RootState>,
    { customer_ref, password }
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['idLogin'],
      variables: {
        customer_ref,
        password,
      },
      context: {
        credentials: 'include',
      },
    })

    const { data } = response

    return data.idLogin
  },

  async tokenLogin(
    context: ActionContext<State, RootState>,
    { email, token }
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['tokenLogin'],
      variables: {
        email,
        token,
      },
      context: {
        credentials: 'include',
      },
    })

    const { data } = response

    return data.tokenLogin
  },

  /**
   * Remove the refresh cookies and customer details from store
   * Will need to hit an endpoint to clear the cookies
   */
  async logout(context: ActionContext<State, RootState>) {
    const client = this.app.$apollo
    const response = await client.query({
      query: customer['logout'],
      context: {
        credentials: 'include',
      },
    })

    const { data } = response

    if (data) {
      this.dispatch('customer/clearSession').then(() => {
        localStorage.removeItem('vuex')
        window.location.reload()
      })
    }
  },

  /**
   * If we identify that the session is erroneous but we don't want to kill the backend session
   * we can reset the client session using this method
   * @param context
   */
  async clearSession(context: ActionContext<State, RootState>) {
    this.app.$eventBus.emit('clear_cookie')

    return new Promise((resolve, reject) => {
      context.commit(types.SET_LOGGED_IN, false)
      context.commit(types.SET_TOKEN, undefined)
      context.commit(types.LOGOUT)

      resolve()
    })
  },

  /**
   * Request the details for the logged in user.
   *
   * If we can't get the active customer, we need to bail out in the catch block
   * and fully logout
   */
  async getActiveCustomer(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    if (context.getters.isLoggedIn) {
      const client = this.app.$apollo

      const response = await client.query({
        query: customer['getActiveCustomer'],
        fetchPolicy,
      })

      const { data } = response

      if (
        data.getActiveCustomer.display_name &&
        data.getActiveCustomer.display_name.indexOf('Anonymous User') !== -1
      ) {
        context.dispatch('logout')
        return false
      } else {
        context.commit(types.SET_CUSTOMER, data.getActiveCustomer)

        this.app.$eventBus.emit('update_cookie', {
          is_logged_in: true,
          customer_name: data.getActiveCustomer.display_name,
          is_member: data.getActiveCustomer.memberships.length ? true : false,
        })

        return data.getActiveCustomer
      }
    } else {
      return false
    }
  },

  async getPrimaryHouseholdMembers(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<Customer[]> {
    const client = this.app.$apollo

    const response = await client.query({
      query: customer['getPrimaryHouseholdMembers'],
      fetchPolicy,
    })

    const { data } = response

    context.commit(types.SET_PRIMARY_MEMBERS, data.getPrimaryHouseholdMembers)

    return data.getPrimaryHouseholdMembers
  },

  async getCustomerChildren(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    if (context.getters.isLoggedIn) {
      const client = this.app.$apolloNonPersisted

      const response = await client.query({
        query: customer['getCustomerChildren'],
        fetchPolicy,
      })

      const { data } = response

      context.commit(types.SET_CHILDREN, data.getActiveCustomer.children)

      return data.getActiveCustomer.children
    } else {
      return null
    }
  },

  async getCustomerHousehold(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    if (context.getters.isLoggedIn) {
      const client = this.app.$apolloNonPersisted

      const response = await client.query({
        query: customer['getCustomerHousehold'],
        fetchPolicy,
      })

      const { data } = response

      if (data.getActiveCustomer?.adults) {
        context.commit(types.SET_ADULTS, data.getActiveCustomer.adults)
      }

      if (data.getActiveCustomer?.children) {
        context.commit(types.SET_CHILDREN, data.getActiveCustomer.children)
      }

      return data.getActiveCustomer?.children
    } else {
      return null
    }
  },

  async getCustomerByRefAndName(
    context: ActionContext<State, RootState>,
    { customer_ref, name }
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted

    const response = await client.query({
      query: customer['getCustomerByRefAndName'],
      variables: {
        customer_ref,
        name,
      },
    })

    const { data } = response

    return data.getCustomerByRefAndName
  },

  async getCustomerContactDetails(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    if (context.getters.isLoggedIn) {
      const client = this.app.$apollo

      const response = await client.query({
        query: customer['getCustomerContactDetails'],
        fetchPolicy,
      })

      const { data } = response

      context.commit(types.SET_CUSTOMER_CONTACT_DETAILS, data.getActiveCustomer)

      return data.getActiveCustomer
    } else {
      return null
    }
  },

  async getCustomerInterests(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    if (context.getters.isLoggedIn) {
      const client = this.app.$apollo

      const response = await client.query({
        query: customer['getCustomerInterests'],
        fetchPolicy,
      })

      const { data } = response

      context.commit(types.SET_CUSTOMER_INTERESTS, data.getActiveCustomer)

      return data.getActiveCustomer.interests
    } else {
      return null
    }
  },

  async getCustomerAttributes(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    if (context.getters.isLoggedIn) {
      const client = this.app.$apollo

      const response = await client.query({
        query: customer['getCustomerAttributes'],
        fetchPolicy,
      })

      const { data } = response

      context.commit(types.SET_CUSTOMER_ATTRIBUTES, data.getActiveCustomer)

      return data.getActiveCustomer.attributes
    } else {
      return null
    }
  },

  async getCustomerConstituencies(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    if (context.getters.isLoggedIn) {
      const client = this.app.$apollo

      const response = await client.query({
        query: customer['getCustomerConstituencies'],
        fetchPolicy,
      })

      const { data } = response

      context.commit(types.SET_CUSTOMER_CONSTITUENCIES, data.getActiveCustomer)

      return data.getActiveCustomer.constituencies
    } else {
      return null
    }
  },

  async getCustomerBalances(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    if (context.getters.isLoggedIn) {
      const client = this.app.$apollo

      const response = await client.query({
        query: customer['getCustomerBalances'],
        fetchPolicy,
      })

      const { data } = response

      context.commit(types.SET_CUSTOMER_BALANCES, data.getActiveCustomer)

      return data.getActiveCustomer.balances
    } else {
      return null
    }
  },

  async getCustomerMemberships(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    if (context.getters.isLoggedIn) {
      const client = this.app.$apollo

      const response = await client.query({
        query: customer['getCustomerMemberships'],
        fetchPolicy,
      })

      const { data } = response

      context.commit(types.SET_CUSTOMER_MEMBERSHIPS, data.getActiveCustomer)
      context.commit(
        types.SET_MEMBERSHIP_HISTORY,
        data.getActiveCustomer.memberships
      )

      return data.getActiveCustomer.memberships
    } else {
      return null
    }
  },

  async getCustomerGiftAid(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    if (context.getters.isLoggedIn) {
      const client = this.app.$apollo

      const response = await client.query({
        query: customer['getCustomerGiftAid'],
        fetchPolicy,
      })

      const { data } = response

      context.commit(types.SET_CUSTOMER_GIFT_AID, data.getActiveCustomer)

      return data.getActiveCustomer.giftaid
    } else {
      return null
    }
  },

  async getCustomerContactPreferences(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    if (context.getters.isLoggedIn) {
      const client = this.app.$apollo

      const response = await client.query({
        query: customer['getCustomerContactPreferences'],
        fetchPolicy,
      })

      const { data } = response

      context.commit(
        types.SET_CUSTOMER_CONTACT_PREFERENCES,
        data.getActiveCustomer
      )

      return data.getActiveCustomer.preferences
    } else {
      return null
    }
  },

  async getCustomerTickets(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    if (context.getters.isLoggedIn) {
      const client = this.app.$apollo

      const response = await client.query({
        query: customer['getCustomerTickets'],
        fetchPolicy,
      })

      const { data } = response

      context.commit(types.SET_TICKET_HISTORY, data.getActiveCustomer)

      return data.getActiveCustomer.tickets
    } else {
      return null
    }
  },

  async getOrderHistory(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<Order[]> {
    const client = this.app.$apollo

    const response = await client.query({
      query: customer['orderHistory'],
      fetchPolicy,
    })

    const { data } = response

    context.commit(types.SET_ORDER_HISTORY, data.orderHistory)

    return data.orderHistory
  },

  async getCustomerEntitlementsCoupon(
    context: ActionContext<State, RootState>
  ): Promise<Order[]> {
    const client = this.app.$apollo
    const response = await client.query({
      query: customer['getCustomerEntitlementsCoupon'],
    })

    const { data } = response

    context.commit(types.SET_COUPONS, data.custom.response)

    return data.custom.response
  },

  async getOrder(
    context: ActionContext<State, RootState>,
    orderId: string
  ): Promise<Order> {
    const client = this.app.$apollo

    const response = await client.query({
      query: customer['order'],
      variables: {
        id: parseInt(orderId),
      },
    })

    const { data } = response
    return data.order
  },

  async getCustomerContributions(
    context: ActionContext<State, RootState>
  ): Promise<any> {
    if (context.getters.isLoggedIn) {
      const client = this.app.$apollo

      const response = await client.query({
        query: customer['getCustomerContributions'],
      })

      const { data } = response

      context.commit(
        types.SET_CONTRIBUTION_HISTORY,
        data.getActiveCustomer.contributions
      )

      context.commit(types.SET_CUSTOMER_CONTRIBUTIONS, data.getActiveCustomer)

      return data.getActiveCustomer.contributions
    } else {
      return null
    }
  },

  async getCustomerGiftSponsorships(
    context: ActionContext<State, RootState>,
    email: string
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted

    const response = await client.query({
      query: customer['getCustomerGiftSponsorships'],
      variables: {
        email,
      },
    })

    const { data } = response

    context.commit(types.SET_GIFT_SPONSORSHIPS, data.getList.response)

    return data.getList.response
  },

  async getCustomerGiftMemberships(
    context: ActionContext<State, RootState>,
    email: string
  ): Promise<any> {
    const client = this.app.$apolloNonPersisted

    const response = await client.query({
      query: customer['getCustomerGiftMemberships'],
      variables: {
        email,
      },
    })

    const { data } = response

    context.commit(types.SET_GIFT_MEMBERSHIPS, data.getList.response)

    return data.getList.response
  },

  /**
   * Update the active customer's details
   * @todo change input: to UpdateCustomerInput
   */
  async updateCustomer(
    context: ActionContext<State, RootState>,
    input: any
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.mutate({
      mutation: customer['updateCustomer'],
      variables: {
        customerInput: input,
      },
    })

    const { data } = response

    return data.updateCustomer
  },

  async updatePhones(
    context: ActionContext<State, RootState>,
    input: Array<CustomerPhoneInput>
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['updatePhones'],
      variables: {
        customerPhonesInput: input,
      },
    })

    const { data } = response

    return data.updatePhones
  },

  async updateAddresses(
    context: ActionContext<State, RootState>,
    input: Array<CustomerAddressInput>
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['updateAddresses'],
      variables: {
        customerAddressesInput: input,
      },
    })

    const { data } = response

    return data.updateAddresses
  },

  async updateAddress(
    context: ActionContext<State, RootState>,
    input: CustomerAddressInput
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['updateAddress'],
      variables: {
        customerAddressInput: input,
      },
    })

    const { data } = response

    return data.updateAddress
  },

  async updatePassword(
    context: ActionContext<State, RootState>,
    input: UpdatePasswordInput
  ): Promise<boolean> {
    const response = await this.app.$apollo.mutate({
      mutation: customer['updatePassword'],
      variables: {
        updatePasswordInput: input,
      },
    })

    const { data } = response

    return data.updatePassword
  },

  async updateGiftAid(
    context: ActionContext<State, RootState>,
    input: [GiftAidDeclarationInput]
  ) {
    const response = this.app.$apollo.mutate({
      mutation: customer['updateGiftAidDeclarations'],
      variables: {
        giftAidDeclarations: input,
      },
    })
  },

  async processLogin(
    context: ActionContext<State, RootState>,
    input: TokenSet
  ): Promise<any> {
    // delete all values from apollo cache to make sure all data is fetched fresh from server
    Object.keys(this.app.$apollo.cache.data.data).forEach((key) =>
      this.app.$apollo.cache.data.delete(key)
    )

    context.commit(types.SET_TOKEN, input.token)
    context.commit(types.SET_LOGGED_IN, true)

    const customer = await context.dispatch('getActiveCustomer', 'network-only')
    await this.dispatch('basket/getBasketProperties', 'network-only')

    if (customer) {
      await this.commit('csi/ADD_CUSTOMER', customer)

      this.app.$eventBus.emit('login')
      this.app.$eventBus.emit('update_cookie', {
        is_logged_in: true,
      })

      // Store email / name to be matched on future login attempts
      context.commit(
        types.SET_KNOWN_USERS,
        `${customer.first_name}|${customer.meta.email}`
      )

      this.app.$cookies.set(
        'known_users',
        JSON.stringify(context.state.known_users)
      )

      return customer
    } else {
      context.commit(types.SET_LOGGED_IN, false)
      context.commit(types.SET_TOKEN, undefined)
      return false
    }
  },

  async sendResetToken(
    context: ActionContext<State, RootState>,
    email
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: customer['sendResetToken'],
      variables: {
        email,
      },
    })

    const { data } = response

    return data.sendResetToken
  },

  async sendMagicLoginLink(
    context: ActionContext<State, RootState>,
    email
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: customer['sendMagicLoginLink'],
      variables: {
        email,
      },
    })

    const { data } = response

    return data.sendMagicLoginLink
  },

  /**
   * Create a new customer (register)
   */
  async createCustomer(
    context: ActionContext<State, RootState>,
    { input, recaptcha }
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['createCustomer'],
      variables: {
        createCustomerInput: input,
        recaptcha: recaptcha,
      },
    })

    const { data } = response

    return data.createCustomer
  },

  async convertToHousehold(
    context: ActionContext<State, RootState>,
    input
  ): Promise<number> {
    const client = this.app.$apollo

    const response = await client.mutate({
      mutation: customer['convertToHousehold'],
      context: {
        credentials: 'include',
      },
    })

    const { data } = response

    this.dispatch('customer/processLogin', data.convertToHouseholdDefault.token)

    return data.convertToHousehold
  },

  async createHousehold(
    context: ActionContext<State, RootState>,
    input
  ): Promise<number> {
    const client = this.app.$apollo

    const response = await client.mutate({
      mutation: customer['createHousehold'],
      variables: {
        createHouseholdInput: input,
      },
    })

    const { data } = response

    return data.createHousehold
  },

  async createHouseholdMember(
    context: ActionContext<State, RootState>,
    input: any
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['createHouseholdMember'],
      variables: {
        customerInput: input.customerInput,
        type: input.type,
      },
    })

    const { data } = response

    return data.createHouseholdMember
  },

  async sendOrderConfirmation(
    context: ActionContext<State, RootState>,
    order_ref: OID
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['sendOrderConfirmation'],
      variables: {
        order_ref,
      },
    })

    const { data } = response

    return data.sendOrderConfirmation
  },

  async sendTickets(
    context: ActionContext<State, RootState>,
    order_ref: OID
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['sendTickets'],
      variables: {
        order_ref,
      },
    })

    const { data } = response

    return data.sendTickets
  },

  async updateHouseholdMember(
    context: ActionContext<State, RootState>,
    input: CustomerInput
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['updateHouseholdMember'],
      variables: {
        customerInput: input,
      },
    })

    const { data } = response

    return data.updateHouseholdMember
  },

  async removeHouseholdMember(
    context: ActionContext<State, RootState>,
    customerRef: String
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['removeHouseholdMember'],
      variables: {
        customerRef: customerRef,
      },
    })

    const { data } = response

    return data.removeHouseholdMember
  },

  async createConstituentAndAssociation(
    context: ActionContext<State, RootState>,
    input
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['createConstituentAndAssociation'],
      variables: {
        customerInput: input.customerInput,
        associationInput: input.associationInput,
        addressInput: input.addressInput,
      },
    })

    const { data } = response

    return data.createConstituentAndAssociation
  },

  async updateAffiliation(
    context: ActionContext<State, RootState>,
    input: AffiliationInput
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['updateAffiliation'],
      variables: {
        updateAffiliationInput: input,
      },
    })

    const { data } = response

    return data.updateAffiliations
  },

  async transferSession(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<void> {
    const client = this.app.$apollo

    const response = await client.query({
      query: customer['transferSession'],
      fetchPolicy,
      context: {
        credentials: 'include',
      },
    })

    const { data } = response

    context.commit(types.SET_TOKEN, data.transferSession.token)
  },

  async updateContactPreferences(
    context: ActionContext<State, RootState>,
    input: Array<CustomerContactPreferenceInput>
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['updateContactPreferences'],
      variables: {
        customerContactPreferencesInput: input,
      },
    })

    const { data } = response

    return data.updateContactPreferences
  },

  async updateInterests(
    context: ActionContext<State, RootState>,
    input: Array<CustomerInterestInput>
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['updateInterests'],
      variables: {
        customerInterestsInput: input,
      },
    })

    const { data } = response

    return data.updateInterests
  },

  async updateConstituencies(
    context: ActionContext<State, RootState>,
    input: Array<CustomerConstituencyInput>
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['updateConstituencies'],
      variables: {
        customerConstituenciesInput: input,
      },
    })

    const { data } = response

    return data.updateActiveCustomerConstituencies
  },

  async updateConstituency(
    context: ActionContext<State, RootState>,
    input: CustomerConstituencyInput
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['updateConstituency'],
      variables: {
        customerConstituencyInput: input,
      },
    })

    const { data } = response

    return data.updateConstituency
  },

  async deleteConstituency(
    context: ActionContext<State, RootState>,
    constituency_ref
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['deleteConstituency'],
      variables: {
        constituency_ref,
      },
    })

    const { data } = response

    return data.deleteConstituency
  },

  async updateAttributes(
    context: ActionContext<State, RootState>,
    input: Array<CustomerAttributeInput>
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['updateAttributes'],
      variables: {
        customerAttributesInput: input,
      },
    })

    const { data } = response

    return data.updateActiveCustomerAttributes
  },

  async updateAttribute(
    context: ActionContext<State, RootState>,
    input: CustomerAttributeInput
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['updateAttribute'],
      variables: {
        customerAttributeInput: input,
      },
    })

    const { data } = response

    return data.updateAttribute
  },

  async deleteAttribute(
    context: ActionContext<State, RootState>,
    attribute_ref
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['deleteAttribute'],
      variables: {
        attribute_ref,
      },
    })

    const { data } = response

    return data.deleteAttribute
  },

  async updateAssociation(
    context: ActionContext<State, RootState>,
    input: AssociationInput
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['updateAssociation'],
      variables: {
        updateAssociationInput: input,
      },
    })

    const { data } = response

    return data.updateAssociation
  },

  async deleteAssociation(
    context: ActionContext<State, RootState>,
    association_ref
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['deleteAssociation'],
      variables: {
        association_ref,
      },
    })

    const { data } = response

    return data.deleteAssociation
  },

  async updateResearch(
    context: ActionContext<State, RootState>,
    input: CustomerResearchInput
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['updateResearch'],
      variables: {
        customerResearchInput: input,
      },
    })

    const { data } = response

    return data.updateResearch
  },

  async deleteResearch(
    context: ActionContext<State, RootState>,
    research_ref
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['deleteResearch'],
      variables: {
        research_ref,
      },
    })

    const { data } = response

    return data.deleteResearch
  },

  async getGiftCertificateBalance(
    context: ActionContext<State, RootState>,
    code: string
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['getGiftCertificateBalance'],
      variables: {
        code,
      },
    })

    const { data } = response

    return data.getGiftCertificateBalance
  },

  async addDirectDebitAccount(
    context: ActionContext<State, RootState>,
    account: AccountInput
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['addDirectDebitAccount'],
      variables: {
        account,
      },
    })

    const { data } = response

    return data.addDirectDebitAccount
  },

  async getCountries(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.query({
      query: customer['getCountries'],
      context: {
        public: true,
      },
      fetchPolicy,
    })

    const { data } = response

    context.commit(types.SET_COUNTRIES, data.getCountries)

    return data.getCountries
  },

  async getStates(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.query({
      query: customer['getStates'],
      context: {
        public: true,
      },
      fetchPolicy,
    })

    const { data } = response

    context.commit(types.SET_STATES, data.getStates)

    return data.getStates
  },

  async getContactPreferenceCategories(
    context: ActionContext<State, RootState>,
    fetchPolicy: string = 'network-only'
  ): Promise<any> {
    const client = this.app.$apollo

    const response = await client.query({
      query: customer['getContactPreferenceCategories'],
      context: {
        public: true,
      },
      fetchPolicy,
    })

    const { data } = response

    return data.getContactPreferenceCategories
  },

  async joinLoyaltyScheme(
    context: ActionContext<State, RootState>,
    account: AccountInput
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['joinLoyaltyScheme'],
    })

    const { data } = response

    return data.joinLoyaltyScheme
  },

  async donateLoyaltyPoints(
    context: ActionContext<State, RootState>,
    account: AccountInput
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['donateLoyaltyPoints'],
    })

    const { data } = response

    return data.donateLoyaltyPoints
  },

  /**
   * Activate a login on to an existing constituent record
   */
  async activateAccount(
    context: ActionContext<State, RootState>,
    input
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['activateAccount'],
      variables: {
        input,
      },
      context: {
        credentials: 'include',
      },
    })

    const { data } = response

    return data.activateAccount
  },

  async checkCustomerHasLogin(
    context: ActionContext<State, RootState>,
    customer_ref: string
  ) {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: customer['checkCustomerHasLogin'],
      variables: {
        customer_ref,
      },
    })

    const { data } = response

    return data.checkCustomerHasLogin
  },

  async checkEmailExists(
    context: ActionContext<State, RootState>,
    email: string
  ) {
    const client = this.$apollo
    const response = await client.mutate({
      mutation: customer['checkEmailExists'],
      variables: {
        email,
      },
      context: {
        public: true,
      },
    })

    const { data } = response

    return data.checkEmailExists
  },

  /**
   * Auto-re-login the current user - useful after applying a new membership etc
   */
  async reauthenticate(context: ActionContext<State, RootState>): Promise<any> {
    const client = this.$apollo
    const response = await client.mutate({
      mutation: customer['reauthenticate'],
      context: {
        credentials: 'include',
      },
    })

    const { data } = response

    return data.reauthenticate
  },
}

export const mutations: MutationTree<State> = {
  [types.SET_KNOWN_USERS](state: State, knownUsers: string): void {
    state.known_users.push(knownUsers)
  },

  [types.SET_LOGGED_IN](state: State, status: boolean): void {
    state.logged_in = status
  },

  [types.SET_TOKEN](state: State, token: string): void {
    state.token = token
  },

  [types.SET_CHILDREN](state: State, payload: any): void {
    const setEmergencyContacts = (data) => {
      data.forEach((child) => {
        // check for emergency contacts that match authorised pickups
        if (child.emergency_contact && child.emergency_contact.length) {
          for (const index in child.authorized_pickups) {
            if (
              child.emergency_contact.find(
                (ec) => ec.name === child.authorized_pickups[index].name
              )
            ) {
              child.authorized_pickups[index].emergency_contact = [true]
            } else {
              child.authorized_pickups[index].emergency_contact = [false]
            }
          }
        }
      })

      return data
    }

    let data = payload.map((child, index) => {
      const issues: any = formatChildIssues(child)
      return {
        ...child,
        index,
        allergies: issues.allergies,
        medical: issues.medical,
        special_needs: issues.special_needs,
        authorized_pickups: formatChildAssociation(
          child,
          childFormSettings.associations.authorized_pickup
        ),
        emergency_contact: formatChildAssociation(
          child,
          childFormSettings.associations.emergency_contact
        ),
        waivers: formatChildAttributes(child, childFormSettings.attributes),
      }
    })

    data = setEmergencyContacts(data)
    state.children = data
    Vue.set(state.customer, 'children', data)
  },

  [types.SET_PRIMARY_MEMBERS](state: State, payload: any): void {
    state.primary_members = payload
  },

  [types.SET_ADULTS](state: State, payload: any): void {
    state.adults = payload.filter((e) => e)
    Vue.set(
      state.customer,
      'adults',
      payload.filter((e) => e)
    )
  },

  [types.SET_CUSTOMER](state: State, payload: any): void {
    state.customer = payload
    if (!payload.tickets) {
      Vue.set(state.customer, 'tickets', [])
    }
  },

  [types.SET_TICKET_HISTORY](state: State, payload: any): void {
    if (payload && state.customer) {
      Vue.set(state.customer, 'tickets', payload.tickets)
    }
  },

  [types.SET_ORDER_HISTORY](state: State, orders: any): void {
    if (orders && state.customer) {
      Vue.set(state.customer, 'orders', orders)
    }
  },

  [types.SET_LATEST_ORDER_ID](state: State, orderId: any): void {
    state.latest_order_id = orderId
  },

  [types.SET_MEMBERSHIP_HISTORY](state: State, payload: any): void {
    state.membership_history = payload
  },

  [types.SET_CONTRIBUTION_HISTORY](state: State, payload: any): void {
    state.contribution_history = payload
  },

  [types.SET_GIFT_MEMBERSHIPS](state: State, payload: any): void {
    let data = []
    payload.forEach((item) => {
      let transformed = Object.keys(item).reduce((newItem, key) => {
        newItem[key.toLowerCase()] = item[key]
        return newItem
      }, {})
      data.push(transformed)
    })
    Vue.set(state.gifts, 'memberships', data)
  },

  [types.SET_GIFT_SPONSORSHIPS](state: State, payload: any): void {
    state.gifts.sponsorships = payload
  },

  [types.LOGOUT](state: State): void {
    state.logged_in = false
    state.token = undefined
    state.customer = undefined
  },

  [types.TRANSFER_SESSION_FAILED](state: State): void {
    state.customer = undefined
  },

  /**
   * Temporary email is committed when someone tries to sign in
   * to potentially be used on either registration or forgotten password
   */
  [types.SET_TEMPORARY_EMAIL](state: State, payload: string): void {
    state.temporary_email = payload
  },

  [types.CLEAR_TEMPORARY_EMAIL](state: State): void {
    state.temporary_email = null
  },

  [types.SET_CUSTOMER_CONTACT_DETAILS](state: State, payload: Customer): void {
    if (payload && payload.addresses && state.customer) {
      Vue.set(state.customer, 'addresses', payload.addresses)
      Vue.set(state.customer, 'billing_address', payload.billing_address)
      Vue.set(state.customer, 'phones', payload.phones)
      Vue.set(state.customer, 'emails', payload.emails)
    }
  },

  [types.SET_CUSTOMER_INTERESTS](state: State, payload: Customer): void {
    if (payload && payload.interests && state.customer) {
      Vue.set(state.customer, 'interests', payload.interests)
    }
  },

  [types.SET_CUSTOMER_ATTRIBUTES](state: State, payload: Customer): void {
    if (payload && payload.attributes && state.customer) {
      Vue.set(state.customer, 'attributes', payload.attributes)
    }
  },

  [types.SET_CUSTOMER_CONSTITUENCIES](state: State, payload: Customer): void {
    if (payload && payload.constituencies && state.customer) {
      Vue.set(state.customer, 'constituencies', payload.constituencies)
    }
  },

  [types.SET_CUSTOMER_BALANCES](state: State, payload: Customer): void {
    if (payload && payload.balances && state.customer) {
      Vue.set(state.customer, 'balances', payload.balances)
    }
  },

  [types.SET_CUSTOMER_MEMBERSHIPS](state: State, payload: Customer): void {
    if (payload && payload.memberships && state.customer) {
      Vue.set(state.customer, 'memberships', payload.memberships)
    }
  },

  [types.SET_CUSTOMER_CONTRIBUTIONS](state: State, payload: Customer): void {
    if (payload && payload.contributions && state.customer) {
      Vue.set(state.customer, 'contributions', payload.contributions)
    }
  },

  [types.SET_CUSTOMER_GIFT_AID](state: State, payload: Customer): void {
    if (payload && payload.giftaid && state.customer) {
      Vue.set(state.customer, 'giftaid', payload.giftaid)
    }
  },

  [types.SET_CUSTOMER_CONTACT_PREFERENCES](
    state: State,
    payload: string
  ): void {
    if (payload && payload.preferences && state.customer) {
      Vue.set(state.customer, 'preferences', payload.preferences)
    }
  },

  [types.SET_COUNTRIES](state: State, payload: Array<any>): void {
    state.countries = payload
  },

  [types.SET_STATES](state: State, payload: Array<any>): void {
    state.states = payload
  },

  /**
   * By using Vue.set and Object.assign to map to existing store values
   * we keep everything in sync and reactive, while allowing us to
   * pass the entire updated object back to the store
   */
  [types.UPDATE_CUSTOMER](state: State, payload: Customer): void {
    Vue.set(state, 'customer', Object.assign({}, state.customer, payload))
  },

  [types.UPDATE_ADULT](
    state: State,
    payload: {
      data: { key: string; value: any }
      customer: CustomerInput
    }
  ): void {
    const index = state.customer.adults.findIndex(
      (cus) => cus.customer_ref == payload.customer.customer_ref
    )

    if (index !== -1) {
      Vue.set(
        state.customer.adults,
        index,
        Object.assign(state.customer.adults[index], payload.data)
      )
      Vue.set(
        state.adults,
        index,
        Object.assign(state.adults[index], payload.data)
      )
    }
  },

  [types.ADD_ADDRESS](state: State, payload: CustomerAddressInput): void {
    const index = state.customer.addresses.length
    Vue.set(state.customer.addresses, index, payload)
  },

  [types.DELETE_ADDRESS](state: State, payload: string): void {
    const data = state.customer.addresses.filter(
      (add) => add && add.address_ref != payload
    )

    if (data) {
      Vue.set(state.customer, 'addresses', data)
    }
  },

  [types.UPDATE_ADDRESS](
    state: State,
    payload: {
      data: { key: string; value: any }
      address: CustomerAddressInput
    }
  ): void {
    const index = state.customer.addresses.findIndex(
      (add) => add.address_ref == payload.address.address_ref
    )

    if (index !== -1) {
      Vue.set(
        state.customer.addresses,
        index,
        Object.assign(state.customer.addresses[index], payload.data)
      )
    }
  },

  [types.SET_COUPONS](state: State, payload: Object) {
    state.coupons = payload.data
  },
}
