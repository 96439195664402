

































































import { Component, Vue, namespace } from 'nuxt-property-decorator'
import CartIcon from '@icons/material/svg/cart.svg'
import SearchIcon from '@icons/material/svg/magnify.svg'
import MenuIcon from '@icons/material/svg/menu.svg'
import menuLinks from './helpers/menuLinks.js'
import LogoSVG from '~/static/images/zoosociety_logo.svg'
import MiniBasket from '~/components/ui/MiniBasket'
import CalendarSVG from '~/static/images/sprites/calendar.svg'
import MobileMenu from '~/components/layout/header/MobileMenu.vue'
import MegaMenu from '~/components/layout/header/MegaMenu.vue'

const customer = namespace('customer')
const basket = namespace('basket')

@Component({
  components: {
    LogoSVG,
    MiniBasket,
    CalendarSVG,
    MobileMenu,
    MegaMenu,
    MenuIcon,
    CartIcon,
    SearchIcon,
  },
})
export default class Header extends Vue {
  private links = menuLinks
  private megaMenuLinks = null
  private megaMenuModifier = ''
  private isOpen: boolean = false
  private baseURL = 'https://www.zoosociety.org'

  toggleMenu(): void {
    this.isOpen = !this.isOpen
  }

  @customer.State
  customer

  @customer.Getter
  isLoggedIn

  @customer.Getter
  displayName

  @basket.State
  properties

  protected basket_count = 0

  get hideUser() {
    return this.$mq === 'sm'
  }

  get showMobileBar() {
    return this.$mq === 'sm' || this.$mq === 'md'
  }

  showMegaMenu(index): void {
    this.megaMenuLinks = this.links[index].megaLinks || []
    this.megaMenuModifier = this.links[index].modifier || ''
  }

  getBasketCount() {
    const cookie = this.$cookies.get('bridge_user_status')
    return cookie != undefined && cookie.basket_count != undefined
      ? cookie.basket_count
      : 0
  }

  setBasketCount() {
    this.basket_count = this.getBasketCount()
  }

  async logout() {
    await this.$store.dispatch('customer/logout')
    this.$router.push(this.$config.get('URLS').sign_in)
  }

  async mounted() {
    this.setBasketCount()

    if (this.isLoggedIn && this.customer == undefined) {
      await this.$store.dispatch('customer/getActiveCustomer')
    }

    if (this.properties == undefined) {
      await this.$store.dispatch('basket/getBasketProperties')
    }

    this.$eventBus.on('cookie_updated', this.setBasketCount())
  }

  beforeDestroy() {
    this.$eventBus.off('cookie_updated', this.setBasketCount())
  }
}
