import { CustomerChild } from '~/@types/skyway'
import { childFormSettings } from '~/components/account/forms/childForm'

export const formatChildAssociation = (child: CustomerChild, type_ref: OID) => {
  const output: object[] = []

  if (child.associations) {
    const associations = child.associations.filter((a) => {
      return a ? parseInt(a.type_ref) === parseInt(type_ref) : false
    })

    if (associations && associations.length) {
      for (const key in associations) {
        const association = associations[key]

        if (association) {
          const assoc = {
            association_ref: association.association_ref,
            name: association.name,
          }

          if (association.note) {
            const notes = association.note.split('\n')
            notes.forEach((n) => {
              const parts = n.split(': ')
              assoc[parts[0]] = parts[1]
            })
          }

          output.push(assoc)
        }
      }
    }
  }
  return output
}

export const formatChildResearch = (child, type_ref) => {
  const result = child.research
    ? child.research.find((a) => parseInt(a.type_ref) === parseInt(type_ref))
    : null

  const output: any = []

  if (result) {
    const research = child.research.find(
      (a) => parseInt(a.type_ref) === parseInt(type_ref)
    )

    if (research) {
      const values = research.value.split(';')

      /**
       * Takes comma separated values, returns array if > 1 or string if exactly 1
       *
       * @param { string } - string of comma separated strings
       *
       * @return { string | array }
       */
      const resolveValue = (value: string): string | string[] | null => {
        return value && value.split(',').length > 1
          ? value.split(',')
          : value
          ? value.split(',')[0]
          : null
      }

      values.forEach((value: string) => {
        const split = value.split(':')

        output[split[0]] = split[1] === 'true' ? true : resolveValue(split[1])
      })

      return output
    } else {
      return output
    }
  } else {
    return output
  }
}

export const formatChildAttributes = (child, attributes: object) => {
  const output = {}
  if (child.attributes) {
    child.attributes.forEach((att) => {
      for (const f in attributes) {
        if (parseInt(attributes[f]) === parseInt(att.keyword_ref)) {
          const type = f.includes('photo')
            ? 'photo'
            : f.includes('privacy')
            ? 'privacy'
            : 'liability'
          if (!output[type]) output[type] = {}
          output[type][f] = [att.keyword_ref]
        }
      }
    })
  }
  return output
}

const mapIssues = (action_refs, issue_type) => {
  switch (issue_type) {
    case 'allergies':
      return mapAllergies(action_refs)
    case 'medical':
      return mapMedical(action_refs)
    case 'special_needs':
      return mapSpecialNeeds(action_refs)
    default:
      return {}
  }
}

const mapAllergies = (action_refs) => {
  const actions = childFormSettings.issues.allergies.actions
  console.log(action_refs)
  let output = {
    has_allergies: !!action_refs.length,
    epipen: action_refs.find((a) => parseInt(a) === actions.epipen),

    // allergies array is determined by whether or not any of the severity actions are present
    allergies: action_refs
      .map((a) => {
        for (const action in actions) {
          if (
            typeof actions[action] === 'object' &&
            actions[action].includes(parseInt(a))
          )
            return action
        }
        return false
      })
      .filter((a) => a),
  }

  // // merge in the actual severity values
  output = Object.assign(
    output,
    ...Object.keys(actions).map((action) => {
      const o = {}

      o[`${action}_severity`] = action_refs.find((a) =>
        typeof actions[action] === 'object'
          ? actions[action].includes(parseInt(a))
          : false
      )
      return o
    })
  )

  return output
}

const mapMedical = (action_refs) => {
  const actions = childFormSettings.issues.medical.actions
  return {
    has_medical_requirements: !!action_refs.length,
    requirements: action_refs.filter((a) =>
      actions.requirements.includes(parseInt(a))
    ),
    medical_items: action_refs.filter((a) =>
      actions.medical_items.includes(parseInt(a))
    ),
  }
}

const mapSpecialNeeds = (action_refs) => {
  return {
    has_special_needs: !!action_refs.length,
    needs: action_refs,
  }
}

export const formatChildIssues = (child) => {
  const output = {}
  if (child.issues) {
    const issueTypes = childFormSettings.issues
    for (const issueType in issueTypes) {
      const issues = child.issues.filter((i) => {
        return parseInt(i.activity_type_ref) === issueTypes[issueType].activity
      })
      if (issues.length) {
        const issue = issues.shift()
        const actions = issue.actions.map((a) => a.action_type_ref)

        output[issueType] = mapIssues(actions, issueType)
        output[issueType].details = issue.note
      }
    }
  }
  return output
}
