<template>
  <div class="layout">
    <client-only>
      <VueAnnouncer />
      <FeedbackBar />
    </client-only>
    <Header id="header" />

    <main id="main" class="main main--no-margin">
      <client-only>
        <AlertNotification />
      </client-only>
      <Nuxt keep-alive include="ProgressContainer" />
    </main>

    <Footer />
    <client-only>
      <portal-target name="after-footer" multiple></portal-target>
    </client-only>
  </div>
</template>
<script>
import Header from '~/components/layout/header/Header'
import Footer from '~/components/layout/footer/Footer'
import AlertNotification from '~/components/ui/notifications/AlertNotification'
import FeedbackBar from '~/components/ui/feedback-bar/FeedbackBar.vue'

export default {
  components: {
    Header,
    AlertNotification,
    Footer,
    FeedbackBar,
  },

  head() {
    return {
      titleTemplate: '%s | Zoo Society of Milwaukee',
    }
  },
}
</script>

<style lang="scss" scoped>
.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
}
#header {
  background-color: $bg;
}
</style>
