<template>
  <div class="layout">
    <VueAnnouncer />
    <FeedbackBar />
    <Header />
    <main id="main" class="main">
      <div class="container">
        <div class="error">
          <Error />
        </div>
      </div>
    </main>
  </div>
</template>
<script>
export default {
  components: {
    FeedbackBar: () => import('~/components/ui/feedback-bar/FeedbackBar.vue'),
  },
}
</script>
