











import { Component, Prop, Vue } from 'nuxt-property-decorator'
import MenuIcon from '@icons/material/svg/menu.svg'
import ChevronIcon from '@icons/material/svg/chevron-right.svg'

@Component({
  components: {
    MenuIcon,
    ChevronIcon,
  },
})
export default class MobileMenu extends Vue {
  @Prop({ type: Array, required: true }) links!: Array<any>
  @Prop({ type: Boolean, default: false }) isOpen!: boolean

  private baseURL = 'https://www.zoosociety.org'
}
