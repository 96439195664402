








import { Component, Prop, Vue } from 'nuxt-property-decorator'
import InfoSVG from 'material-design-icons/action/svg/production/ic_info_outline_24px.svg'

@Component({
  components: {
    InfoSVG,
  },
})
export default class InfoBox extends Vue {
  @Prop({ type: Object }) data?: InfoItem
}
