




























import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { ValidationProvider } from 'vee-validate'

@Component({
  components: {
    ValidationProvider,
  },
})
export default class CheckboxGroup extends Vue {
  @Prop({ type: Array, required: true }) options!: Array<Object>
  @Prop({ type: String }) label!: string
  @Prop({ type: Boolean, required: false, default: false }) required?: Boolean
  @Prop({ type: Boolean, required: false }) horizontal?: Boolean

  get isRequired() {
    return this.required ? { required: { allowFalse: false } } : ''
  }

  get checkedOptions(): Array<string> {
    const checked = this.$refs.checkbox.filter(
      (option) => option.isChecked === true
    )

    return checked.map((option) => option.label)
  }

  handleChange(): void {
    this.$emit('checkedOptions', this.checkedOptions)
    this.$emit('change', this.checkedOptions)
  }
}
