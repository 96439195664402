<template>
  <div class="layout">
    <VueAnnouncer />
    <FeedbackBar />
    <Header />
    <main id="main" class="main">
      <nuxt class="flex-1" />
    </main>
    <client-only>
      <portal-target name="after-footer" multiple></portal-target>
    </client-only>
  </div>
</template>
<script>
import Header from '~/components/layout/header/Header.vue'
import FeedbackBar from '~/components/ui/feedback-bar/FeedbackBar.vue'

/**
 * This layout should be used when the page has a custome header, which means that the global alert notifications should be removed
 */
export default {
  components: {
    Header,
    FeedbackBar,
  },

  head() {
    return {
      titleTemplate: `%s | ${this.$config.get('CLIENT_NAME')}`,
    }
  },

  computed: {
    hasBasketSelection() {
      return true
    },
  },
}
</script>

<style lang="scss" scoped>
.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
}
</style>
