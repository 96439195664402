// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".text--widen{letter-spacing:.15px}.rotating-info{background-color:#f2f9f9;opacity:0;display:flex;width:100%;padding:0 .125rem 0 1rem;-ms-overflow-style:none;scrollbar-width:none}.rotating-info::-webkit-scrollbar{display:none}.rotating-info.is-loaded{opacity:1;overflow-y:scroll;scroll-snap-type:y proximity}.rotating-info__items{transition:all .3s ease;width:100%}.rotating-info__item{display:grid;gap:.625rem;background-color:#f2f9f9;width:100%;flex-direction:column;justify-content:center;padding:1.625rem 1rem 1.625rem 0;grid-template-columns:max-content 1fr;scroll-snap-align:center}@media(min-width:48em){.rotating-info__item{grid-template-columns:max-content 1fr max-content;justify-content:unset;align-items:center;flex-direction:row}}.rotating-info__item svg{fill:#017d85;width:1.875rem;height:1.875rem}.rotating-info__item p{margin:0;font-weight:600;font-size:.75rem;line-height:1rem}.rotating-info__icon{grid-column:1/span 1;grid-row:1/span 1}.rotating-info__message{grid-column:2/span 1;grid-row:1/span 1}.rotating-info__actions{display:flex;gap:1rem;grid-column:2/span 1}@media(min-width:48em){.rotating-info__actions{grid-column:3/span 1}}.rotating-info__actions .anchor{min-width:0;margin:0;text-decoration:underline}.rotating-info__actions .button{font-size:.75rem}.rotating-info__actions .button--dismiss{color:#7e7d78}.rotating-info__nav{width:4px;padding:.125rem 0;display:flex;flex-direction:column;gap:.125rem;position:sticky;top:0;right:0}.rotating-info__nav-item{background-color:#cfe9e9;padding:0;width:100%;min-width:100%}.rotating-info__nav-item.is-active{background-color:#017d85}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"price1": "#d3048e",
	"price2": "#936d22",
	"price3": "#617e26",
	"price4": "#196e19",
	"price5": "#056868",
	"price6": "#5252b6",
	"price7": "#8d0dcd",
	"price8": "#bc2e2d"
};
module.exports = ___CSS_LOADER_EXPORT___;
