import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState } from './types'

export const types = {}

export interface State {}

export const state = (): State => ({})

export const getters: GetterTree<State, RootState> = {}

export const actions: ActionTree<State, RootState> = {
  /**
   * Hook is always fired on first server render of page
   * use this to trigger actions to load global data
   * such as navigation, options etc.
   */

  async nuxtClientInit(context, { app, error }) {
    await context.dispatch('customer/init', context)
  }
}

export const mutations: MutationTree<State> = {}

export const strict = false
