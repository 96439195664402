// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".text--widen{letter-spacing:.15px}.field--loqate{z-index:102}.field--loqate .multiselect__tags,.field--loqate .multiselect__tags .multiselect__input{position:relative;padding-left:1.75rem;min-height:3.1875rem}.field--loqate .multiselect__tags .multiselect__input{background-position:8px 50%;line-height:1}.field--loqate .multiselect__tags .multiselect__input,.field--loqate .multiselect__tags:before{background-image:url(/images/sprites/search.svg);background-size:1.2em 1.2em;background-repeat:no-repeat}.field--loqate .multiselect__tags:before{position:absolute;background-position:0 50%;left:.5rem;top:50%;transform:translateY(-50%);content:\"\";width:1.2em;height:1.2em}.field--loqate .multiselect--active .multiselect__tags{border:none;padding:0;margin:0}.field--loqate .multiselect--active .multiselect__tags:before{display:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"price1": "#d3048e",
	"price2": "#936d22",
	"price3": "#617e26",
	"price4": "#196e19",
	"price5": "#056868",
	"price6": "#5252b6",
	"price7": "#8d0dcd",
	"price8": "#bc2e2d"
};
module.exports = ___CSS_LOADER_EXPORT___;
