















import { Component, Prop, Vue } from 'nuxt-property-decorator'
import CardImage from '~/components/ui/cards/CardImage'
import UIButton from '~/components/ui/buttons/UIButton'

@Component({
  components: {
    CardImage,
    UIButton,
  },
})
export default class UICard extends Vue {
  /** Pass modifier to generate different class names **/
  @Prop({ type: Boolean, default: true }) hideImage?: Boolean
  @Prop({ type: Boolean, default: true }) hasContainer?: Boolean
  @Prop({ type: String, default: '' }) cardType?: string
  @Prop({ type: Object, required: false }) itemData?: any

  get cssName(): string {
    return `${this.$options.name}${
      this.cardType ? ' ' + this.$options.name + '--' + this.cardType : ''
    }`
  }

  get hasImage(): boolean {
    return !!(this.$slots.image || !this.hideImage)
  }

  get hasActions(): boolean {
    return !!this.$slots.actions
  }
}
