var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible !== false),expression:"visible !== false"}],staticClass:"form-row field field--text"},[_c('label',{staticClass:"field__label",class:{ 'flex items-center justify-between': _vm.descriptiveLabel },attrs:{"for":(_vm.id + "_" + _vm._uid)}},[_c('span',[_vm._v(_vm._s(_vm.label)+"\n      "),(_vm.showRequired)?_c('sup',{staticClass:"field__required-indicator"},[_vm._v("*")]):_vm._e()]),_vm._v(" "),(_vm.descriptiveLabel)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.descriptiveLabel)}}):_vm._e()]),_vm._v(" "),_c('ValidationProvider',{staticClass:"field__validation-wrapper",attrs:{"tag":"div","name":_vm.id,"rules":_vm.compiledRules,"mode":_vm.mode ? _vm.mode : 'eager'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
return [_c('div',{staticClass:"field__input-wrapper"},[((_vm.fieldType === 'currency' ? 'number' : 'text')==='checkbox')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],ref:"inputField",staticClass:"field__input form-row__input input",class:[
          _vm.inputClass,
          classes,
          { 'field__input--currency': _vm.fieldType === 'currency' } ],attrs:{"id":(_vm.id + "_" + _vm._uid),"name":_vm.label,"placeholder":_vm.placeholder,"maxlength":_vm.maxlength ? _vm.maxlength : false,"disabled":_vm.disabledBoolean,"pattern":_vm.pattern ? _vm.pattern : false,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.localValue)?_vm._i(_vm.localValue,null)>-1:(_vm.localValue)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('enter')},"blur":function($event){return _vm.$emit('blur')},"change":function($event){var $$a=_vm.localValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.localValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.localValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.localValue=$$c}}}},'input',ariaInput,false)):((_vm.fieldType === 'currency' ? 'number' : 'text')==='radio')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],ref:"inputField",staticClass:"field__input form-row__input input",class:[
          _vm.inputClass,
          classes,
          { 'field__input--currency': _vm.fieldType === 'currency' } ],attrs:{"id":(_vm.id + "_" + _vm._uid),"name":_vm.label,"placeholder":_vm.placeholder,"maxlength":_vm.maxlength ? _vm.maxlength : false,"disabled":_vm.disabledBoolean,"pattern":_vm.pattern ? _vm.pattern : false,"type":"radio"},domProps:{"checked":_vm._q(_vm.localValue,null)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('enter')},"blur":function($event){return _vm.$emit('blur')},"change":function($event){_vm.localValue=null}}},'input',ariaInput,false)):_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],ref:"inputField",staticClass:"field__input form-row__input input",class:[
          _vm.inputClass,
          classes,
          { 'field__input--currency': _vm.fieldType === 'currency' } ],attrs:{"id":(_vm.id + "_" + _vm._uid),"name":_vm.label,"placeholder":_vm.placeholder,"maxlength":_vm.maxlength ? _vm.maxlength : false,"disabled":_vm.disabledBoolean,"pattern":_vm.pattern ? _vm.pattern : false,"type":_vm.fieldType === 'currency' ? 'number' : 'text'},domProps:{"value":(_vm.localValue)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('enter')},"blur":function($event){return _vm.$emit('blur')},"input":function($event){if($event.target.composing){ return; }_vm.localValue=$event.target.value}}},'input',ariaInput,false)),_vm._v(" "),(_vm.fieldType === 'currency')?_c('span',{staticClass:"field__currency-placeholder"},[_vm._v(_vm._s(_vm.$config.get('CURRENCY_SYMBOL')))]):_vm._e()]),_vm._v(" "),(errors[0])?_c('span',_vm._b({staticClass:"form-errors",attrs:{"data-cy":(_vm.inputClass + "-error")}},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }