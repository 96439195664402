import Vue from 'vue'

/**
 * Use the event bus to emit and catch events where a direct parent/child relationship
 * does not exist between the components
 *
 * From anywhere in the app, call :
 *
 * this.$eventBus.emit('event-name', args)
 *
 * You can then register a listener for this event anywhere else in the app
 * Typically you would do this in the mounted() hook within a component
 *
 * this.$eventBus.on('event-name', (args) => this.doSomething(args))
 */
const createEventBus = () => {
  return new Vue({
    methods: {
      /**
       * Emit the given event.
       *
       * @param {string|object} event
       * @param {...*} args
       */
      emit(event, ...args) {
        this.$emit.apply(this, [event].concat(args))
      },

      /**
       * Emit the given event.
       *
       * @param {string|object} event
       * @param {...*} args
       */
      fire(event, ...args) {
        this.emit.apply(this, [event].concat(args))
      },

      /**
       * Listen for the given event.
       *
       * @param {string} event
       * @param {function} callback
       */
      on(event, callback) {
        this.$on(event, callback)
      },

      /**
       * Listen for the given event.
       *
       * @param {string} event
       * @param {function} callback
       */
      listen(event, callback) {
        this.on(event, callback)
      },

      /**
       * Listen for the given event once.
       *
       * @param {string} event
       * @param {function} callback
       */
      once(event, callback) {
        this.$once(event, callback)
      },

      /**
       * Remove one or more event listeners.
       *
       * @param {string} event
       * @param {function} callback
       */
      off(event, callback) {
        this.$off(event, callback)
      },

      /**
       * Remove one or more event listeners.
       *
       * @param {string} event
       * @param {function} callback
       */
      remove(event, callback) {
        this.off(event, callback)
      },

      /**
       * Convenience method to a default notification
       *
       * his.$eventBus.notifySuccess('The tickets were added to your basket')
       *
       * @param {string} message
       * @param {string} context
       * @param {Objext} { text: string, link: string }
       */
      notify(msg, heading = null, shouldPersist = false, link = null) {
        this.emit('notification-show', msg, '', heading, shouldPersist, link)
      },

      /**
       * Convenience method to fire a success notification
       *
       * his.$eventBus.notifySuccess('The tickets were added to your basket')
       *
       * @param {string} message
       * @param {string} context
       * @param {Objext} { text: string, link: string }
       */
      notifySuccess(msg, heading = null, shouldPersist = false, link = null) {
        this.emit(
          'notification-show',
          msg,
          'notification--success',
          heading,
          shouldPersist,
          link
        )
      },

      /**
       * Convenience method to fire a user friendly failure notification
       *
       * this.$eventBus.notifyFailure('There was an error with our ticketing system')
       *
       * @param {string} message
       * @param {string} context
       * @param {Objext} { text: string, link: string }
       */
      notifyFailure(msg, heading = null, shouldPersist = false, link = null) {
        this.emit(
          'notification-show',
          msg,
          'notification--warning',
          heading,
          shouldPersist,
          link
        )
      },
    },
  })
}

export default ({ app }, inject) => {
  const eventBus = app.$eventBus || createEventBus()
  Vue.prototype.$eventBus = eventBus
  inject('eventBus', eventBus)
}
