import Vue from 'vue'
import { config, ConfigOptions } from '~/config'

export interface IConfig<ConfigOptions> {
  get(key: string): any
}

class Config implements IConfig<ConfigOptions> {
  public config: ConfigOptions
  constructor(config: ConfigOptions) {
    this.config = config
  }

  get(key: string): any {
    if (config[key]) {
      return config[key]
    } else {
      return null
    }
  }
}

Vue.prototype.$config = new Config(config)

export default ({ app }, inject) => {
  inject('config', new Config(config))
}
