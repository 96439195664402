let notifications
const notificationMessages = {
  membershipRenewalMessage: [
    {
      name: 'Zoo Pass',
      renewalMessage:
        'You have gift Zoo Passes eligible for renewal, please renew them now to enjoy uninterupted benefits.',
      redirectUrl: '/account/membership',
    },
    {
      name: 'Platypus Circle',
      renewalMessage:
        'You have gift Platypus Circle eligible for renewal, please renew them now to enjoy uninterupted benefits.',
      redirectUrl: '/account/platypus-circle',
    },
    {
      name: 'Animal Sponsorship',
      renewalMessage:
        'You have gift Animal Sponsorship eligible for renewal, please renew them now to enjoy uninterupted benefits.',
      redirectUrl: '/account/sponsorships',
    },
  ],
}

export const addNotificationItem = (expiringMemberships) => {
  notifications = []
  expiringMemberships &&
    expiringMemberships.forEach((expiringMembership, index) => {
      notificationMessages.membershipRenewalMessage.find((membership) => {
        if (membership.name === expiringMembership) {
          notifications[index] = {
            message: membership.renewalMessage,
            redirectUrl: membership.redirectUrl,
          }
        }
      })
    })

  return notifications
}
