
















import { Component, Vue, Watch } from 'nuxt-property-decorator'
import { Route } from 'vue-router'

/**
 * The notification component is included in the main layouts so should not be
 * included in individual components. There are three convenience methods that
 * can be called from anywhere in the app to show a notification
 * <pre class="pt-1 pl-1 bg--monochrome-1">
 * this.$eventBus.notify('message')
 * this.$eventBus.notifySuccess('Success!!')
 * this.$eventBus.nofityFailure('Fail!!')
 * </pre>
 */
@Component
export default class AlertNotification extends Vue {
  public isVisible: boolean = false
  public notificationText: string = ''
  public notificationType: string = 'notification--warning'
  public notificationLink: { to: string; label: string } = null
  public shouldPersist: boolean = false
  public hammer = null

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange(newVal: Route) {
    this.hideNotification()
  }

  showNotification(
    notificationText: string,
    notificationType: string = 'notification--warning',
    notificationHeading: string = 'Error',
    shouldPersist: boolean = false
  ): void {
    this.notificationText = notificationText
    this.notificationHeading = notificationHeading
    this.isVisible = true
    this.notificationType = notificationType
    this.shouldPersist = shouldPersist

    if (!this.shouldPersist) {
      setTimeout(() => {
        this.$eventBus.emit('notification-close')
      }, 12000)
    }
  }

  hideNotification(): void {
    this.isVisible = false
    this.notificationText = ''
  }

  addEventListeners(): void {
    this.$eventBus.on(
      'notification-show',
      (msg, type, heading, shouldPersist) => {
        this.showNotification(msg, type, heading, shouldPersist)
      }
    )

    this.$eventBus.on('notification-close', this.hideNotification)
    this.$eventBus.on('routeChanged', this.hideNotification)
  }

  removeEventListeners(): void {
    this.$eventBus.off('notification-show')
    this.$eventBus.off('notification-close', this.hideNotification)
    this.$eventBus.off('routeChanged', this.hideNotification)
  }

  goToPage(link): void {
    this.$router.push(link)
    this.hideNotification()
  }

  mounted(): void {
    this.addEventListeners()
  }

  beforeDestroy(): void {
    this.removeEventListeners()
  }
}
