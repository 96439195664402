import { ActionTree, MutationTree, ActionContext, GetterTree } from 'vuex'
import _ from 'lodash'
import { RootState } from './types'
import * as forms from '~/api/queries/forms.gql'
import { Form, FormSubmission } from '~/@types/skyway'

export const name = 'forms'

export const namespaced = true

export const types = {
  SET_FORM: 'SET_FORM',
  SET_FORMS_VIEW: 'SET_FORMS_VIEW',
  SET_SUBMISSION: 'SET_SUBMISSION',
  SET_SUBMISSIONS: 'SET_SUBMISSIONS',
  SET_DEFERRED_SUBMISSION: 'SET_DEFERRED_SUBMISSION',
  SET_DEFERRED_SUBMISSIONS: 'SET_DEFERRED_SUBMISSIONS',
  UNSET_SUBMISSION: 'UNSET_SUBMISSION',
  UNSET_SUBMISSIONS: 'UNSET_SUBMISSIONS',
  UNSET_DEFERRED_SUBMISSION: 'UNSET_DEFERRED_SUBMISSION',
  UNSET_DEFERRED_SUBMISSIONS: 'UNSET_DEFERRED_SUBMISSIONS',
  UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
  UPDATE_ORDER: 'UPDATE_ORDER',
}
export interface State {
  form?: Form
  errors?: any
  submissions?: Array<FormSubmission>
  deferred_submissions?: Array<FormSubmission>
}
export interface UnsetSubmission {
  type: string
  ref: number | string
}

export interface FormSubmission {
  id: number
  basket_item_ref?: number
  data: JSON
}

/**
 * Initial state, empty array of forms
 */
export const state = (): State => ({
  form: undefined,
  errors: undefined,
  submissions: [],
  deferred_submissions: [],
})

export const getters: GetterTree<State, RootState> = {}

export const actions: ActionTree<State, RootState> = {
  async getForm(
    context: ActionContext<State, RootState>,
    id: number
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: forms.getForm,
      variables: {
        id,
      },
    })

    const { data } = response

    context.commit(types.SET_FORM, data.form)

    return data.form
  },

  /**
   * Submit a form
   */
  async submitForm(
    context: ActionContext<State, RootState>,
    input: FormSubmission
  ): Promise<any> {
    context.commit(types.SET_SUBMISSION, input)

    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: forms.submitForm,
      variables: {
        id: input.id,
        data: input.data,
      },
    })

    const { data } = response

    return data.submitForm
  },

  /**
   * Store a form to be submitted once checkout is complete
   */
  deferForm(
    context: ActionContext<State, RootState>,
    input: FormSubmission
  ): boolean {
    context.commit(types.SET_DEFERRED_SUBMISSION, input)
    return true
  },

  async cleanSubmissions(
    context: ActionContext<State, RootState>
  ): Promise<any> {
    if (context.state.deferred_submissions !== undefined) {
      const basket = await this.dispatch('basket/getBasket')
      let basketItemRefs: number[] = []

      if (basket && basket.groups.length) {
        const output: any[] = []
        basket.groups.forEach((group) => {
          if (group.name === 'Performance' || group.name === 'Package') {
            group.items.forEach((i) => {
              output.push(i)
            })
          }
        })
        basketItemRefs = output.map((t) => parseInt(t.item_ref))
      }

      // get unique forms
      const submissions = context.state.deferred_submissions.filter(
        (object, index) =>
          index ===
          context.state.deferred_submissions.findIndex(
            (obj) => JSON.stringify(obj) === JSON.stringify(object)
          )
      )

      const output: FormSubmission[] = []
      for (const submission in submissions) {
        const input = submissions[submission]
        if (input.basket_item_ref) {
          // if form is linked to an basket item which is no longer in the basket, ignore it
          if (!basketItemRefs.includes(input.basket_item_ref)) continue
        }
        output.push(input)
      }
      context.commit(types.SET_DEFERRED_SUBMISSIONS, output)
    }
  },

  /**
   * Process all submissions
   */
  async processSubmissions(
    context: ActionContext<State, RootState>
  ): Promise<any> {
    if (context.state.deferred_submissions != undefined) {
      const self = this

      if (this.state.customer.customer == undefined) {
        await this.dispatch('customer/getActiveCustomer')
      }

      const results = context.state.deferred_submissions.map(async (input) => {
        const inputData = JSON.parse(JSON.stringify(input.data))

        const client = this.app.$apollo
        const response = await client.mutate({
          mutation: forms.submitForm,
          variables: {
            id: input.id,
            data: Object.assign(inputData, {
              customer_ref: self.state.customer.customer.customer_ref,
            }),
          },
        })

        const { data } = response

        return data.submitForm
      })

      return Promise.all(results).then((res) => {
        context.commit(types.UNSET_DEFERRED_SUBMISSIONS)
        return true
      })
    }
  },
}

export const mutations: MutationTree<State> = {
  [types.SET_FORM](state: State, payload: Form): void {
    state.form = payload
  },

  [types.SET_SUBMISSIONS](state: State, payload: FormSubmission[]): void {
    state.submissions = payload
  },

  [types.SET_DEFERRED_SUBMISSIONS](
    state: State,
    payload: FormSubmission[]
  ): void {
    state.deferred_submissions = payload
  },

  [types.SET_SUBMISSION](state: State, payload: FormSubmission): void {
    if (state.submissions === undefined) state.submissions = []

    if (payload.basket_item_ref) {
      const current = state.submissions.findIndex(
        (s) => s.basket_item_ref === payload.basket_item_ref
      )
      if (current !== -1) {
        state.submissions[current] = payload
      } else {
        state.submissions.push(payload)
      }
    } else {
      state.submissions.push(payload)
    }
  },

  [types.SET_DEFERRED_SUBMISSION](state: State, payload: FormSubmission): void {
    if (state.deferred_submissions === undefined)
      state.deferred_submissions = []

    if (payload.basket_item_ref) {
      const current = state.deferred_submissions.findIndex(
        (s) => s.basket_item_ref === payload.basket_item_ref
      )
      if (current !== -1) {
        state.deferred_submissions[current] = payload
      } else {
        state.deferred_submissions.push(payload)
      }
    } else {
      state.deferred_submissions.push(payload)
    }
  },

  [types.UNSET_SUBMISSION](state: State, payload: UnsetSubmission): void {
    const match = _.findKey(state.submissions, (sub) => {
      if (sub && sub.data) {
        return (
          parseInt(sub.data[`${payload.type}_ref`]) ===
          parseInt(`${payload.ref}`)
        )
      }
    })
    if (match && state.submissions) state.submissions.splice(match, 1)
  },
  [types.UNSET_DEFERRED_SUBMISSION](
    state: State,
    payload: UnsetSubmission
  ): void {
    const match = _.findKey(state.deferred_submissions, (sub) => {
      if (sub && sub.data) {
        return (
          parseInt(sub.data[`${payload.type}_ref`]) ===
          parseInt(`${payload.ref}`)
        )
      }
    })
    if (match && state.deferred_submissions)
      state.deferred_submissions.splice(match, 1)
  },

  [types.UNSET_SUBMISSIONS](state: State): void {
    state.submissions = undefined
  },
  [types.UNSET_DEFERRED_SUBMISSIONS](state: State): void {
    state.deferred_submissions = undefined
  },

  [types.UPDATE_CUSTOMER](state: State, payload: number | string): void {
    if (state.submissions && state.submissions.length) {
      state.submissions.forEach((sub) => {
        sub.data.customer_ref = parseInt(`${payload}`)
      })
    }
    if (state.deferred_submissions && state.deferred_submissions.length) {
      state.deferred_submissions.forEach((sub) => {
        sub.data.customer_ref = parseInt(`${payload}`)
      })
    }
  },

  [types.UPDATE_ORDER](state: State, payload: number | string): void {
    if (state.submissions && state.submissions.length) {
      state.submissions.forEach((sub) => {
        sub.data.order_ref = parseInt(`${payload}`)
      })
    }
    if (state.deferred_submissions && state.deferred_submissions.length) {
      state.deferred_submissions.forEach((sub) => {
        sub.data.order_ref = parseInt(`${payload}`)
      })
    }
  },
}
