








































import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'
import Check from '@icons/material/svg/check.svg'

@Component({
  components: {
    Check,
  },
})
export default class CheckboxField extends Vue {
  @Prop({ type: String }) rules?: string
  @Prop({ type: String }) label?: string
  @Prop({ type: String | Number | Boolean }) inputValue!:
    | string
    | number
    | boolean
  @Prop() index!: number
  @Prop({ type: Boolean, default: true }) visible?: boolean
  @Prop({ type: Boolean, default: false }) isChecked?: boolean

  protected localChecked: boolean | undefined = false

  @Watch('isChecked')
  toggleChecked(newVal) {
    this.localChecked = this.isChecked
  }

  handleChange() {
    this.$emit('isChecked', this.label)
  }

  mounted() {
    this.localChecked = this.isChecked
  }
}
