import { Vue } from 'nuxt-property-decorator'
/**
 * Template helper filters
 *
 * try to extract common logic here rather than overuse of computed properties
 */

/**
 * Remove HTML from a string
 *
 * usage - {{ string | striphtml }}
 */
Vue.filter('striphtml', function(value) {
  var div = document.createElement('div')
  div.innerHTML = value
  var text = div.textContent || div.innerText || ''
  return text
})

/**
 * Truncate a text string
 *
 * usage = {{ string | truncate(limit) }}
 */
Vue.filter('truncate', function(value, limit) {
  if (value.length > limit) {
    value = value.substring(0, limit - 3) + '...'
  }

  return value
})
